import React from "react";
import style from "./Skeleton.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      // zrobic light mode
      <SkeletonTheme key={`searchbar_loader_${i}`}>
        <div className={style.skeleton} key={i}>
          <div className={style.img}>
            <Skeleton width={48} height={48} borderRadius={10} />
          </div>
          <div className={style.content}>
            <Skeleton count={2} />
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default SkeletonLoader;
