import React, { useState } from "react";
import styles from "./MoreOptions.module.sass";
import cn from "classnames";
import Actions from "components/Actions";
import devscntrAuth from "api/Instance/devscntrAuth";
import useAuth from "hooks/useAuth";
import { coursesEndpoints, jobsEndpoints, postsEndpoints } from "api/endpoints";
import { showError } from "utils/showError";
import { store } from "store/rootReducer";
import { showShareModal } from "store/shareModal/actions";

const deleteEndpoints = {
  post: postsEndpoints.delete,
  course: coursesEndpoints.delete,
  job: jobsEndpoints.delete,
};

const reportEndpoints = {
  post: "",
  course: "",
  job: "",
};

const shareUrls = {
  post: "https://devscntr.com/post/",
  course: "https://devscntr.com/course/",
  job: "https://devscntr.com/jobs/",
};

const MoreOptions = ({ className, item, type, refresh, onDelete }) => {
  const authCtx = useAuth();
  const deleteUrl = deleteEndpoints[type] ?? "";
  const shareUrl = shareUrls[type] ?? "";
  const reportUrl = reportEndpoints[type] ?? "";
  const [confirm, setConfirm] = useState(false);

  const handleShare = () => {
    console.log("Share");
    store.dispatch(showShareModal(`${shareUrl}${item.id}`));
  };

  const handleReport = async () => {
    console.log("Report");
    try {
      const response = await devscntrAuth.post(`${reportUrl}${item.id}`);
      console.log(response?.data);
      console.log("reported");
    } catch (error) {
      console.log(error);
      showError("Zgłoszenie nie powiodło się. Spróbuj ponownie później");
    }
  };

  const handleDelete = () => {
    if (authCtx.isModerator || authCtx.isAdmin) {
      if (confirm) {
        deleteApi();
        setConfirm(false);
      } else {
        setConfirm(true);
      }
    }
  };

  const deleteApi = async () => {
    try {
      const response = await devscntrAuth.delete(`${deleteUrl}${item.id}`);
      console.log(response?.data);
      //refresh && refresh();
      onDelete && onDelete();
      console.log("deleted");
    } catch (error) {
      console.log(error);
      showError("Usuwanie nie powiodło się. Spróbuj ponownie później");
    }
  };

  const actions = [
    {
      title: "Udostępnij",
      icon: "link",
      action: handleShare,
    },
    // {
    //   title: "Zgłoś",
    //   icon: "ticket",
    //   action: handleReport,
    // },
    authCtx.isModerator ||
      (authCtx.isAdmin && {
        title: confirm ? "Potwierdź" : "Usuń",
        icon: "trash",
        color: confirm,
        action: handleDelete,
      }),
  ];

  return (
    <Actions
      className={cn(styles.menu, className)}
      classActionsHead={styles.settings}
      classActionsBody={styles.actionsBody}
      classActionsOption={styles.actionsOption}
      items={actions}
    />
  );
};

export default MoreOptions;
