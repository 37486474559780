import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Icon from "../../../../components/Icon";
import ModalPreview from "../../../../components/ModalPreview";
import { convertFromRaw, EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { Link } from "react-router-dom";
import StarRating from "../StarRating";
import TagsList from "screens/DetailsLayout/TagsList";

const gallery = [
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
];

const Overview = ({ article, fetchArticle }) => {
  const [visibleModalPreview, setVisibleModalPreview] = useState(false);
  const [description, setDescription] = useState(article.description);

  useEffect(() => {
    if (article.description) {
      let fixedDescription = EditorState.createWithContent(
        convertFromRaw(article.description)
      );
      setDescription(convertToHTML(fixedDescription.getCurrentContent()));
    }
  }, [article, description]);

  return (
    <>
      <div className={styles.overview}>
        <section className={styles.postmoreinfo}>
          <Link className={styles.author} to={`/profile/${article.author}`}>
            <div className={styles.authorimage}>
              <img
                src={
                  article?.author_image
                    ? article?.author_image
                    : "/images/default-avatar.png"
                }
                alt=""
              />
            </div>
            <p>{article.display_name && article.display_name}</p>
          </Link>
          <StarRating article={article} />
        </section>
        <div className={styles.article_header}>
          <div className={styles.preview}>
            <img
              src={
                article.image ? article.image : "/images/image-placeholder.png"
              }
              alt="Course Image"
            />
          </div>
          <div className={cn("h4", styles.header_content)}>
            <div className={styles.title}>{article.title}</div>
            {article?.is_external && (
              <a
                href={article?.external_url}
                target="_blank"
                className={cn("button", styles.header_button)}
              >
                Przejdź do wpisu
              </a>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>Treść</div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </div>
        </div>

        {article?.tags?.length > 0 && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={cn("title-purple", styles.subtitle)}>Tagi</div>
              {/* <div className={styles.tags}>
                {article.tags.map((tag, index) => (
                  <span className={styles.tag} key={index}>
                    {tag.name}
                  </span>
                ))}
              </div> */}
              <TagsList tags={article?.tags || []} />
            </div>
          </div>
        )}
      </div>
      <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        gallery={gallery}
        title="Fleet - Travel shopping UI design kit"
        figcaption="Elegant product mockup for your next project"
        download
      />
    </>
  );
};

export default Overview;
