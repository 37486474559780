import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Content.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";

const MAX_TITLE_CHARS = 100;

const Content = ({
  className,
  onDescriptionChange,
  onTitleChange,
  title,
  description,
}) => {
  return (
    <Card
      className={cn(styles.card, className)}
      title="Tytuł i opis"
      classTitle="title-green"
      // head={
      //   <Link
      //     className={cn("button-stroke button-small", styles.button)}
      //     to="/"
      //   >
      //     <Icon name="arrow-left" size="24" />
      //     <span>Powrót</span>
      //   </Link>
      // }
    >
      <div className={styles.description}>
        <TextInput
          className={styles.field}
          label="Tytuł"
          name="title"
          type="text"
          tooltip={`Maksymalnie ${MAX_TITLE_CHARS} znaków. Bez tagów HTML oraz emotek`}
          value={title}
          onChange={onTitleChange}
          currency={title.length}
          required
        />
        <Editor
          editorState={description}
          onEditorChange={onDescriptionChange}
          classEditor={styles.editor}
          label="Opis"
        />
      </div>
    </Card>
  );
};

export default Content;
