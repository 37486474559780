import React from "react";
import style from "./Skeleton.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const groupModalLoader = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <SkeletonTheme key={i}>
        <div className={style.skeleton_group} key={i}>
          <div className={style.content}>
            <Skeleton count={6} />
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default groupModalLoader;
