import styles from "./StarRating.module.sass";
import Icon from "../../../../components/Icon";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Rating } from "react-simple-star-rating";
import { showLoginModal } from "utils/showLoginModal";
import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";

const StarRating = ({ article, fetchArticle }) => {
  const [rateChangeVisible, setRateChangeVisible] = useState(false);
  const [currentRating, setCurrentRating] = useState(
    article?.rating?.toFixed(1) || 0
  );
  const [rating, setRating] = useState(
    article?.rating ? (article?.rating * 100) / 5 : null
  );
  const ratings_count = article?.rating_count;

  const isMounted = useRef(false);
  const authCtx = useAuth();

  useEffect(() => {
    console.log(rating);
    if (isMounted.current) {
      rating && postRating((rating * 5) / 100); //ratingApi((rating * 5) / 100);
      console.log((rating * 5) / 100);
      ratings_count === 0
        ? setCurrentRating((rating * 5) / 100)
        : setCurrentRating((prev) => (((prev * 100) / 5 + rating) / 200) * 5);
    } else {
      isMounted.current = true;
    }
  }, [rating]);

  const postRating = (rating) => {
    devscntrAuth
      .post("/posts/article/rating-post/", { post: article.id, stars: rating })
      .then((response) => {
        fetchArticle();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className={styles.rating}
      onMouseEnter={() => setRateChangeVisible(true)}
      onMouseLeave={() => setRateChangeVisible(false)}
    >
      <Icon name="star-fill" size="24" />
      {/* {!article.rating ? (rating * 5) / 100 : article.rating} */}
      {currentRating && parseFloat(currentRating).toFixed(1)}
      {rateChangeVisible && (
        <div className={styles.ratechange}>
          <Rating
            size={20}
            ratingValue={rating}
            onClick={(rate) => {
              if (!authCtx.isLoggedIn) {
                showLoginModal();
                return;
              }
              rating !== rate ? setRating(rate) : setRating(0);
            }}
          />
        </div>
      )}
    </div>
  );
};
export default StarRating;
