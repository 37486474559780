import { shareModalSlice } from "./slice";

export default shareModalSlice.reducer;
export const shareModalActions = shareModalSlice.actions;
export const showShareModal =
  (url, image = null) =>
  (dispatch) => {
    return dispatch(shareModalActions.showShareModal({ url, image }));
  };
export const removeShareModal = () => (dispatch) => {
  dispatch(shareModalActions.hideShareModal());
};
