import React, { useState } from "react";
import cn from "classnames";
import styles from "./ReplyComment.module.sass";
import Icon from "components/Icon";
import Control from "../Control";
import { Link } from "react-router-dom";
import Actions from "components/Actions";

import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import { showLoginModal } from "utils/showLoginModal";
import More from "../More";
import { validateInput } from "utils/validation";

const ReplyComment = ({
  className,
  item,
  onComment,
  authorName,
  type,
  replyUrl,
}) => {
  const authCtx = useAuth();

  const [currentValue, setCurrentValue] = useState("");
  const [currentValueAnswer, setCurrentValueAnswer] = useState("");
  const [showMore, setShowMore] = useState(false);

  const sendReply = () => {
    if (replyUrl === "") return;
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    if (validateInput(currentValue?.trim(), 255, 2, "odpowiedź")) replyApi();
  };

  const replyApi = async () => {
    try {
      const response = await devscntrAuth.post(replyUrl, {
        text: currentValue.trim(),
        commentID: item.id,
      });
      console.log(response);
      onComment();
      setCurrentValue("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={cn(styles.item, className)}>
        <Link to={`/profile/${item?.author}`}>
          <div className={styles.avatar}>
            <img
              src={item?.user_image || "/images/default-avatar.png"}
              alt="Avatar"
            />
          </div>
        </Link>
        <div className={styles.details}>
          <div className={styles.line}>
            <Link to={`/profile/${item?.author}`}>
              <div className={styles.author}>
                {item?.user_name || "Brak nazwy"}
              </div>
            </Link>

            <div className={styles.rating_and_time}>
              <div className={styles.time}>
                {item?.created_at?.slice(0, 10)}
              </div>
              {item?.course_rating && (
                <div className={styles.rating}>
                  {item?.course_rating}
                  <Icon name="star-fill" size="24" />
                </div>
              )}
              <div className={styles.actions}>
                <More
                  id={item.id}
                  author={item.author}
                  type={type}
                  refresh={onComment}
                />
              </div>
            </div>
          </div>
          {/* <div className={styles.login}>{item.login}</div> */}
          <div className={cn(styles.comment, showMore && styles.comment_full)}>
            <span className={styles.reply_to}>
              @{authorName || "Brak nazwy"}
            </span>{" "}
            {item.text}
          </div>
          {item?.text?.length > 100 && (
            <div
              className={styles.show_more}
              onClick={() => setShowMore((prev) => !prev)}
            >
              {showMore ? "Pokaż mniej" : "Pokaż więcej"}
            </div>
          )}
          <Control
            valueAnswer={currentValue}
            setValueAnswer={setCurrentValue}
            handleReply={sendReply}
            comment={item}
            type={type}
          />
        </div>
      </div>
      {item.replies.length > 0 &&
        item.replies.map((x, index) => (
          <ReplyComment
            className={className}
            key={index}
            item={x}
            onComment={onComment}
            authorName={item.user_name}
            type={type}
            replyUrl={replyUrl}
            answer
          />
        ))}
    </>
  );
};

export default ReplyComment;
