export const validationRules = {
  email: {
    name: "email",
    maxChars: 50,
    minChars: 5,
    security: 1,
  },
  displayName: {
    name: "nazwa użytkownika",
    maxChars: 25,
    minChars: 3,
    security: 1,
  },
  firstname: {
    name: "imię",
    maxChars: 30,
    minChars: 3,
    security: 1,
  },
  lastname: {
    name: "nazwisko",
    maxChars: 40,
    minChars: 3,
    security: 1,
  },
  password: {
    name: "hasło",
    maxChars: 50,
    minChars: 8,
    security: 1,
  },
  taxNumber: {
    name: "NIP",
    maxChars: 10,
    minChars: 10,
    security: 1,
  },
  companyName: {
    name: "nazwa firmy",
    maxChars: 50,
    minChars: 3,
    security: 1,
  },
};
