import { tagInfoSlice } from "./slice";

export default tagInfoSlice.reducer;
export const tagInfoActions = tagInfoSlice.actions;
export const showTagInfo = (id) => (dispatch) => {
  return dispatch(tagInfoActions.showTagInfo({ id }));
};
export const hideTagInfo = () => (dispatch) => {
  dispatch(tagInfoActions.clearTagInfo());
};
