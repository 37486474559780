import React, { useEffect, useState } from "react";
import styles from "./Filtering.module.sass";
import cn from "classnames";
import Filters from "components/Filters";
import { adminUsersRoles } from "data/dataArrays";
import Dropdown from "components/Dropdown";
import { useHistory, useLocation } from "react-router";

const Filtering = ({ currentPage, search }) => {
  const [role, setRole] = useState("Wybierz rolę");

  const searchUrl = useLocation().search;
  const history = useHistory();

  const handleFiltersClear = () => {
    history.push({
      search: `page=${currentPage}`,
    });
    setRole("Wybierz rolę");
  };

  const getUrlParams = () => {
    let index;

    let role = new URLSearchParams(searchUrl).get("role");
    adminUsersRoles.forEach((item, i) => {
      if (item.value === role) {
        index = i;
        return;
      }
    });

    if (role !== null && index !== undefined)
      setRole(adminUsersRoles[index].name);
    console.log(role);
  };

  useEffect(() => {
    getUrlParams();
  }, []);

  const pushUrlParams = () => {
    let params = `page=${currentPage}`;

    let index;
    adminUsersRoles.forEach((item, i) => {
      if (item.name === role) {
        index = i;
        return;
      }
    });
    if (index !== undefined) {
      params = params + `&role=${adminUsersRoles[index].value}`;
    }

    history.push({
      search: params,
    });
  };

  useEffect(() => {
    pushUrlParams();
  }, [searchUrl, currentPage, role]);

  return (
    <Filters className={styles.filter} title="Filtrowanie">
      <div className={styles.filter_body}>
        <Dropdown
          label="Rola"
          options={adminUsersRoles.map((item) => item.name)}
          value={role}
          setValue={setRole}
        />

        <button
          className={cn("button", styles.button)}
          onClick={handleFiltersClear}
        >
          Resetuj filtry
        </button>
      </div>
    </Filters>
  );
};

export default Filtering;
