import React from "react";
import style from "./Skeleton.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const userSubsLoader = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <SkeletonTheme key={i} >
        <div className={style.skeleton_user} key={i}>
          <div className={style.img}>
            <Skeleton width={40} height={40} borderRadius={40}/>
          </div>
          <div className={style.content}>
            <Skeleton count={1} />
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default userSubsLoader;
