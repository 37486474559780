import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "components/Icon";
import Modal from "components/Modal";
import Share from "./Share";
import axios from "axios";
import ModalAuthorSettings from "components/ModalAuthorSettings";
import { store } from "store/rootReducer";
import { showShareModal } from "store/shareModal/actions";
import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import { showLoginModal } from "utils/showLoginModal";
import { Link } from "react-router-dom";
import { emotionChangeDelay } from "data/dataArrays";
import { coursesEndpoints, postsEndpoints } from "api/endpoints";

const shareUrls = {
  post: "https://devscntr.com/post/",
  course: "https://devscntr.com/course/",
  job: "https://devscntr.com/jobs/",
};

// REACTION ENDPOINTS ASSIGNED TO TYPE
const reactionEndpoints = {
  post: postsEndpoints.reaction,
  course: coursesEndpoints.reaction,
};

const Panel = ({
  className,
  type,
  id,
  image,
  currentReaction,
  likes,
  dislikes,
  author_image,
  author,
  refresh,
}) => {
  // AUTH CONTEXT
  const authCtx = useAuth();
  const [isMounted, setIsMounted] = useState(false);
  const [visibleAuthorModal, setVisibleAuthorModal] = useState(false);

  // REACTIONS-------------------------------------------------------------------------REACTIONS
  const reactionUrl = reactionEndpoints[type] ?? "";
  const [allowClick, setAllowClick] = useState(true);
  const [reaction, setReaction] = useState(currentReaction || 0);
  const [emotions, setEmotions] = useState({
    likes: likes || 0,
    dislikes: dislikes || 0,
  });

  // CHANGE REACTION
  const handleReaction = (newReaction) => {
    // CHECK IF USER IS LOGGED IN
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    // CHECK IF REACTION IS NOT THE SAME
    if (reaction == newReaction) return;

    // ANTI-SPAM
    if (!allowClick) return;
    setAllowClick(false);
    setTimeout(() => setAllowClick(true), emotionChangeDelay);

    // SET NEW REACTION, SET NUMBERS
    setReaction(newReaction);
    setEmotions({
      likes: newReaction === 1 ? likes + 1 : likes,
      dislikes: newReaction === 2 ? dislikes + 1 : dislikes,
    });
  };

  // SEND TO API
  const sendReaction = async () => {
    // SET JSON ADAPTED TO TYPE
    let data = {};
    if (type === "post") data = { post: id, emotion: reaction };
    if (type === "course") data = { course_id: id, emotion: reaction };

    // SEND
    try {
      const response = await devscntrAuth.post(reactionUrl, data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // CALL API ON REACTION CHANGE
  useEffect(() => {
    if (isMounted) {
      sendReaction();
    } else {
      setIsMounted(true);
    }
  }, [reaction]);

  //const shareUrl = shareUrls[type] ?? "";
  const handleShare = () => {
    store.dispatch(showShareModal(window.location.href, image));
  };

  return (
    <>
      <div className={cn(styles.panel, className)}>
        <Link to={`/profile/${author}`} className={styles.avatar}>
          <img
            src={author_image || "/images/default-avatar.png"}
            alt="Avatar"
          />
        </Link>
        <button className={styles.btns} onClick={handleShare}>
          <Icon name="share" size="24" />
        </button>
        {type !== "job" && (
          <>
            <button className={styles.btns} onClick={() => handleReaction(1)}>
              <Icon
                name={reaction == 1 ? "thumbs-up-fill" : "thumbs-up"}
                size="24"
                viewBox={"0 0 24 24"}
              />
            </button>
            <button className={styles.btns} onClick={() => handleReaction(2)}>
              <Icon
                name={reaction == 2 ? "thumbs-down-fill" : "thumbs-down"}
                size="24"
                viewBox={"0 0 24 24"}
              />
            </button>
          </>
        )}
        {authCtx.userID === author ||
          (authCtx.isAdmin && (
            <button
              className={styles.btns}
              onClick={() => setVisibleAuthorModal(true)}
            >
              <Icon name="setting" size="24" />
            </button>
          ))}
      </div>
      {authCtx.userID === author ||
        (authCtx.isAdmin && (
          <ModalAuthorSettings
            id={id}
            type={type}
            visible={visibleAuthorModal}
            setVisible={setVisibleAuthorModal}
          />
        ))}
    </>
  );
};

export default Panel;
