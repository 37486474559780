import React, { useState, useEffect, useRef } from "react";
import styles from "./Dashboard.module.sass";
import { Redirect } from "react-router-dom";
import useAuth from "hooks/useAuth";
import cn from "classnames";
import Card from "components/Card";
import axios from "axios";
import useBreakpoints from "hooks/useBreakpoints";

const Dashboard = () => {
  const authCtx = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ["r-max", Infinity],
    ["r-lg", 1140],
    ["r-md", 996],
    ["r-sm", 858],
    ["r-sm-s", 712],
  ];
  useBreakpoints(ref.current, breakpoints);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    //getTags(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const handleSubmit = () => {};

  // akcje dla zaznaczonych userow
  return (
    <>
      {authCtx.isAdmin ? (
        <div className={styles.container} ref={ref}>
          <Card
            className={styles.card}
            classCardHead={styles.head}
            title="Najpopularniejsze tagi"
            classTitle={cn("title-purple", styles.title)}
          ></Card>
          <Card
            className={styles.card}
            classCardHead={styles.head}
            title="Najpopularniejsze tagi"
            classTitle={cn("title-purple", styles.title)}
          ></Card>
        </div>
      ) : (
        <Redirect to="/home" />
      )}
    </>
  );
};
export default Dashboard;
