import React, { useEffect, useState } from "react";
import styles from "./Jobs.module.sass";
import cn from "classnames";
import MiniJobOffer from "components/MiniJobOffer";
import SkeletonLoader from "components/MiniJobOffer/Loader";
import axios from "axios";

import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import devscntrNoauth from "api/Instance/devscntrNoauth";

const Jobs = ({ id }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(false);
  const [jobsList, setJobsList] = useState([]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getJobs(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [id]);

  const getJobs = async (cancelToken) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.request({
        method: "get",
        url: "/jobs/user-job/",
        headers: {
          "USER-ID": id,
          method: "list",
        },
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      setJobsList(response.data.results);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("user jobs canceled");
      } else {
        console.log(error);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.jobs}>
        <div className={cn("title-yellow", styles.subtitle)}>Oferty pracy</div>
        <div className={styles.jobs_list}>
          {isLoading ? (
            <SkeletonLoader cards={1} />
          ) : jobsList?.length > 0 ? (
            <div className={styles.items_list}>
              {jobsList.map((job, index) => (
                <MiniJobOffer article={job} key={index} />
              ))}
            </div>
          ) : (
            <p className={styles.info}>Brak aktualnych ofert pracy</p>
          )}
        </div>
      </div>
    </div>
  );
};
export default Jobs;
