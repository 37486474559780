import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./MiniPost.module.sass";
import relatedStyle from "./RelatedMiniPost.module.sass";

import { Link, useLocation } from "react-router-dom";
import { EditorState, convertFromRaw } from "draft-js";
import ModalPin from "components/ModalPin";
import devscntrAuth from "api/Instance/devscntrAuth";
import useAuth from "hooks/useAuth";
import { showLoginModal } from "utils/showLoginModal";
import MoreOptions from "components/MiniItemsLayout/MoreOptions";
import Reactions from "components/MiniItemsLayout/Reactions";
import Author from "components/MiniItemsLayout/Author";
import Tags from "components/MiniItemsLayout/Tags";
import Thumbnail from "components/MiniItemsLayout/Thumbnail";
import useBreakpoints from "hooks/useBreakpoints";

const MiniPost = React.forwardRef(({ article, related }, ref) => {
  const authCtx = useAuth();
  const [content, setContent] = useState(null);
  const [hidden, setHidden] = useState(false);

  const stylesRef = useRef(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ["desktop", Infinity],
    ["mobile", 716],
  ];
  useBreakpoints(stylesRef.current, breakpoints);

  useEffect(() => {
    if (article) {
      let newContent;
      if (article?.description !== null) {
        newContent = EditorState.createWithContent(
          convertFromRaw(JSON.parse(article?.description))
        );
        setContent(newContent.getCurrentContent().getPlainText());
      } else {
        setContent("");
      }
    }
  }, [article]);

  useEffect(() => {
    setPinState(article.pinned);
    if (pinState !== null) setPinState(article?.pinned);
  }, [article?.pinned]);

  // -----------------------------------------------------
  // <----- !!!! PRZYPINKI !!!! ----->
  // -----------------------------------------------------
  const [pinState, setPinState] = useState(article?.pinned || false);
  const [StarModalVisible, setStarModalVisible] = useState(false);
  const location = useLocation();
  // <----- WALIDACJA ODPINANIA ----->
  const pinsPath = location.pathname.slice(0, 5);
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (pinsPath === "/pins") {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };
  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    setStarModalVisible(true);
  };
  // -----------------------------------------------------
  // -----------------------------------------------------

  return (
    article &&
    !hidden && (
      <article ref={stylesRef}>
        <div className={!related ? styles.item : relatedStyle.item} ref={ref}>
          {/* <----- !!!! MODALPIN !!!! -----> */}
          {(StarModalVisible || redirectedUser || unpinPost) && (
            <ModalPin
              visible={StarModalVisible}
              onClose={() => setStarModalVisible(false)}
              article={article}
              setPinState={setPinState}
              redirectedUser={redirectedUser}
              setRedirectedUser={setRedirectedUser}
              unpinPost={unpinPost}
              setunpinPost={setunpinPost}
              pinUrl={"/posts/article/user-post/"}
              idName={"post_id"}
              path={pinsPath}
            />
          )}

          <Link to={`/post/${article.id}`}>
            <Thumbnail className={styles.image} image={article?.image} />
          </Link>

          <section className={!related ? styles.details : relatedStyle.details}>
            <div className={styles.head}>
              <Author
                id={article?.id}
                username={article?.display_name}
                image={article?.author_image}
              />
              {article?.tags?.length > 0 && (
                <Tags
                  tags={article?.tags?.sort(
                    (a, b) => b.tags_count - a.tags_count
                  )}
                />
              )}

              <div className={!related ? styles.actions : relatedStyle.actions}>
                <button
                  className={styles.pin_btn}
                  onClick={pinState ? unpinHandler : pinHandler}
                >
                  <img
                    src={
                      pinState
                        ? "/images/Icons/heart/filled-color.svg"
                        : "/images/Icons/heart/stroke.svg"
                    }
                    alt="favourite"
                  />
                </button>

                <MoreOptions
                  item={article}
                  onDelete={() => setHidden(true)}
                  type="post"
                />
              </div>
            </div>

            <Link to={`/post/${article.id}`} style={{ color: "inherit" }}>
              <h2 className={!related ? styles.title : relatedStyle.title}>
                {article?.title || "Brak tytułu"}
              </h2>
            </Link>

            <div className={!related ? styles.content : relatedStyle.content}>
              {content}
            </div>

            <div className={styles.foot}>
              <Reactions
                className={styles.control}
                type="post"
                id={article?.id}
                comments={article?.comments_count}
                rating={article?.rating}
                rated={article?.rated}
                currentReaction={
                  article?.emotion_of_user?.length > 0
                    ? article?.emotion_of_user[0]?.emotion
                    : 0
                }
                likes={article?.emotions_likes_count}
                dislikes={article?.emotions_dislikes_count}
              />
              <div className={!related ? styles.time : relatedStyle.time}>
                {article?.created_at?.slice(0, 10)}
              </div>
            </div>
          </section>
        </div>
      </article>
    )
  );
});

export default MiniPost;
