export default function setBreakpointClass(containerElement, breakpoints = [], breakpointName = '') {
  if (!containerElement || !breakpointName) return;

  // usuwa wszystkie klasy breakpoint z kontenera
  breakpoints.forEach(breakpoint => {
    containerElement.classList.remove(`${breakpoint[0]}`);
  });

  // ustawia klasy do najmniejszej (aby klasy z większych szerokości również zostały zaaplikowane w css)
  for (let i = 0; i < breakpoints.length; i++) {
    if (breakpointName !== breakpoints[i][0]) {
      containerElement.classList.add(`${breakpoints[i][0]}`);
    }
    else {
      containerElement.classList.add(`${breakpoints[i][0]}`);
      break;
    }
  }
}
