import React, { useState } from "react";
import cn from "classnames";
import styles from "./Notification.module.sass";
import List from "./List";
import Filters from "./Filters";

const filters = [
  "Posty",
  "Komentarze do postów",
  "Komentarze do kursów",
  "Różne"
];

const Notification = () => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <List className={styles.card} filters={selectedFilters} />
      </div>
      <div className={styles.col}>
        <Filters
          className={styles.filters}
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </div>
    </div>
  );
};

export default Notification;
