import { jobsMapSlice } from "./slice";

export default jobsMapSlice.reducer;
export const jobsMapActions = jobsMapSlice.actions;

export const showJobsMap = () => (dispatch) => {
  dispatch(jobsMapActions.showJobsMap());
};

export const hideJobsMap = () => (dispatch) => {
  dispatch(jobsMapActions.hideJobsMap());
};

export const setJobId = (id) => (dispatch) => {
  return dispatch(jobsMapActions.setCurrentId({ id }));
};
