import React, { useContext, useRef, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import AuthContext from "../../../context/AuthContext";
import PhoneNumberInput from "../../../components/PhoneNumberInput";
import axios from "axios";
import devscntrAuth from "../../../api/Instance/devscntrAuth";
import { showError } from "../../../utils/showError";

const UPDATE_BACKGROUND_IMG_URL = "/accounts/me/background-image-upload";

const ProfileInformation = ({
  className,
  handleChange,
  handleSubmit,
  handleEditorStateChange,
  profileData,
  setProfileData,
  phoneData,
  setPhoneData,
  profileImg,
  editorState,
  showMessage,
  //setErrMsg,
  //errMsg,
  //imgErrMsg,
  handleProfileImgChange,
  handleRemoveProfileImg,
  handleProfileBgImgChange,
}) => {
  const authCtx = useContext(AuthContext);
  const bgImgRef = useRef();
  const [bgImgErrMsg, setBgImgErrMsg] = useState("");

  // Remove profile background img
  const removeBackgroundImgAPI = async () => {
    try {
      // Send data to API
      const response = await devscntrAuth.delete(UPDATE_BACKGROUND_IMG_URL);

      setProfileData((prev) => ({
        ...prev,
        backgroundImg: null,
      }));
      //setBgImgErrMsg("");
    } catch (error) {
      showError("Nie udało się usunąć zdjęcia. Spróbuj ponownie później.");
      //setBgImgErrMsg("Nie udało się usunąć zdjęcia, spróbuj ponownie później.");
    }
  };

  const handleUploadClick = () => {
    bgImgRef.current.click();
  };

  const handleRemoveClick = () => {
    removeBackgroundImgAPI();
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Dane profilu"
      classTitle="title-green"
    >
      {/* {imgErrMsg.length > 0 && (
        <div className={styles.error}>
          <Icon name="info" size="24" />
          {imgErrMsg}
        </div>
      )} */}
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <img src={authCtx.userImg} alt="Avatar" />
          <button className={styles.remove} onClick={handleRemoveProfileImg}>
            <Icon name="close" />
          </button>
        </div>
        <div className={styles.file}>
          <input
            type="file"
            onChange={handleProfileImgChange}
            value={profileImg}
          />
          <button className={cn("button", styles.button)} type="button">
            <Icon name="add" size="24" />
            <span>Prześlij zdjęcie</span>
          </button>
        </div>
        <button
          className={cn("button-stroke", styles.button)}
          onClick={handleRemoveProfileImg}
        >
          Usuń
        </button>
      </div>
      {/* {bgImgErrMsg.length > 0 && (
        <div className={styles.error}>
          <Icon name="info" size="24" />
          {bgImgErrMsg}
        </div>
      )} */}
      <div className={styles.profile_bg_img}>
        <img
          src={profileData.backgroundImg || "/images/default-bg-img.jpg"}
          alt="Background image"
        />
        <input
          className={styles.bg_img_input}
          onChange={handleProfileBgImgChange}
          ref={bgImgRef}
          type="file"
        />
        <div className={styles.bg_img_btns}>
          <button
            className={cn("button", styles.button)}
            onClick={handleUploadClick}
          >
            Prześlij zdjęcie
          </button>
          <button
            className={cn("button-stroke", styles.button, styles.button_filled)}
            onClick={handleRemoveClick}
          >
            Usuń
          </button>
        </div>
      </div>
      <div className={styles.fieldset}>
        {/* {errMsg.length > 0 && (
          <div className={styles.error}>
            <Icon name="info" size="24" />
            {errMsg}
          </div>
        )} */}
        <TextInput
          className={styles.field}
          label="Nazwa użytkownika"
          name="displayName"
          type="text"
          tooltip="Każdy ją widzi"
          value={profileData.displayName}
          onChange={handleChange}
          required
        />
        <TextInput
          className={styles.field}
          label="Imię"
          name="firstname"
          type="text"
          tooltip="Imię jest niewidoczne dla innych"
          value={profileData.firstname}
          onChange={handleChange}
          required
        />
        <TextInput
          className={styles.field}
          label="Nazwisko"
          name="lastname"
          type="text"
          tooltip="Nazwisko jest niewidoczne dla innych"
          value={profileData.lastname}
          onChange={handleChange}
          required
        />
        <TextInput
          className={styles.field}
          label="Email"
          name="email"
          type="email"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          value={profileData.email}
          onChange={handleChange}
          readOnly
          required
        />
        <PhoneNumberInput
          className={styles.field}
          label="Numer telefonu"
          name="phone"
          type="text"
          tooltip="Wpisz swój numer telefonu, wybierz kraj"
          value={phoneData}
          onChange={setPhoneData}
        />
        <Editor
          editorState={editorState}
          onEditorChange={handleEditorStateChange}
          classEditor={styles.editor}
          label="O mnie"
          tooltip="Description"
        />
      </div>
      <button
        className={cn("button", styles.submit_button)}
        onClick={handleSubmit}
      >
        Zapisz zmiany
      </button>
    </Item>
  );
};

export default ProfileInformation;
