import { guestModalSlice } from "./slice";

export default guestModalSlice.reducer;
export const guestModalActions = guestModalSlice.actions;
export const showGuestModal = () => (dispatch) => {
  dispatch(guestModalActions.showGuestModal());
};
export const hideGuestModal = () => (dispatch) => {
  dispatch(guestModalActions.hideGuestModal());
};
