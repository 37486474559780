import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
  url: "",
  image: null,
};

export const shareModalSlice = createSlice({
  name: "shareModal",
  initialState: initialState,
  reducers: {
    showShareModal: (state, action) => {
      const { url, image } = action.payload;
      state.visible = true;
      state.url = url;
      state.image = image;
    },
    hideShareModal: (state) => {
      state.visible = false;
      state.url = "";
      state.image = null;
    },
  },
});
