import React, { useState, useEffect } from "react";
import styles from "./SideSubs.module.sass";
import { NavLink } from "react-router-dom";
import api from "../../../api/devscntr";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import Icon from "../../../components/Icon";

const SideSubs = () => {
  const [response, error, loading, axiosFetch] = useAxiosFunction();

  const getData = () => {
    axiosFetch({
      axiosInstance: api,
      method: "get",
      url: "/accounts/me/follow-user/",
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  return (
    <ul className={styles.sidesubs}>
      {/* DISPLAY LOADDER */}
      {loading && <p>loading</p>}
      {/* DISPLAY POSTS*/}
      {!loading &&
        !error &&
        response.length >= 1 &&
        response.map((res) => (
          <li key={res.id}>
            <NavLink
              to={`/profile/${res.id}`}
              className={styles.link}
              activeClassName={styles.active}
            >
              <img
                src={res.image ? res.image : "/images/default-avatar.png"}
                alt=""
              />
              <span className={styles.userName}>
                {res.display_name ? res.display_name : "Brak Nazwy"}
              </span>

              <Icon name="arrow-next" size="24" />
            </NavLink>
          </li>
        ))}

      {/* DISPLAY VALUE NULL */}
      {!loading && !error && response.length === 0 && (
        <p className={styles.none}>Brak Subskrybcji</p>
      )}
    </ul>
  );
};

export default SideSubs;
