import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";

const Page = ({ wide, children, title }) => {
  // sidebar active state
  const [visible, setVisible] = useState(
    JSON.parse(localStorage.getItem("sidebarExpand")) ?? false
  );
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isMounted) {
      localStorage.setItem("sidebarExpand", visible);
    } else {
      setIsMounted(true);
    }
  }, [visible]);

  return (
    <div className={styles.page_container}>
      <Sidebar
        className={cn(styles.sidebar, { [styles.visible]: visible })}
        onClose={() => setVisible(false)}
        visible={visible}
        setVisible={setVisible}
      />
      <div className={styles.page}>
        <Header onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            {title && <div className={cn("h3", styles.title)}>{title}</div>}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Page);
