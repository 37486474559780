import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Icon from "../../../../components/Icon";
import ModalPreview from "../../../../components/ModalPreview";
import { convertFromRaw, EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { Link } from "react-router-dom";
import ProgressBar from "../../../../components/ProgressBar";
import {
  currencyList,
  jobSeniority,
  jobFormat,
  jobRecruitment,
  jobLevel,
  jobContractType,
} from "../../../../data/dataArrays";
import TagsList from "screens/DetailsLayout/TagsList";
import AboutCompany from "components/AboutCompany";
import Thumbnail from "components/MiniItemsLayout/Thumbnail";

const currencyCode = currencyList[0].code;

const gallery = [
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
];

const Overview = ({ article, author }) => {
  const [visibleModalPreview, setVisibleModalPreview] = useState(false);
  const [description, setDescription] = useState(null);
  const [authorBio, setAuthorBio] = useState(
    author?.company ? author?.company?.description : null
  );

  useEffect(() => {
    if (article.content) {
      let fixedDescription = EditorState.createWithContent(
        convertFromRaw(JSON.parse(article.content))
      );
      setDescription(convertToHTML(fixedDescription.getCurrentContent()));
    }
  }, [article, description]);

  useEffect(() => {
    if (author?.company) {
      if (author?.company?.description) {
        let fixedBio = EditorState.createWithContent(
          convertFromRaw(JSON.parse(author?.company?.description))
        );
        setAuthorBio(convertToHTML(fixedBio.getCurrentContent()));
      }
    }
  }, [author]);

  return (
    <>
      <div className={styles.overview}>
        <section className={styles.postmoreinfo}>
          <Link className={styles.author} to={`/profile/${article.author_id}`}>
            <div className={styles.authorimage}>
              <img
                src={author.image ? author.image : "/images/default-avatar.png"}
                alt=""
              />
            </div>
            <p>{author?.display_name || "Brak nazwy"}</p>
          </Link>
        </section>
        <div className={styles.article_header}>
          <Thumbnail
            className={article?.company_logo && styles.image_wrapper}
            image={article?.company_logo}
            imageFit="contain"
          />
          <div className={cn("h4", styles.header_content)}>
            <div>
              <div className={styles.title}>{article?.title}</div>
              <div className={styles.company_name}>{article?.company_name}</div>
            </div>

            {article?.application_link && (
              <a
                href={article?.application_link}
                target="_blank"
                className={cn("button", styles.header_button)}
              >
                Aplikuj
              </a>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-green", styles.subtitle)}>
              Szczegóły oferty
            </div>
            <div className={styles.details}>
              <ul>
                {article?.salary?.map((item, index) => (
                  <li key={index}>
                    <b>{jobContractType[item?.type - 1]}: </b>
                    {item.type !== "3"
                      ? `${item?.salary_from} ${currencyCode} - ${item?.salary_to} ${currencyCode}`
                      : `${item?.hour_wage} ${currencyCode}/h`}
                  </li>
                ))}
                {/* <li>
                  <b>Płaca UoP:</b> {article?.salary_from} -{" "}
                  {article?.salary_to}
                </li> */}
                <li>
                  <b>Forma pracy:</b> {jobFormat[article?.format - 1]}
                </li>
                <li>
                  <b>Doświadczenie:</b> {jobSeniority[article?.seniority - 1]}
                </li>
                <li>
                  <b>Forma rekrutacji:</b>{" "}
                  {jobRecruitment[article?.recruitment_format - 1]}
                </li>
              </ul>
            </div>
          </div>
        </div>

        {article?.content?.length > 0 && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={cn("title-red", styles.subtitle)}>Opis</div>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></div>
            </div>
          </div>
        )}

        {/* {article?.technologies?.length > 0 && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={cn("title-blue", styles.subtitle)}>
                Technologie
              </div>
              <div className={styles.technologies}>
                {article.technologies.map((technology, index) => (
                  <div className={styles.technology} key={index}>
                    <ProgressBar
                      barColor="#B1E5FC"
                      currentProgress={technology.level}
                      maxProgress={5}
                      height={15}
                    />
                    <div className={styles.name}>
                      {technology.name}
                      <div className={styles.level_title}>
                        {jobLevel[technology.level - 1]} ({technology.level}/5)
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )} */}

        {article?.localization?.length > 0 && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={cn("title-yellow", styles.subtitle)}>
                Lokalizacje
              </div>

              <ul>
                {article?.localization?.map((item, index) => (
                  <li key={index}>
                    ul. {item.street} {item.number}, {item.code} {item.city}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {author?.company && (
          <div className={styles.row}>
            <AboutCompany user={author} company={author?.company} />
          </div>
        )}

        {article?.tags?.length > 0 && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={cn("title-purple", styles.subtitle)}>Tagi</div>
              <TagsList tags={article?.tags || []} />
            </div>
          </div>
        )}
      </div>
      <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        gallery={gallery}
        title="Fleet - Travel shopping UI design kit"
        figcaption="Elegant product mockup for your next project"
        download
      />
    </>
  );
};

export default Overview;
