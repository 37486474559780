import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import TooltipGlodal from "components/TooltipGlodal";
import Dropdown from "components/Dropdown";
import ProfileInformation from "./ProfileInformation";
import Login from "./Login";
import Notifications from "./Notifications";
import Payment from "./Payment";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import Icon from "components/Icon";
import PhotoUploader from "components/PhotoUploader";
import Company from "./Company";
import { validateInput, validateImage } from "utils/validation";
import axios from "axios";

import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import Tags from "./Tags";
import { accountsEndpoints } from "api/endpoints";

// API URLs
const GET_ACCOUNT_INFO_URL = accountsEndpoints.userData.myAccount;
const UPDATE_PROFILE_URL = accountsEndpoints.userData.myAccount;
const CHANGE_EMAIL_REQUEST_URL = "/accounts/email/change-requests";
const UPDATE_EMAIL_REQUEST_URL = "/accounts/email/update-requests";
const UPDATE_PHONE_URL = "/accounts/phone/change-requests";
const UPDATE_PROFILE_IMG_URL = "/accounts/me/image-upload";
const UPDATE_PROFILE_BG_IMG_URL = "/accounts/me/background-image-upload";

const Settings = () => {
  // Context authorization
  const authCtx = useAuth();

  // Pagination for settings
  const navigation = [
    {
      title: "Dane profilu",
      action: () =>
        scrollToProfile.current.scrollIntoView({ behavior: "smooth" }),
    },
    {
      title: "Moje tagi",
      action: () => scrollToTags.current.scrollIntoView({ behavior: "smooth" }),
    },
    {
      title: "Konto",
      action: () =>
        scrollToLogin.current.scrollIntoView({ behavior: "smooth" }),
    },
    {
      title: "Moja firma",
      action: () =>
        scrollToCompany.current.scrollIntoView({ behavior: "smooth" }),
    },

    /*
    {
      title: "Powiadomienia",
      action: () =>
        scrollToNotifications.current.scrollIntoView({ behavior: "smooth" }),
    },
    {
      title: "Płatności",
      action: () =>
        scrollToPayment.current.scrollIntoView({ behavior: "smooth" }),
    },
    */
  ];
  const options = [];
  navigation.map((x) => options.push(x.title));
  const [activeTab, setActiveTab] = useState(options[0]);

  // Pagination states/refs for settings
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollToProfile = useRef(null);
  const scrollToLogin = useRef(null);
  const scrollToCompany = useRef(null);
  const scrollToTags = useRef(null);
  const scrollToNotifications = useRef(null);
  //const scrollToPayment = useRef(null);

  // Pagination menu
  const handleClick = (x, index) => {
    setActiveIndex(index);
    x.action();
  };

  // editorState manages Bio (biography) editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Error and success states
  const [successMsg, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [imgErrMsg, setImgErrMsg] = useState("");

  // Profile img input value
  const [profileImg, setProfileImg] = useState("");
  // Image cropper open/close
  const [openCropper, setOpenCropper] = useState(false);
  const [cropperData, setCropperData] = useState({
    api: "",
    visible: false,
    image: null,
    width: 0,
    height: 0,
    filename: "",
    onSubmit: () => {},
  });

  // Profile data state
  const [data, setData] = useState({});
  const [phone, setPhone] = useState("");
  const [profileData, setProfileData] = useState({
    id: 0,
    img: authCtx.userImg,
    backgroundImg: null,
    displayName: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });

  // Get profile data on load by useEffect hook
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getAccountInfo(cancelToken);

    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  // Set message field (error/success)
  const showMessage = (type, message, scroll = true, timeout = 5000) => {
    if (type == "error") {
      setErrMsg(message);
      setTimeout(() => {
        setErrMsg("");
      }, timeout);
    }
    if (type == "success") {
      setSuccessMsg(message);
      setTimeout(() => {
        setSuccessMsg("");
      }, timeout);
    }
    scroll && window.scrollTo(0, 0);
  };

  // Get account information from API
  const getAccountInfo = async (cancelToken) => {
    try {
      // Request user data
      const response = await devscntrAuth.request({
        method: "GET",
        url: GET_ACCOUNT_INFO_URL,
        cancelToken: cancelToken.token,
      });
      setData(response?.data.data.user);
      // Set profile data to state
      setProfileData({
        id: response?.data?.data?.user?.id,
        img: authCtx.userImg,
        backgroundImg: response?.data?.data?.user?.background_image,
        displayName: response?.data?.data?.user?.display_name || "",
        firstname: response?.data?.data?.user?.first_name || "",
        lastname: response?.data?.data?.user?.last_name || "",
        email: response?.data?.data?.user?.email || "",
        phone: response?.data?.data?.user?.phone || "",
      });

      setPhone(response.data.phone);

      // Set user's bio, if API return null, set empty string
      const description = response?.data?.bio || "";

      // Set bio Editor state if user's bio content is not empty
      if (description !== "") {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(JSON.parse(description)))
        );
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("account info canceled");
      } else {
        console.log(error);
      }
    }
  };

  // Put profile data changes in API
  const updateProfileAPI = async () => {
    try {
      console.log(
        JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      );
      const response = await devscntrAuth.put(UPDATE_PROFILE_URL, {
        display_name: profileData.displayName,
        first_name: profileData.firstname,
        last_name: profileData.lastname,
        bio: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      });

      showMessage("success", "Zapisano zmiany pomyślnie");
    } catch (error) {
      console.log(error);
    }
  };

  // UPDATE EMAIL, PHONE NUMER - na później
  /*
  const updateEmailAPI = async () => {
    try {
      const response = await devscntrAuth.post(
        CHANGE_EMAIL_REQUEST_URL,
        JSON.stringify({
          email: profileData.email,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );

      verifyEmailAPI(response.data.token);
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No response");
      } else if (error.response?.status === 400) {
        setErrMsg("Email update failed");
      } else if (error.response?.status === 401) {
        setErrMsg("Wrong password");
      } else {
        setErrMsg("Login failed");
      }
    }
  };

  const verifyEmailAPI = async (token) => {
    try {
      const response = await devscntrAuth.post(
        UPDATE_EMAIL_REQUEST_URL,
        JSON.stringify({
          token: token,
          code: "0",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        setErrMsg("No response");
      } else if (error.response?.status === 400) {
        setErrMsg("Email update failed");
      } else if (error.response?.status === 401) {
        setErrMsg("Wrong password");
      } else {
        setErrMsg("Login failed");
      }
    }
  };

  const updatePhoneAPI = async (token) => {
    try {
      const response = await devscntrAuth.post(
        UPDATE_PHONE_URL,
        JSON.stringify({
          phone: profileData.phone,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No response");
      } else if (error.response?.status === 400) {
        setErrMsg("Phone update failed");
      } else if (error.response?.status === 401) {
        setErrMsg("Wrong password");
      } else {
        setErrMsg("Login failed");
      }
    }
  };

  */

  // Image validation
  const imageValidation = (file) => {
    // Allowed file extensions
    const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;
    // Allowed file size in MiB
    const allowedFileSize = 5;

    // Check file's size
    if (file?.size / 1024 / 1024 > allowedFileSize) {
      showMessage("error", `Maksymalny rozmiar pliku to ${allowedFileSize} MB`);
      return false;
    }
    // Check file's extension
    if (!allowedExtensions.exec(file?.name)) {
      showMessage(
        "error",
        "Możesz przesłać tylko pliki w formacie .png, .jpg, .jpeg"
      );
      return false;
    }

    // If file is OK
    return true;
  };

  // Hide image cropper
  const hideCropper = () => {
    setCropperData((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  // Handle bio's Editor changes, set state
  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  // Remove profile img
  const removeProfileImgAPI = async (token) => {
    try {
      // Send data to API
      const response = await devscntrAuth.delete(UPDATE_PROFILE_IMG_URL, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log(response?.data);
      authCtx.changeUserImg(
        `${process.env.PUBLIC_URL}/images/default-avatar.png`
      );
      setImgErrMsg("");
    } catch (error) {
      setImgErrMsg("Couldn't remove your profile image");
      console.log(error);
    }
  };

  // Handle profile img remove
  const handleRemoveProfileImg = () => {
    removeProfileImgAPI(authCtx.token);
  };

  // Handle profile image change
  const handleProfileImgChange = (e) => {
    // Get user's file
    const file = e.target.files[0];

    if (!validateImage(file, 5)) return;
    // Prepare data for image cropping
    setCropperData({
      api: UPDATE_PROFILE_IMG_URL,
      visible: true,
      image: file,
      width: 150,
      height: 150,
      filename: `profile_img_${profileData.id}_${Math.floor(
        Math.random() * 10000000000
      )}`,
      onSubmit: updateProfileImage,
    });
  };

  const updateProfileImage = async (file, imgBase64) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      // Send data to API
      const response = await devscntrAuth.put(
        UPDATE_PROFILE_IMG_URL,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("bearerToken"),
          },
        }
      );

      // call onSubmit from parent component
      console.log(response.data);

      hideCropper();

      authCtx.changeUserImg(imgBase64);

      setProfileData((prev) => ({
        ...prev,
        img: imgBase64,
      }));

      setImgErrMsg("");
    } catch (error) {
      setImgErrMsg("Server error");
      //console.log(error);
    }
  };

  const updateProfileBgImage = async (file, imgBase64) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      // Send data to API
      const response = await devscntrAuth.put(
        UPDATE_PROFILE_BG_IMG_URL,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("bearerToken"),
          },
        }
      );

      console.log(response.data);
      hideCropper();

      console.log("imgbase64");
      console.log(imgBase64);
      setProfileData((prev) => ({
        ...prev,
        backgroundImg: imgBase64,
      }));

      setImgErrMsg("");
    } catch (error) {
      setImgErrMsg("Server error");
      //console.log(error);
    }
  };

  const handleProfileBgImgChange = (e) => {
    // Get user's file
    const file = e.target.files[0];

    if (!validateImage(file, 5)) return;

    // Prepare data for image cropping
    setCropperData({
      api: UPDATE_PROFILE_BG_IMG_URL,
      visible: true,
      image: file,
      width: 1100,
      height: 400,
      filename: `profile_bg_img_${profileData.id}_${Math.floor(
        Math.random() * 10000000000
      )}`,
      onSubmit: updateProfileBgImage,
    });
  };

  // Handle inputs changes
  // ONLY: Display name, First name, Last name, Bio
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle data submit
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Submit");
    console.log(profileData);

    // Send profile data to API
    updateProfileAPI(authCtx.token);
  };

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.button, {
                [styles.active]: activeIndex === index,
              })}
              key={index}
              onClick={() => handleClick(x, index)}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={options}
          />
          {successMsg.length > 0 && (
            <div className={styles.success}>
              <Icon name="info" size="24" />
              {successMsg}
            </div>
          )}
          {errMsg.length > 0 && (
            <div className={styles.error}>
              <Icon name="info" size="24" />
              {errMsg}
            </div>
          )}
          <div className={styles.list}>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[0],
              })}
            >
              <div className={styles.anchor} ref={scrollToProfile}></div>
              <ProfileInformation
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleEditorStateChange={handleEditorStateChange}
                profileData={profileData}
                setProfileData={setProfileData}
                phoneData={phone}
                setPhoneData={setPhone}
                editorState={editorState}
                //errMsg={errMsg}
                //setErrMsg={setErrMsg}
                showMessage={showMessage}
                //imgErrMsg={imgErrMsg}
                //profileImg={profileImg}
                handleProfileImgChange={handleProfileImgChange}
                handleRemoveProfileImg={handleRemoveProfileImg}
                handleProfileBgImgChange={handleProfileBgImgChange}
              />
            </div>
            {cropperData.visible && (
              <PhotoUploader
                onSubmit={cropperData.onSubmit}
                sourceImage={cropperData.image}
                width={cropperData.width}
                height={cropperData.height}
                filename={cropperData.filename}
                //showMessage={showMessage}
                setErrorMsg={setImgErrMsg}
              />
            )}

            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[1],
              })}
            >
              <div className={styles.anchor} ref={scrollToTags}></div>
              <Tags />
            </div>

            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[2],
              })}
            >
              <div className={styles.anchor} ref={scrollToLogin}></div>
              <Login showMessage={showMessage} />
            </div>

            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[3],
              })}
            >
              <div className={styles.anchor} ref={scrollToCompany}></div>
              <Company data={data.company} />
            </div>

            {/* <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[2],
              })}
            >
              <div className={styles.anchor} ref={scrollToNotifications}></div>
              <Notifications />
            </div> */}
            {/* <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[3],
              })}
            >
              <div className={styles.anchor} ref={scrollToPayment}></div>
              <Payment />
            </div> */}
          </div>
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Settings;
