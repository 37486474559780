import React, { useEffect, useState } from "react";
import styles from "./Course.module.sass";
import Details from "./Details";
//import Panel from "./Panel";
import { useParams } from "react-router-dom";
import cn from "classnames";
import axios from "axios";

import useAuth from "../../hooks/useAuth";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../api/Instance/devscntrNoauth";
import SkeletonLoader from "screens/DetailsLayout/Loader";
import Panel from "screens/DetailsLayout/Panel";

const course = {
  id: 1,
  title: "React - The Complete Guide (incl Hooks, React Router, Redux)",
  price: "49",
  specialprice: "29",
  duration: "20h", // może być czas trwania w sekundach, potem przekonwertuję
  format: "stacjonarnie", // albo jakaś cyfra przyporządkowana do tego formatu
  difficulty: "początkujący", // tak samo może być cyfra
  language: "pl",
  localization: ["Leszno", "64-100", "Testowa", "21/3"],
  comments_count: "45",
  rating_avg: "4.5",
  certificate: true,
  emotions_likes_count: "23",
  emotions_dislikes_count: "35",
  emotion_of_user: [{ emotion: 1 }],
  created_at: "2022-07-18T11:58:40+00:00",
  author: 5,
  author_name: "Testuser21",
  author_image: "/images/default-avatar.png",
  image: "/images/content/post-pic-1.jpg",
  reflink: "https://udemy.com/",
  reviews_count: 4,
  tags: [
    {
      name: "frontend",
      tags_count: 1,
    },
    {
      name: "React",
      tags_count: 1,
    },
  ],
};

const Course = () => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [author, setAuthor] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCourse(cancelToken);
    window.scrollTo(0, 0);

    // cleanup
    return () => {
      cancelToken.cancel();
    };
  }, [id, refresh]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (article) {
      getAccountInfo(cancelToken);
    }

    // cleanup
    return () => {
      cancelToken.cancel();
    };
  }, [article]);

  const getCourse = async (cancelToken) => {
    try {
      // Request user data
      const response = await axiosInstance.request({
        method: "get",
        url: `/course/show-course/${id}`,
        cancelToken: cancelToken.token,
      });
      setArticle(response.data[0]);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("course detail canceled");
      } else {
        console.log(error);
      }
    }
  };

  const getAccountInfo = async (cancelToken) => {
    try {
      // Request user data
      const response = await axiosInstance.request({
        method: "get",
        url: "/accounts/me/user-info/",
        cancelToken: cancelToken.token,
        headers: {
          "USER-ID": article.author,
        },
      });
      setAuthor(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("account info canceled");
      } else {
        console.log(error);
      }
    }
  };

  const handleClose = () => {
    setActiveIndex(0);
    setVisible(false);
  };

  return (
    <div className={cn(styles.product, { [styles.active]: visible })}>
      {author && article ? (
        <>
          <Details
            className={styles.details}
            setValue={setVisible}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            article={article}
            author={author}
          />
          <Panel
            className={styles.panel}
            type="course"
            id={article?.id}
            currentReaction={
              article?.emotion_of_user?.length > 0
                ? article?.emotion_of_user[0]?.emotion
                : 0
            }
            likes={article?.emotions_likes_count}
            dislikes={article?.emotions_dislikes_count}
            author={article.author}
            author_image={article.author_image}
            refresh={() => setRefresh((prev) => !prev)}
          />
        </>
      ) : (
        <SkeletonLoader />
      )}
    </div>
  );
};

export default Course;
