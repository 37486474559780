import React from "react";
import Modal from "components/Modal";
import Schedule from "components/Schedule";

const ModalSchedule = ({
  visible,
  setVisible,
  startDate,
  setStartDate,
  startTime,
  setStartTime,
}) => {
  return (
    <Modal visible={visible} onClose={() => setVisible(false)}>
      <Schedule
        startDate={startDate}
        setStartDate={setStartDate}
        startTime={startTime}
        setStartTime={setStartTime}
      />
    </Modal>
  );
};
export default ModalSchedule;
