export const postsEndpoints = {
  // list and detail
  show: "/posts/article/show-post/",
  userPosts: "/posts/article/user-post/",
  // create and delete
  create: "/posts/article/create-post/",
  update: "/posts/article/create-post/",
  delete: "/posts/article/create-post/",
  // reactions
  reaction: "/posts/add-reaction/",
  rating: "/posts/article/rating-post/",
  // comments
  showComments: "/posts/article/comment-anon/",
  createComment: "/posts/article/comment/",
  reactionComment: "/posts/article/comment-emote/",
  // tests
  showTest: "/posts/article/show-test/",
  createTest: "/posts/article/test-create/create/",
  submitTest: "/posts/article/test-submit/submit/",
};

export const coursesEndpoints = {
  // list and detail
  show: "/course/show-course/",
  userCourses: "/course/user-course/",
  // create and delete
  create: "/course/course-actions/",
  update: "/course/course-actions/",
  delete: "/course/course-actions/",
  // reactions
  reaction: "/course/course-emotion/",
  rating: "/course/course-rate/",
  review: "/course/course-review/",
  // comments
  showComments: "/course/course-comment-anon/",
  createComment: "/course/course-comment/",
  reactionComment: "/course/course-comment-emote/",
};

export const jobsEndpoints = {
  // list and detail
  show: "/jobs/show-job/",
  userJobs: "/jobs/user-job/",
  // create and delete
  create: "/jobs/job-create/",
  update: "/jobs/job-create/",
  delete: "/jobs/job-create/",
};

export const jzwitEndpoints = {};

export const levels = {
  showList: "/levels/",
  userLevelGroupHistory: "/levels/groupHistory/",
  userLevelHistory: "/levels/history/",
};

export const tagsEndpoints = {
  show: "/misc/tags/",
  create: "/misc/tags/",
};

export const miscEndpoints = {
  search: "/search/search/",
  newestComments: "/misc/newest-comments/",
  newestCourses: "/misc/newest-courses/",
  recommendedPosts: "/misc/recommended/",
};

export const accountsEndpoints = {
  admin: {
    tags: "/accounts/admin/tags/",
    users: "/accounts/admin/users/",
  },
  company: {
    show: "/accounts/company/show/",
    create: "/accounts/company/create/",
  },
  auth: {
    //token
    refreshToken: "/accounts/token/refresh",
    // login
    login: "/accounts/login",
    phoneVerification: "/accounts/login/phone-verifications",
    resendPhoneVerification: "/accounts/login/phone-code-resend",
    // registration
    register: "/accounts/registration",
    registerActivate: "/accounts/registration/activate",
    registerCheckFiled: "/accounts/registration/check-field-taken",
  },
  userAuthData: {
    // phone number
    changePhone: "/accounts/phone/change-requests",
    // email
    changeEmail: "/accounts/email/change-requests",
    changeEmailAccept: "/accounts/email/update-requests",
    // password
    changePassword: "/accounts/password",
    resetPassword: "/accounts/password/reset",
    sendResetPasswordEmail: "/accounts/password/reset/send-email",
  },
  // user's data
  userData: {
    myAccount: "/accounts/me",
    userInfo: "/accounts/me/user-info/",
    profileImage: "/accounts/me/image-upload",
    backgroundImage: "/accounts/me/background-image-upload",
    followUser: "/accounts/me/follow-user/",
    groups: "/accounts/me/managegroup/",
    tagPreferences: "/accounts/me/tag-preferences/",
  },
  userContent: {
    userPins: "/accounts/user-pins/",
    followUserPosts: "/accounts/me/follow-user-posts/",
    followedContent: "/accounts/me/followed-content/",
  },
  // notifications
  notifications: {
    show: "/accounts/me/notification/",
    delete: "/accounts/me/notification-delete/",
  },
};

export const adminEndpoints = {
  users: {
    show: "/accounts/admin/users/",
    update: "/accounts/admin/users/",
    publications: "/accounts/admin/publications/",
  },
  company: {
    update: "/accounts/admin/companies/",
  },
  tags: {
    show: "/accounts/admin/tags/",
  },
  posts: {
    update: "/accounts/admin/posts/",
  },
  courses: {
    update: "/accounts/admin/courses/",
  },
  jobs: {
    update: "/accounts/admin/jobs/",
  },
};
