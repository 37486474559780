import React, { useState } from "react";
import cn from "classnames";
import styles from "./Comment.module.sass";
import Icon from "components/Icon";
import Control from "./Control";
import { Link } from "react-router-dom";

import devscntrAuth from "api/Instance/devscntrAuth";
import ReplyComment from "./ReplyComment";
import Actions from "components/Actions";
import useAuth from "hooks/useAuth";
import { showLoginModal } from "utils/showLoginModal";
import More from "./More";
import { validateInput } from "utils/validation";

const types = {
  post: "/posts/article/comment/",
  course: "/course/course-comment/",
};

const Comment = ({ className, item, type, onComment }) => {
  const authCtx = useAuth();

  const [currentValue, setCurrentValue] = useState("");
  const [currentValueAnswer, setCurrentValueAnswer] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [visibleAnswers, setVisibleAnswers] = useState("");

  const replyUrl = types[type] ?? "";

  const sendReply = () => {
    if (replyUrl === "") return;
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    if (validateInput(currentValue?.trim(), 255, 2, "odpowiedź")) replyApi();
  };

  const replyApi = async () => {
    try {
      const response = await devscntrAuth.post(replyUrl, {
        text: currentValue.trim(),
        commentID: item.id,
      });
      console.log(response);
      onComment();
      setCurrentValue("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={cn(styles.item, className)}>
        <Link to={`/profile/${item?.author}`}>
          <div className={styles.avatar}>
            <img
              src={item?.user_image || "/images/default-avatar.png"}
              alt="Avatar"
            />
          </div>
        </Link>
        <div className={styles.details}>
          <div className={styles.line}>
            <Link to={`/profile/${item?.author}`}>
              <div className={styles.author}>
                {item?.user_name || "Brak nazwy"}
              </div>
            </Link>
            <div className={styles.rating_and_time}>
              <div className={styles.time}>
                {item?.created_at?.slice(0, 10)}
              </div>
              {item?.course_rating && (
                <div className={styles.rating}>
                  {item?.course_rating}
                  <Icon name="star-fill" size="24" />
                </div>
              )}
              <div className={styles.actions}>
                <More
                  id={item.id}
                  author={item.author}
                  type={type}
                  refresh={onComment}
                />
              </div>
            </div>
          </div>
          {/* <div className={styles.login}>{item.login}</div> */}
          <div className={cn(styles.comment, showMore && styles.comment_full)}>
            {item.text}
          </div>
          {item?.text?.length > 100 && (
            <div
              className={styles.show_more}
              onClick={() => setShowMore((prev) => !prev)}
            >
              {showMore ? "Pokaż mniej" : "Pokaż więcej"}
            </div>
          )}
          <Control
            valueAnswer={currentValue}
            setValueAnswer={setCurrentValue}
            handleReply={sendReply}
            comment={item}
            type={type}
            refresh={onComment}
          />
        </div>
      </div>
      {item.replies.length > 0 && (
        <>
          <div
            className={cn(styles.answerlist, { [styles.show]: visibleAnswers })}
          >
            {item.replies.map((x, index) => (
              <ReplyComment
                className={styles.answer}
                key={index}
                item={x}
                onComment={onComment}
                authorName={item.user_name || "Brak nazwy"}
                replyUrl={replyUrl}
                type={type}
                answer
              />
            ))}
          </div>
          <button
            className={cn(styles.showanswers)}
            onClick={() => setVisibleAnswers((prev) => !prev)}
          >
            {visibleAnswers ? "Ukryj odpowiedzi" : "Pokaż odpowiedzi"}
          </button>
        </>
      )}
    </>
  );
};

export default Comment;
