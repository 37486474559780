import React, { useEffect, useState, useContext, useCallback } from "react";
import useAxios from "../../hooks/useAxios";
import Modal from "../Modal";
import cn from "classnames";
import styles from "./ModalPin.module.sass";
import { useParams } from "react-router";
import GroupContext from "../../context/group-context";
import GroupModalLoader from "../../components/PostLoaderSkeleton/groupModalLoader";

const ModalPin = (props) => {
  const groupCtx = useContext(GroupContext);

  const [title, setTitle] = useState("");
  const [radio, setRadio] = useState();
  const [invalid, setInvalid] = useState(false);
  const [selectInvalid, setSelectInvalid] = useState(false);

  const { name } = useParams();
  const isEmpty = title.trim() === "";

  // <----- ADD GROUP ----->

  const [response, error, loading, FetchAllGroups] = useAxios();

  const postNewGroup = () => {
    FetchAllGroups({
      method: "post",
      url: "/accounts/me/managegroup/",
      requestConfig: {
        name: title,
      },
      customHeaders: {
        METHOD: "create",
      },
    });
  };

  const handleAddGroup = () => {
    if (!isEmpty) {
      postNewGroup();
      // setRefresh((prevState) => !prevState);
      groupCtx.setGroupHandler((prevState) => !prevState);
      setInvalid(false);
      setTitle("");
    } else {
      setInvalid(true);
    }
  };
  const handleKeyDownNewGroup = (e) => {
    if (e.key !== "Enter") return;
    handleAddGroup();
  };

  // <----- GET ALL GROUPES ----->
  const [Groupes, errorGroupes, loadingGroupes, GetAllGroupes] = useAxios();
  const getGroupes = () => {
    GetAllGroupes({
      method: "get",
      url: "/accounts/me/managegroup",
    });
  };

  useEffect(() => {
    getGroupes();
  }, [groupCtx.groupHandler]);

  // console.log("----------------------------");
  // console.log("modalid : ", radio);
  // <----- PIN ----->
  const [Pin, errorPin, loadingPin, pinHandler] = useAxios();
  const handlePin = () => {
    if (radio) {
      pinHandler({
        method: "post",
        url: props.pinUrl,
        requestConfig: {
          [props.idName]: props.article.id,
          group: radio,
        },
        customHeaders: {
          METHOD: "pin",
        },
      });
      props.setPinState(true);
      props.onClose();
      setSelectInvalid(false);
    } else {
      setSelectInvalid(true);
    }
  };

  // <----- UNPIN ----->
  const [Unpin, errorUnpin, loadingUnpin, UnpinHandler] = useAxios();
  const handleUnpin = () => {
    UnpinHandler({
      method: "post",
      url: props.pinUrl,
      requestConfig: {
        [props.idName]: props.article.id,
        group: name,
      },
      customHeaders: {
        METHOD: "unpin",
      },
    });
    props.setPinState(false);
    props.setunpinPost(false);
    groupCtx.setDeleteHandler((prevState) => !prevState);
  };

  return (
    <>
      {/* <----- !!!! PIN MODAL!!!! -----> */}

      <Modal
        visible={props.visible}
        onClose={() => {
          props.onClose();
          setSelectInvalid(false);
          setInvalid(false);
        }}
      >
        <div className={styles.body}>
          <input
            className={`${styles.input} ${!invalid ? "" : styles.invalid}`}
            placeholder="Nowa Grupa..."
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onKeyDown={handleKeyDownNewGroup}
            maxLength={20}
          ></input>
          <span className={styles.validationCounter}>{title.length}/20</span>
          <div className={styles.ListOfGroups}>
            <ul className={styles.sidesubs}>
              {loadingGroupes && <GroupModalLoader />}
              {/* DISPLAY GROUPS*/}
              {!loadingGroupes &&
                Groupes.length >= 1 &&
                Groupes.map((res) => (
                  <li key={res.id}>
                    <label>
                      {res.name ? res.name : "Brak Nazwy "}
                      <input
                        type="radio"
                        checked={radio === res.name}
                        value={res.name}
                        onChange={(e) => {
                          setRadio(e.target.value);
                        }}
                      ></input>
                    </label>
                  </li>
                ))}

              {/* DISPLAY VALUE NULL */}
              {!loadingGroupes && Groupes.length === 0 && (
                <p>Brak Utworzonych Grup</p>
              )}
            </ul>
          </div>
          <span
            className={`${styles.select} ${
              !selectInvalid ? "" : styles.selectInvalid
            }`}
          >
            ABY PRZYPIĄĆ ZAZNACZ GRUPE
          </span>

          <div className={styles.groupButtons}>
            {Groupes?.length === 0 || title?.length !== 0 ? (
              <button
                type="submit"
                onClick={handleAddGroup}
                onKeyDown={handleKeyDownNewGroup}
                className={cn("button", styles.button)}
              >
                DODAJ NOWĄ GRUPĘ
              </button>
            ) : (
              <button
                type="submit"
                onClick={handlePin}
                className={cn("button", styles.button)}
              >
                PRZYPNIJ
              </button>
            )}
          </div>
        </div>
      </Modal>

      {/* <----- !!!! REDIRECT MODAL!!!! -----> */}
      <Modal
        visible={props.redirectedUser}
        onClose={() => props.setRedirectedUser(false)}
      >
        <div className={styles.body}>
          <p className={styles.attention}>
            {`Aby odpiąć "${props.article.title}" przejdź do przypiętej grupy`}
          </p>
        </div>
      </Modal>

      {/* <----- !!!! UUNPIN MODAL!!!! -----> */}

      <Modal
        visible={props.unpinPost}
        onClose={() => props.setunpinPost(false)}
      >
        <div className={styles.body}>
          <h3 className={styles.unpinHeadline}>
            {`Czy na pewno chcesz odpiąć "${props.article.title}" ?`}
          </h3>
          <div className={styles.groupButtons}>
            <button
              type="submit"
              onClick={handleUnpin}
              className={cn("button", styles.button, styles.cancel)}
            >
              ODEPNIJ
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalPin;
