import { accountsEndpoints } from "api/endpoints";
import devscntrAuth from "api/Instance/devscntrAuth";
import axios from "axios";
import React, { useState, createContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router";

// Define logout timer, expiration time
let logoutTimer;

// Create authorization context
const AuthContext = createContext({
  token: "",
  isLoggedIn: false,
  userId: "",
  userImg: null,
  userName: "",
  isAuthor: false,
  isAdmin: false,
  isStaff: false,
  isSuperuser: false,
  level: 0,
  login: (token) => {},
  logout: () => {},
  changeUserImg: () => {},
});

// Calculate remaining token's time
const calculateRemainingTime = (expirationTime) => {
  // Get current date
  const currentTime = new Date().getTime();
  // Get expiration date
  const adjExpirationTime = new Date(expirationTime).getTime();

  // Calculate time
  const remainingDuration = adjExpirationTime - currentTime;

  // Return remaining token's duration
  return remainingDuration;
};

// Retrieve stored token if token didn't expire
const retrieveStoredToken = () => {
  // Get token stored
  const storedToken = localStorage.getItem("bearerToken");
  const storedExpirationDate = localStorage.getItem("expirationTime");

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("expirationTime");
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }
  // Set initalToken, if token is in Local Storage, if not storage returns null
  //const initialToken = localStorage.getItem("bearerToken");
  const initialUserId = localStorage.getItem("userId");
  const initialUserName = localStorage.getItem("userName");
  const initialIsAuthor = JSON.parse(localStorage.getItem("isAuthor"));
  const initialIsSuperuser = JSON.parse(localStorage.getItem("isSuperuser"));

  const [token, setToken] = useState(initialToken);
  const [userId, setUserId] = useState(initialUserId);
  const [userName, setUserName] = useState(initialUserName);
  const [userImg, setUserImg] = useState(
    `${process.env.PUBLIC_URL}/images/default-avatar.png`
  );
  const [userLevel, setUserLevel] = useState(0);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isSuperuser, setIsSuperuser] = useState(false);

  const userIsLoggedIn = !!token;

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  });

  useEffect(() => {
    userIsLoggedIn && getAccountInfo(token);
  }, []);

  // Handle logout
  const logoutHandler = useCallback(() => {
    // Remove user data from context
    setToken(null);
    setUserImg(null);
    setUserName("");
    setIsAuthor(false);
    setIsAdmin(false);
    setIsStaff(false);
    setIsSuperuser(false);
    setUserLevel(0);

    // Remove user data from local storage
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("isAuthor");
    localStorage.removeItem("isSuperuser");
    localStorage.removeItem("userName");
    localStorage.removeItem("expirationTime");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  // Handle login
  const loginHandler = (token, expirationTime) => {
    // Set token, to given token
    setToken(token);
    getAccountInfo(token);
    // Set token in Local Storage
    localStorage.setItem("bearerToken", token);

    localStorage.setItem("expirationTime", expirationTime);
    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
    window.location.reload();
  };

  /*
    useEffect(() => {
        if (tokenData) {
            logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData, logoutHandler]);
    */
  const getAccountInfo = async (token) => {
    try {
      const response = await devscntrAuth.request({
        method: "GET",
        url: accountsEndpoints.userData.myAccount,
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      localStorage.setItem("userId", response.data.data.user.id);
      setUserId(response.data.data.user.id);

      localStorage.setItem("userName", response.data.data.user.display_name);
      setUserName(response.data.data.user.display_name);

      //localStorage.setItem("isAuthor", response.data.data.user.is_author);
      setIsAuthor(response.data.data.user.is_author);
      setIsAdmin(response.data.data.user.is_admin);
      setIsStaff(response.data.data.user.is_staff);
      //localStorage.setItem("isSuperuser", response.data.data.user.is_superuser);
      setIsSuperuser(response.data.data.user.is_superuser);

      //console.log(response.data.data.user);

      setUserImg(
        response.data.image ||
          `${process.env.PUBLIC_URL}/images/default-avatar.png`
      );
      setUserLevel(response.data.user_current_level || 0);
      //console.log(response.data.all_xp_points);
      /*
      const avatar = response.data.image;
      avatar !== null &&
        setUserImg(
          "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png"
        );
      */
      //"/images/content/avatar.jpg"
    } catch (error) {
      console.log(error);
    }
  };

  const changeUserImgHandler = (img) => {
    setUserImg(img);
  };

  // Context values
  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    userID: userId,
    userImg: userImg,
    userName: userName,
    isAuthor: isAuthor,
    isAdmin: isAdmin,
    isStaff: isStaff,
    isSuperuser: isSuperuser,
    level: userLevel,
    login: loginHandler,
    logout: logoutHandler,
    changeUserImg: changeUserImgHandler,
  };

  // Return provider
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
