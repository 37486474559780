import React, { useState, useEffect } from "react";
import styles from "./AboutCompany.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";
import { convertFromRaw, EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import Thumbnail from "components/MiniItemsLayout/Thumbnail";

const AboutCompany = ({ user, company }) => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    if (company && company.description) {
      let fixedDescription = EditorState.createWithContent(
        convertFromRaw(JSON.parse(company.description))
      );
      setDescription(convertToHTML(fixedDescription.getCurrentContent()));
    }
  }, [company]);

  return (
    <div className={styles.container}>
      <div className={cn("title-blue", styles.subtitle)}>O firmie</div>
      <div className={styles.about}>
        <Link to={`/profile/${user?.id}`}>
          <Thumbnail
            className={user?.company?.logo && styles.image_wrapper}
            image={user?.company?.logo}
            imageFit="contain"
          />
        </Link>

        <div className={styles.info}>
          <Link to={`/profile/${user?.id}`}>
            <div className={styles.name}>{company?.name}</div>
          </Link>
          {company && company?.description ? (
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></div>
          ) : (
            <div className={styles.message}>
              Ta firma nie dodała jeszcze swojego opisu
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
