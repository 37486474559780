import React, { useState, useEffect } from "react";
import styles from "./Company.module.sass";
import cn from "classnames";
import Icon from "../../../components/Icon";
import { convertToHTML } from "draft-convert";
import { convertFromRaw, EditorState } from "draft-js";
import MiniJobOffer from "../../../components/MiniJobOffer";
import SkeletonLoader from "../../../components/MiniJobOffer/Loader";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import devscntrAuth from "../../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../../api/Instance/devscntrNoauth";
import Thumbnail from "components/MiniItemsLayout/Thumbnail";

const DUMMY_DATA = {
  company: {
    name: "Twoja nazwa firmy",
    taxNumber: "1234567890",
    image: null,
    phone: "000 000 000",
    email: "kontakt@devscntr.com",
    description:
      '{"blocks":[{"key":"auasq","text":"uighuigyuifyufyuj","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    localization: [
      {
        id: 4,
        city: "Leszno",
        code: "64-100",
        street: "Testowa",
        number: "12a/2",
      },
      {
        id: 5,
        city: "Góra",
        code: "56-200",
        street: "Policyjna",
        number: "14/1",
      },
      {
        id: 6,
        city: "Wschowa",
        code: "56-256",
        street: "Inna",
        number: "10",
      },
    ],
  },
  jobs: [
    {
      id: 4,
      created_at: "2022-08-05T14:16:36.235655Z",
      title: "Testowa oferta pracy [dummy data]",
      content:
        '{"blocks":[{"key":"auasq","text":"uighuigyuifyufyuj","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
      format: "3",
      salary: [
        {
          type: 1,
          salary_from: 100.5,
          salary_to: 300.2,
          hour_wage: 19.99,
        },
        {
          type: 2,
          salary_from: 1200.5,
          salary_to: 3000.2,
          hour_wage: 19.99,
        },
      ],
      seniority: "5",
      application_link: "",
      recruitment_format: "1",
      technologies: [
        {
          name: "React",
          level: 4,
        },
        {
          name: "VueJS",
          level: 5,
        },
      ],
      localization: [
        {
          id: 4,
          city: "Leszno",
          code: "64-100",
          street: "Testowa",
          number: "12a/2",
        },
        {
          id: 5,
          city: "Góra",
          code: "56-200",
          street: "Policyjna",
          number: "14/1",
        },
        {
          id: 6,
          city: "Wschowa",
          code: "56-256",
          street: "Inna",
          number: "10",
        },
      ],
    },
  ],
};

const Company = ({ id, data }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState(data);
  const [description, setDescription] = useState(company.description);
  const [jobsList, setJobsList] = useState(DUMMY_DATA.jobs);

  useEffect(() => {
    if (company?.description) {
      let fixedDescription = EditorState.createWithContent(
        convertFromRaw(JSON.parse(company?.description))
      );
      setDescription(convertToHTML(fixedDescription.getCurrentContent()));
    }
  }, [company]);

  return (
    <div className={styles.container}>
      <div className={styles.company_info}>
        <Thumbnail
          className={company?.logo && styles.image_wrapper}
          image={company?.logo}
          imageFit="contain"
        />
        <div className={styles.data}>
          <div className={styles.name}>{company?.name || "Brak nazwy"}</div>
          <div className={styles.tax_number}>
            <b>NIP: </b>
            {company?.NIP || "Brak numeru podatkowego"}
          </div>
          <div className={styles.contact}>
            <span>
              <Icon name="phone" />
              {company?.phone || "Brak numeru telefonu"}
            </span>
            <span>
              <b>Email: </b>
              {company?.mail || "Brak adresu email"}
            </span>
          </div>
        </div>
      </div>

      <div className={styles.about_us}>
        <div className={cn("title-purple", styles.subtitle)}>O firmie</div>
        {data.description ? (
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></div>
        ) : (
          <p className={styles.info}>Ta firma jeszcze nie dodała opisu</p>
        )}
      </div>
      <div className={styles.locations}>
        <div className={cn("title-blue", styles.subtitle)}>
          Lokalizacje firmy
        </div>
        {company?.locations?.length > 0 ? (
          <ul>
            {company?.locations?.map((item, index) => (
              <li key={index}>
                {item.street} {item.house_number}, {item.postcode} {item.city}
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles.info}>
            Ta firma jeszcze nie dodała żadnej lokalizacji
          </p>
        )}
      </div>
    </div>
  );
};

export default Company;
