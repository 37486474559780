import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Notification.module.sass";
import Icon from "../../Icon";
import Actions from "../../Actions";
import ItemPost from "./Item/ItemPost";

// data
import {useHistory} from "react-router";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import api from "../../../api/devscntr";
import ItemComments from "./Item/ItemComments";
import ItemMisc from "./Item/ItemMisc";

const navigation = [
    "Posty",
    "Komentarze",
    "Różne"
];

const NOTIFICATION_URL = "/accounts/me/notification/";

const Notification = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const location = useHistory();
  const [received, error, loading, axiosFetch] = useAxiosFunction();
  const [notifications, setNotifications] = useState();
  const [activeCommentTypeIndex, setActiveCommentTypeIndex] = useState(0);

  const getNotificationsAPI = () => {
      axiosFetch({
          axiosInstance: api,
          method: "GET",
          url: NOTIFICATION_URL
      })
  };

  useEffect(() => {
      if(received.length == 0)
        getNotificationsAPI()

      if(received.length !== 0){
            switch(activeIndex){
                case 0:
                    setNotifications(received["notifications posts"]);
                    break;
                case 1:
                    activeCommentTypeIndex == 0
                        ? setNotifications(received["notifications comments posts"])
                        : setNotifications(received["notifications comments courses"])
                    break;
                case 2:
                    setNotifications(received["notification misc"])
                    break;
            }
        }

    }, [received, activeIndex, activeCommentTypeIndex]);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.notification, className, {
          [styles.active]: visible,
        })}
      >
        <button
          className={cn(styles.head, styles.active)}
          onClick={() => visible ? setVisible(false) : setVisible(true)}
        >
          <Icon name="notification" size="24" />
        </button>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.title}>Powiadomienia</div>
          </div>
          <div className={styles.control}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                  <button
                      className={cn(styles.link, {
                        [styles.active]: index === activeIndex,
                      })}
                      onClick={() => setActiveIndex(index)}
                      key={index}
                  >
                    {x}
                  </button>
              ))}
            </div>
          </div>
          <div className={styles.list}>
              {activeIndex == 1 &&
                  <div className={styles.nav}>
                      <button
                          className={cn(styles.link, {
                              [styles.active]: 0 === activeCommentTypeIndex,
                          })}
                          onClick={() => setActiveCommentTypeIndex(0)}
                      >
                          Posty
                      </button>
                      <button
                          className={cn(styles.link, {
                              [styles.active]: 1 === activeCommentTypeIndex,
                          })}
                          onClick={() => setActiveCommentTypeIndex(1)}
                      >
                          Kursy
                      </button>
                  </div>}
            {notifications && notifications.map((x, index) => {
                if(index < 5){
                    switch (activeIndex){
                        case 0:
                            return <ItemPost
                                className={cn(styles.item, className)}
                                item={x}
                                key={index}
                                onClose={() => setVisible(false)}
                            />
                        case 1:
                            return <ItemComments
                                className={cn(styles.item, className)}
                                item={x}
                                key={index}
                                onClose={() => setVisible(false)}
                                type={activeCommentTypeIndex == 0 ? "commentPost" : "commentCourse"}
                            />
                        case 2:
                            return <ItemMisc
                                className={cn(styles.item, className)}
                                item={x}
                                key={index}
                                onClose={() => setVisible(false)}
                            />
                    }

                }

            })}
          </div>
          <Link
            className={cn("button", styles.button)}
            to="/notification"
            onClick={() => setVisible(false)}
          >
            Wyświetl wszystkie
          </Link>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Notification;
