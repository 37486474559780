import React from "react";
import styles from "./ModalMessage.module.sass";
import Modal from "../Modal";
import { useSelector, useDispatch } from "react-redux";
import { removeMessage } from "../../store/message/actions";

const types = {
  error: {
    title: "Błąd",
    color: "#cf0000",
  },
  success: {
    title: "Sukces",
    color: "#009e28",
  },
};

const ModalMessage = () => {
  const { visible, message, type } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const item = types[type] ?? {};

  const handleCloseMessage = () => {
    dispatch(removeMessage());
  };

  return (
    <Modal visible={visible} onClose={handleCloseMessage}>
      <div className={styles.container}>
        <div className={styles.title} style={{ color: item.color }}>
          {item?.title}
        </div>
        <div className={styles.content}>{message}</div>
      </div>
    </Modal>
  );
};
export default ModalMessage;
