import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Answer from "../../../../../Answer";
import Icon from "../../../../../Icon";
import axios from "axios";

const Control = ({ className, valueAnswer, setValueAnswer, sendReplyHandler, isReplied, userImg, comment, authCtx }) => {
  const [visible, setVisible] = useState(false);
  const [userReaction, setUserReaction] = useState(0);
  const [reactionNumber, setReactionNumber] = useState();

    const getReactionNum = () => {
        if (comment?.has_liked) return 1;
        if (comment?.has_disliked) return 2;
        return 0;
    };

    const reactionApi = async (reaction) => {
        try {
            const response = await axios.post(
                "https://devscntr-staging.herokuapp.com/api/v1/posts/article/comment-emote/",
                {
                    post: comment.id,
                    emotion: reaction,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authCtx.token,
                    },
                }
            );
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    const handleLike = () => {
        if (reactionNumber == 1) return;
        setReactionNumber(1);
        reactionApi(1);
    };

    const handleDislike = () => {
        if (reactionNumber == 2) return;
        setReactionNumber(2);
        reactionApi(2);
    };
    console.log(comment)

  return (
    <>
      <div
        className={cn(styles.control, className, { [styles.hidden]: visible })}
      >
          <div className={styles.favorite} onClick={handleLike}>
              <Icon
                  name={reactionNumber == 1 ? "thumbs-up-fill" : "thumbs-up"}
                  size={24}
                  viewBox="0 0 24 24"
              />
          </div>
          <div className={styles.favorite} onClick={handleDislike}>
              <Icon
                  name={reactionNumber == 2 ? "thumbs-down-fill" : "thumbs-down"}
                  size={24}
                  viewBox="0 0 24 24"
              />
          </div>
        <button className={styles.button} onClick={() => setVisible(true)}>
          Reply
        </button>
      </div>
        {visible && (!isReplied ? <Answer
            className={cn(styles.answer, {[styles.show]: visible})}
            avatar={userImg}
            onClose={() => setVisible(false)}
            currentValue={valueAnswer}
            setCurrentValue={setValueAnswer}
            onSendReply={sendReplyHandler}
        />
        : <div className={cn(styles.answer,styles.sent, {[styles.show]: visible})}>Odpowiedź została wysłana</div>
        )}
    </>
  );
};

export default Control;
