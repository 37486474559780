import React, { useEffect, useState } from "react";
import styles from "./NewJob.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Modal from "../../components/Modal";
import Schedule from "../../components/Schedule";
//import NameAndDescription from "./NameAndDescription";
//import Preview from "./Preview";
import Icon from "../../components/Icon";
import Panel from "screens/NewLayouts/Panel";
import { EditorState, convertToRaw } from "draft-js";
import axios from "axios";
import cn from "classnames";
import Card from "../../components/Card";
import { useHistory } from "react-router-dom";
import Details from "./Details";
//import Tags from "./Tags";
//import Location from "./Location";
import Salary from "./Salary";

import useAuth from "../../hooks/useAuth";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import { showError } from "../../utils/showError";
import { jobFormat, jobRecruitment, jobSeniority } from "../../data/dataArrays";
import { validateInput } from "utils/validation";
import TagsInput from "screens/NewLayouts/TagsInput";
import Content from "screens/NewLayouts/Content";
import LocationsInput from "screens/NewLayouts/LocationsInput";

const DUMMY_TAGS = [{ name: "jak zacząć", count: 20 }];
const DUMMY_LOCATIONS = [
  {
    city: "Leszno",
    code: "64-100",
    street: "Testowa",
    number: "12a/2",
  },
  {
    city: "Góra",
    code: "56-200",
    street: "Policyjna",
    number: "14/1",
  },
  {
    city: "Wschowa",
    code: "56-256",
    street: "Inna",
    number: "10",
  },
];

const NewJob = () => {
  // Set up axios hook
  const authCtx = useAuth();
  const [errorMsg, setErrorMsg] = useState("");

  const location = useHistory();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [isPostCreated, setIsPostCreated] = useState(false);

  const [title, setTitle] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [detailsData, setDetailsData] = useState({
    format: jobFormat[0],
    seniority: jobSeniority[0],
    application_link: "",
    recruitment_format: jobRecruitment[0],
  });

  const [salary, setSalary] = useState([]);

  const [locations, setLocations] = useState([]);
  const [companyLocations, setCompanyLocations] = useState([]);

  const [tags, setTags] = useState([]);

  const isValidUrl = (url) => {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  };

  const preparePrice = (price) => {
    if (price.includes(",")) {
      return price.replace(",", ".");
    }
    return price;
  };

  const validateFields = (data) => {
    // TITLE
    if (!validateInput(data.title, 100, 3, "Tytuł")) return;

    // DESCRIPTION
    if (data.content.length < 133) {
      showError("Opis nie może być pusty");
      return false;
    }

    // SALARY
    if (data.salary.length < 1) {
      showError("Musisz dodać przynajmniej jedną opcję wynagrodzenia");
      return false;
    }

    // DROPDOWNS
    if (!jobFormat.includes(detailsData.format)) {
      showError("Wybierz formę pracy");
      return false;
    }
    if (!jobSeniority.includes(detailsData.seniority)) {
      showError("Wybierz doświadczenie");
      return false;
    }

    // LOCATIONS
    if (data.localization.length < 1) {
      showError("Musisz dodać przynajmniej jedną lokalizację firmy");
      return false;
    }

    // TECHNOLOGIES
    if (data.tags.length < 1) {
      showError("Musisz dodać przynajmniej jedną wymaganą technologię");
      return false;
    }

    /*
    // LOCALIZATION
    if (formatOptions.indexOf(detailsData.format) + 1 === 4) {
      let isLocation = true;
      if (!detailsData.courseLocation.city.length > 0) isLocation = false;
      if (!detailsData.courseLocation.code.length > 0) isLocation = false;
      if (!detailsData.courseLocation.street.length > 0) isLocation = false;
      if (!detailsData.courseLocation.address.length > 0) isLocation = false;

      !isLocation && setErrorMsg("Podaj lokalizację");
      return isLocation;
    }
    */

    // VALIDATE REFLINK
    if (
      !isValidUrl(data?.application_link) &&
      data?.application_link?.length > 0
    ) {
      showError("Podany link nie jest poprawny");
      return false;
    }

    return true;
  };

  const getCompany = async (cancelToken) => {
    try {
      const response = await devscntrAuth.request({
        method: "get",
        url: "/accounts/company/show/",
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      const locations = response.data.locations;
      setCompanyLocations(
        locations?.map((item) => ({
          city: item.city,
          code: item.postcode,
          street: item.street,
          number: item.house_number,
        }))
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("cancel get company");
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCompany(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const addJobApi = async (data) => {
    try {
      const response = await devscntrAuth.post("/jobs/job-create/", data, {
        headers: {
          method: "create",
        },
      });
      console.log(response);
      setIsPostCreated(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleTitleChange = (event) => {
    const val = event.target.value;
    setTitle(val);
  };

  const prepareTagList = (tags) => {
    return tags.map((tag) => tag).join(",");
  };

  const handleSubmit = () => {
    console.log(detailsData);
    const stringTags = prepareTagList(tags.map((item) => item));
    let preparedData = {
      title: title,
      content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      format: jobFormat.indexOf(detailsData.format) + 1,
      salary: salary,
      seniority: jobSeniority.indexOf(detailsData.seniority) + 1,
      application_link: detailsData.application_link,
      recruitment_format:
        jobRecruitment.indexOf(detailsData.recruitment_format) + 1,
      tags: stringTags,
      localization: locations,
    };
    console.log(preparedData);
    if (validateFields(preparedData)) {
      addJobApi(preparedData);
    }
  };

  return (
    <>
      <div className={styles.row}>
        {!isPostCreated ? (
          <>
            <div className={styles.fields}>
              {errorMsg.length > 0 && (
                <div className={styles.error}>
                  <Icon name="info" size="24" />
                  {errorMsg}
                </div>
              )}
              <Content
                className={styles.card}
                onTitleChange={handleTitleChange}
                onDescriptionChange={handleEditorStateChange}
                title={title}
                description={editorState}
              />
              <Salary data={salary} setData={setSalary} />
              <Details data={detailsData} setData={setDetailsData} />
              <LocationsInput
                locations={locations}
                setLocations={setLocations}
                savedLocations={companyLocations}
              />
              <TagsInput tags={tags} setTags={setTags} maxTagNumber={12} />
            </div>
          </>
        ) : (
          <Card className={styles.postcreated}>
            <p>Oferta pracy została utworzona</p>
            <div className={styles.panel}>
              <button
                className={cn("button", styles.homebtn)}
                onClick={() => location.push("/jobs")}
              >
                Zobacz oferty pracy
              </button>
              <button
                className={cn("button-stroke", styles.newbtn)}
                onClick={window.location.reload}
              >
                Utwórz kolejną ofertę pracy
              </button>
            </div>
          </Card>
        )}
      </div>
      {!isPostCreated && (
        <Panel
          setVisiblePreview={setVisiblePreview}
          setVisibleSchedule={setVisibleModal}
          onSubmit={handleSubmit}
        />
      )}

      <TooltipGlodal />
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal>
    </>
  );
};

export default NewJob;
