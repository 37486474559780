import React, { useContext, useEffect, useState } from "react";
import styles from "./FollowedUser.module.sass";
import cn from "classnames";
import { Link } from "react-router-dom";

import axios from "axios";
import SkeletonLoader from "./Loader";

import useAuth from "../../../hooks/useAuth";
import devscntrAuth from "../../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../../api/Instance/devscntrNoauth";

const FollowedUser = ({ myFollows, user }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isFollowed, setIsFollowed] = useState(
    myFollows.includes(user.id) || false
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    //getMyFollows();
    //fetchFollows();
  }, []);

  useEffect(() => {
    setIsFollowed(myFollows.includes(user.id));
  }, [myFollows]);

  /*
  const fetchFollows = () => {
    devscntrAuth
      .get("/accounts/me/follow-user/")
      .then(function (response) {
        let followings = response.data.map((item) => item.id);
        setIsFollowed(followings.includes(user.id));
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  */

  const followUserApi = () => {
    const headers = {
      METHOD: isFollowed ? "unfollow" : "follow",
    };
    devscntrAuth
      .post(
        "/accounts/me/follow-user/",
        { id: user.id },
        { headers: { ...headers } }
      )
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleFollow = async () => {
    followUserApi();
    setIsFollowed((prev) => !prev);
  };

  return (
    <>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <div className={styles.followed_user}>
          <Link to={`/profile/${user.id}`}>
            <div className={styles.followed_user_data}>
              <div className={styles.followed_user_img}>
                <img
                  src={user?.image || "/images/default-avatar.png"}
                  alt={`Avatar użytkownika ${user?.display_name}`}
                />
              </div>
              <div className={styles.followed_user_name}>
                {user?.display_name || "Brak nazwy"}
              </div>
            </div>
          </Link>
          {authCtx.isLoggedIn && (
            <>
              {authCtx?.userID !== user?.id ? (
                isFollowed ? (
                  <button
                    className={cn("button-stroke")}
                    onClick={handleFollow}
                  >
                    Obserwujesz
                  </button>
                ) : (
                  <button className={cn("button")} onClick={handleFollow}>
                    Obserwuj
                  </button>
                )
              ) : (
                <Link
                  to={`/profile/${user.id}`}
                  className={cn("button-stroke")}
                >
                  Twój profil
                </Link>
              )}
            </>
          )}
          {/* {isFollowed ? (
            <button className={cn("button-stroke")} onClick={handleFollow}>
              Obserwujesz
            </button>
          ) : (
            <button className={cn("button")} onClick={handleFollow}>
              Obserwuj
            </button>
          )} */}
        </div>
      )}
    </>
  );
};

export default FollowedUser;
