import JobsMap from "components/JobsMap";
import React from "react";
import { useParams } from "react-router";
import JobOffer from "../JobOffer";
import JobOffers from "../JobOffers";

const Jobs = () => {
  const { id } = useParams();
  return (
    <>
      {id !== undefined ? <JobOffer /> : <JobOffers />}
      <JobsMap />
    </>
  );
};

export default Jobs;
