export const setStatusName = id => {
  switch (id) {
    case 0: return 'Opublikowany';
    case 1: return 'Zaplanowany';
    case 2: return 'Szkic';
    default: return '-';
  }
}

export const setTypeName = id => {
  switch (id) {
    case 0: return 'Post';
    case 1: return 'Kurs';
    case 2: return 'Oferta pracy';
    case 3: return 'Technologia';
    default: return '-';
  }
}

export const setShortenAmount = value => {
  if (isNaN(value)) return '-';
  if (value < 1000) return value;

  const stringNumber = value.toString().replace(/[^0-9.]/g, '');
  const si = [
    { v: 1E3, s: "K" },
    { v: 1E6, s: "M" },
    { v: 1E9, s: "B" },
    { v: 1E12, s: "T" },
    { v: 1E15, s: "P" },
    { v: 1E18, s: "E" }
  ];

  let id;
  for (id = si.length - 1; id > 0; id--) {
    if (stringNumber >= si[id].v) break;
  }
  return (stringNumber / si[id].v).toFixed(1).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[id].s;
}