import React from "react";
import styles from "./Thumbnail.module.sass";
import cn from "classnames";

const Thumbnail = ({ className, image, imageFit = "cover" }) => {
  return (
    <div className={cn(styles.image, className && className)}>
      <img
        src={image || "/images/image-placeholder.png"}
        style={{ objectFit: imageFit }}
        alt=""
      />
    </div>
  );
};

export default Thumbnail;
