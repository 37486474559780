import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "components/Icon";
import Actions from "components/Actions";
import { Link } from "react-router-dom";

const Panel = ({
  setVisiblePreview,
  setVisibleSchedule,
  onSubmit,
  buttonText,
}) => {
  const actions = [
    {
      title: "Podgląd",
      icon: "expand",
      action: () => setVisiblePreview(true),
    },
    {
      title: "Zapisz szkic",
      icon: "edit",
      action: () => setVisiblePreview(true),
    },
    {
      title: "Zaplanuj publikację",
      icon: "calendar",
      action: () => setVisibleSchedule(true),
    },
    {
      title: "Udostępnij",
      icon: "link",
      action: () => console.log("Get shareable link"),
    },
    {
      title: "Wyczyść pola",
      icon: "close",
      action: () => console.log("Clear data"),
    },
  ];

  return (
    <div className={cn(styles.panel)}>
      <div className={styles.info}>
        <Link className={cn("button-stroke", styles.button)} to="/">
          <Icon name="arrow-left" size="24" />
          <span className={styles.buttonText}>Powrót</span>
        </Link>
        <div className={styles.lastSave}>
          <Icon name="check-all" size="24" />
          Ostatni zapis <span>Oct 4, 2021 - 23:32</span>
        </div>
      </div>
      <div className={styles.btns}>
        {/* <button className={cn("button-stroke", styles.button)}>
          Zapisz szkic
        </button> */}
        <button className={cn("button", styles.button)} onClick={onSubmit}>
          {buttonText || "Opublikuj"}
        </button>
        <Actions
          className={styles.actions}
          classActionsHead={styles.actionsHead}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
          up
        />
      </div>
    </div>
  );
};

export default Panel;
