import React, { useEffect, useState } from "react";
import styles from "./Tags.module.sass";
import cn from "classnames";
import Card from "components/Card";
import Search from "components/Search";
import Checkbox from "components/Checkbox";
import devscntrAuth from "api/Instance/devscntrAuth";
import { accountsEndpoints, tagsEndpoints } from "api/endpoints";
import axios from "axios";
import { showError } from "utils/showError";

const Tags = ({ className }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [search, setSearch] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showTags, setShowTags] = useState(false);
  const [showSelected, setShowSelected] = useState(false);

  const toggleTag = (index) => {
    if (selected?.includes(tags[index].name)) {
      setSelected((prev) => prev.filter((item) => item !== tags[index].name));
    } else {
      setSelected((prev) => [...prev, tags[index].name]);
    }
  };

  const getAllTags = async (cancelToken) => {
    try {
      const response = await devscntrAuth.request({
        method: "get",
        url: `${tagsEndpoints.show}`,
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      setAllTags(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMyTags = async (cancelToken) => {
    try {
      const response = await devscntrAuth.request({
        method: "get",
        url: `${accountsEndpoints.userData.tagPreferences}`,
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      setSelected(response.data.map((item) => item.name));
      setIsMounted(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getAllTags(cancelToken);
    getMyTags(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [refresh]);

  const prepareTags = () => {
    let preparedTags = allTags;
    if (search !== "") {
      preparedTags = preparedTags
        ?.filter((item) => item.name.includes(search))
        .sort();
    }

    setTags(preparedTags.sort((a, b) => b.tags_count - a.tags_count));
  };

  useEffect(() => {
    prepareTags();
  }, [allTags, search]);

  const updateTags = async () => {
    try {
      const response = await devscntrAuth.post(
        accountsEndpoints.userData.tagPreferences,
        { tags: selected?.map((tag) => tag).join(",") }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
      showError(
        "Nie udało się zaktualizować preferowanych tagów. Spróbuj ponownie później"
      );
    }
  };

  useEffect(() => {
    if (isMounted) {
      updateTags();
    }
  }, [selected]);

  return (
    <Card
      className={cn(styles.card, className, styles.container)}
      title="Tagi"
      classTitle="title-red"
      head={
        <Search
          className={styles.form}
          value={search}
          setValue={setSearch}
          placeholder="Szukaj..."
          type="text"
          name="search"
          icon="search"
        />
      }
    >
      <Checkbox
        className={styles.checkbox}
        value={showSelected}
        onChange={() => setShowSelected((prev) => !prev)}
        content="Pokaż tylko zaznaczone"
      />
      <div className={styles.tags_list}>
        {tags?.map((item, index) =>
          showSelected ? (
            selected?.includes(item.name) && (
              <button
                key={`settings_tag_${item.id}`}
                onClick={() => toggleTag(index)}
                className={cn(
                  styles.item,
                  selected?.includes(item.name) && styles.selected
                )}
                style={{ backgroundColor: item.colour || "#ffffff" }}
              >
                {item.name}
              </button>
            )
          ) : (
            <button
              key={`settings_tag_${index}`}
              onClick={() => toggleTag(index)}
              className={cn(
                styles.item,
                selected?.includes(item.name) && styles.selected
              )}
              style={{ backgroundColor: item.colour || "#ffffff" }}
            >
              {item.name}
            </button>
          )
        )}
      </div>
      {/* 
            tags?.map(
              (item, index) =>
                index < TAGS_LIMIT && (
                  <button
                    key={`settings_tag_${index}`}
                    onClick={() => toggleTag(index)}
                    className={cn(
                      styles.item,
                      selected?.includes(item) && styles.selected
                    )}
                  >
                    {item}
                  </button>
                )
      
      {!showSelected && (
        <button
          className={styles.button}
          onClick={() => setShowTags((prev) => !prev)}
        >
          {showTags ? "Pokaż mniej" : "Pokaż wszystkie"}
        </button>
      )} */}
    </Card>
  );
};
export default Tags;
