import { useContext } from "react";
import { Redirect } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Logout = () => {
  // Authorization context
  const authCtx = useAuth();

  // Handle logout
  const logoutHandler = () => {
    authCtx.logout();
  };

  // Logout, then redirect to sign-in page
  logoutHandler();
  return <Redirect to="/sign-in" />;
};

export default Logout;
