import React from "react";
import styles from "./More.module.sass";
import Actions from "components/Actions";
import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import { coursesEndpoints, postsEndpoints } from "api/endpoints";

const types = {
  post: postsEndpoints.createComment,
  course: coursesEndpoints.createComment,
};

const More = ({ id, author, type, refresh }) => {
  const authCtx = useAuth();

  const deleteUrl = types[type] ?? "";
  const handleDelete = () => {
    if (deleteUrl === "") return;
    console.log("delete comment");
    console.log({ id: id, author: author });
    try {
      const response = devscntrAuth.delete(`${deleteUrl}${id}/`);
      console.log(response);
      setTimeout(refresh, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const actions = [
    // {
    //   title: "Edytuj",
    //   icon: "edit",
    //   action: () => console.log('test'),
    // },
    {
      title: "Zgłoś",
      icon: "ticket",
      action: () => console.log("test"),
    },
    authCtx.isModerator ||
      (authCtx.isAdmin && {
        title: "Usuń komentarz",
        icon: "close",
        color: true,
        action: handleDelete,
      }),
  ];

  return (
    <Actions
      className={styles.menu}
      classActionsHead={styles.settings}
      classActionsBody={styles.actionsBody}
      classActionsOption={styles.actionsOption}
      items={actions}
    />
  );
};
export default More;
