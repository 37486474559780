import React, { useEffect, useState } from "react";
import styles from "./Search.module.sass";
import { useParams } from "react-router-dom";
import cn from "classnames";
import LatestCourses from "../../components/LatestCourses";
import LatestComments from "../../components/LatestComments";
import TooltipGlodal from "../../components/TooltipGlodal";
import Card from "../../components/Card";
import axios from "axios";
import MiniPost from "../../components/MiniPost";
import MiniCourse from "../../components/MiniCourse";

import useAuth from "../../hooks/useAuth";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../api/Instance/devscntrNoauth";
import { miscEndpoints } from "api/endpoints";
import Loader from "components/MiniCourse/Loader";
import { pageSize } from "data/dataArrays";

const DUMMY_DATA = [
  {
    id: 43,
    created_at: "2022-07-27T19:43:52.171046Z",
    author: 5,
    author_name: "Bitcoin Majster <frontend@mail.mail>",
    display_name: "BtcMiner21",
    title: "asdasfsadfasdfafdgadsfadsg",
    description:
      '{"blocks":[{"key":"al10r","text":"sdgfhfyhgjukjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    image: "https://d315baofnr6zvj.cloudfront.net/Bez_tytu%C5%82u.png",
    author_image:
      "https://d315baofnr6zvj.cloudfront.net/userimages/profile_img_5.jpeg",
    test: null,
    tags: [
      {
        name: "na start",
        tags_count: 2,
      },
      {
        name: "frontend",
        tags_count: 5,
      },
      {
        name: "backend",
        tags_count: 1,
      },
    ],
    emotions_likes_count: 1,
    emotions_dislikes_count: 0,
    rating_count: 1,
    rating: 5.0,
    rated: false,
    pinned: false,
    comments_count: 1,
    emotion_of_user: [],
  },
];

const postsPerPage = pageSize;

const Search = () => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(true);

  // PAGINATION
  const [currentPage, setCurentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [onlyOnePage, setOnlyOnePage] = useState(false);

  const { search } = useParams();
  const [results, setResults] = useState([]);

  const getSearchResults = async (search, cancelToken) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.request({
        method: "get",
        url: `${miscEndpoints.search}?query=${search}&page=${currentPage}&page_size=${postsPerPage}`,
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      setResults(response?.data?.results);

      // PAGINATION
      const lastPageNum = Math.ceil(
        parseInt(response.data.count) / postsPerPage
      );
      setOnlyOnePage(response.data.count <= postsPerPage);
      setLastPage(lastPageNum);

      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("search canceled");
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getSearchResults(search, cancelToken);
    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [search, currentPage]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Card
            className={styles.heading}
            title={`Wyniki wyszukiwania dla "${search}"`}
            classTitle="title-red"
            oneLine={true}
          />
          {/* <h3 className={styles.heading}>
            Wyniki wyszukiwania dla <b>"{search}"</b>
          </h3> */}
          {/* <Category
            categories={categories}
            onChangeCategory={handleChangeCategory}
          /> */}
          {isLoading ? (
            <Loader cards={4} />
          ) : results?.length > 0 ? (
            <>
              {results?.map((article, index) =>
                article?.localization ? (
                  <MiniCourse article={article} key={index} />
                ) : (
                  <MiniPost article={article} key={index} />
                )
              )}
              {!onlyOnePage && (
                <div className={styles.foot}>
                  {currentPage > 1 && (
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                      style={{ marginRight: "12px" }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setCurentPage((prevState) => --prevState);
                      }}
                    >
                      Poprzednia
                    </button>
                  )}
                  {currentPage > 1 && (
                    <button
                      className={styles.pagination_numbers}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setCurentPage((prevState) => --prevState);
                      }}
                    >
                      {currentPage - 1}
                    </button>
                  )}
                  {currentPage !== 0 && (
                    <button
                      className={styles.pagination_numbers}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      {currentPage}
                    </button>
                  )}
                  {currentPage < lastPage && (
                    <button
                      className={styles.pagination_numbers}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setCurentPage((prevState) => ++prevState);
                      }}
                    >
                      {currentPage + 1}
                    </button>
                  )}
                  {currentPage < lastPage - 1 && (
                    <>
                      ...
                      <button
                        className={styles.pagination_numbers}
                        onClick={() => {
                          window.scrollTo(0, 0);
                          setCurentPage(lastPage);
                        }}
                      >
                        {lastPage}
                      </button>
                    </>
                  )}

                  {currentPage != lastPage && (
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                      style={{ marginLeft: "12px" }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setCurentPage((prevState) => ++prevState);
                      }}
                    >
                      Następna
                    </button>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className={styles.no_results}>Brak wyników</div>
          )}
        </div>
        <div className={styles.col}>
          <LatestCourses className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Search;
