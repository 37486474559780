import React from "react";
import styles from "./Author.module.sass";
import { Link } from "react-router-dom";

const Author = ({ id, username, image }) => {
  return (
    <Link className={styles.author} to={`/profile/${id}`}>
      <div className={styles.author_image}>
        <img src={image || "/images/default-avatar.png"} alt="" />
      </div>
      <span>{username}</span>
    </Link>
  );
};

export default Author;
