import React, { useState, useEffect } from "react";
import styles from "./Company.module.sass";
import cn from "classnames";
import Icon from "components/Icon";
import Card from "components/Card";
import { Link } from "react-router-dom";
import { convertFromRaw, EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";

const Company = ({ user }) => {
  const [description, setDescription] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    if (user?.company?.description) {
      let fixedDescription = EditorState.createWithContent(
        convertFromRaw(JSON.parse(user?.company?.description))
      );
      setDescription(convertToHTML(fixedDescription.getCurrentContent()));
    }
  }, [user]);

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title="Profil firmy"
      classTitle={cn("title-purple", styles.title)}
      // head={
      //   <Link to={`/profile/${user?.id}`} className={cn("button")}>
      //     Edytuj profil firmy
      //   </Link>
      // }
    >
      <div className={styles.details}>
        <div className={styles.image}>
          <img
            src={user?.company?.logo || "/images/image-placeholder.png"}
            alt=""
          />
        </div>
        <div className={styles.user_info}>
          <span className={styles.username}>
            {user?.company?.name || "Brak nazwy"}
          </span>
          <div className={styles.list_item}>
            <b>NIP:</b> {user?.company?.NIP || "Brak NIPu"}
          </div>
          <span className={styles.time}>
            Data utworzenia: {user?.last_logged?.slice(0, 10) || "Brak danych"}
          </span>
        </div>
      </div>
      <div>
        <h4 className={styles.subheader}>Dane kontaktowe</h4>
        <div className={styles.list_item}>
          <b>@</b> {user?.company?.mail || "Brak emailu"}
        </div>
        <div className={styles.list_item}>
          <Icon name="phone" size={16} />
          {user?.company?.phone || "Brak nr telefonu"}
        </div>
      </div>

      <div>
        <h4 className={styles.subheader}>Dane liczbowe</h4>
        <div className={styles.list_item}>
          <b>Rozmiar firmy</b> {`${user?.company?.size}+` || "Brak danych"}
        </div>
        <div className={styles.list_item}>
          <b>Oferty pracy:</b> {user?.jobs_count || 0}
        </div>
      </div>

      {user?.company?.locations?.length > 0 && (
        <div>
          <h4 className={styles.subheader}>Lokalizacja</h4>
          <ul>
            {user?.company?.locations.map((item, index) => (
              <li
                key={`company_location_${index}`}
                className={styles.list_item}
              >
                <b>-</b> {item.street} {item.house_number}, {item.city}{" "}
                {item.postcode}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className={styles.description}>
        <div className={cn("title-green", styles.description_title)}>
          <div
            className={cn(
              styles.title_head,
              contentVisible ? styles.active : ""
            )}
            onClick={() => setContentVisible((prev) => !prev)}
          >
            <div>Opis firmy</div>
            <div>
              <Icon name="arrow-down" size={20} />
            </div>
          </div>
        </div>
        {contentVisible &&
          (user?.company?.description ? (
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></div>
          ) : (
            <p className={styles.info}>Ta firma jeszcze nie dodała opisu</p>
          ))}
      </div>
    </Card>
  );
};
export default Company;
