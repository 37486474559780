import React from "react";
import styles from "./EditProfile.module.sass";
import cn from "classnames";
import Profile from "./Profile";
import Account from "./Account";
import Company from "./Company";

const EditProfile = ({ user, setUser, refresh }) => {
  return (
    <div className={styles.container}>
      <Profile user={user} refresh={refresh} />
      <Account user={user} />
      {user.company && <Company user={user} refresh={refresh} />}
    </div>
  );
};

export default EditProfile;
