import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./LocationsInput.module.sass";
import Card from "components/Card";
import TextInput from "components/TextInput";
import Icon from "components/Icon";
import Dropdown from "components/Dropdown";
import { validateInput } from "utils/validation";

const LocationsInput = ({
  className,
  locations,
  setLocations,
  savedLocations,
}) => {
  const [currentLocation, setCurrentLocation] = useState({
    city: "",
    code: "",
    street: "",
    number: "",
    location_lat: null,
    location_lon: null,
  });

  useEffect(() => {
    console.log(locations);
    console.log(savedLocations);
  }, [locations]);

  const handleLocationChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentLocation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validation = () => {
    if (!validateInput(currentLocation.city, 40, 2, "Miasto")) {
      return false;
    }
    if (!validateInput(currentLocation.code, 6, 6, "Kod pocztowy")) {
      return false;
    }
    if (!validateInput(currentLocation.street, 40, 2, "Ulica")) {
      return false;
    }
    if (!validateInput(currentLocation.number, 15, 0, "Numer budynku/pokoju")) {
      return false;
    }
    return true;
  };

  const removeLocation = (index) => {
    setLocations(locations.filter((location, i) => i !== index));
  };

  const addLocationFromList = (index) => {
    const newLocation = savedLocations[index];
    if (locations.includes(newLocation)) return;
    setLocations((prev) => [...prev, newLocation]);
  };

  const handleEdit = (index) => {
    setCurrentLocation(locations[index]);
    removeLocation(index);
  };

  const handleLocationAppend = (e) => {
    //e.preventDefault();
    console.log(currentLocation);
    if (validation()) {
      setLocations((prev) => [...prev, currentLocation]);
      setCurrentLocation({
        city: "",
        code: "",
        street: "",
        number: "",
        location_lat: "",
        location_lon: "",
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    handleLocationAppend();
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="Lokalizacja"
      classTitle="title-yellow"
    >
      <div className={styles.locations}>
        <div className={styles.locations_list}>
          {savedLocations?.length > 0 && (
            <h3 className={styles.heading}>Zapisane miejsca</h3>
          )}
          <ul>
            {savedLocations?.length > 0 &&
              savedLocations?.map((x, index) => (
                <div
                  className={cn(
                    styles.locaton_item,
                    locations.includes(x) && styles.active
                  )}
                  key={index}
                >
                  <div className={styles.location_item_data}>
                    {x.street} {x.number}, {x.city} {x.code}
                  </div>
                  <div className={styles.btns}>
                    <button
                      className={styles.location_item_btn}
                      onClick={() =>
                        locations.includes(x)
                          ? removeLocation(locations.indexOf(x))
                          : addLocationFromList(index)
                      }
                    >
                      {locations.includes(x) ? (
                        <Icon name="trash" size={20} />
                      ) : (
                        <Icon name="check" size={20} />
                      )}
                    </button>
                  </div>
                </div>
              ))}
            {locations.map(
              (x, index) =>
                !savedLocations?.includes(x) && (
                  <div
                    className={cn(
                      styles.locaton_item,
                      locations.includes(x) && styles.active
                    )}
                    key={index}
                  >
                    <div className={styles.location_item_data}>
                      {x.street} {x.number}, {x.city} {x.code}
                    </div>
                    <div className={styles.btns}>
                      <button
                        className={styles.location_item_btn}
                        onClick={() => handleEdit(index)}
                      >
                        <Icon name="edit" size={20} />
                      </button>
                      <button
                        className={styles.location_item_btn}
                        onClick={() => removeLocation(index)}
                      >
                        <Icon name="trash" size={20} />
                      </button>
                    </div>
                  </div>
                )
            )}
          </ul>
        </div>
        {/* <div className={styles.locations_list}>
          <h3 className={styles.heading}>Wybrane lokalizacje</h3>
          <ul>
            {locations.map((x, index) => (
              <div className={cn(styles.locaton_item)} key={index}>
                <div className={styles.location_item_data}>
                  <span style={{ marginRight: "16px" }}>{index + 1}.</span>
                  ul. {x.street} {x.number}, {x.city} {x.code}
                </div>
                <div className={styles.btns}>
                  <button
                    className={styles.location_item_remove}
                    onClick={() => removeLocation(index)}
                  >
                    <Icon name="close" size={22} />
                  </button>
                </div>
              </div>
            ))}
          </ul>
        </div> */}
        <div className={styles.add_location}>
          <h3 className={styles.heading}>Dodaj lokalizację</h3>
          <div className={styles.row}>
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                label="Miasto"
                name="city"
                type="text"
                tooltip="Miasto"
                value={currentLocation.city}
                onChange={handleLocationChange}
                onKeyDown={handleKeyDown}
              />
              <TextInput
                className={styles.field}
                label="Kod pocztowy"
                name="code"
                type="text"
                tooltip="Kod pocztowy miasta"
                value={currentLocation.code}
                onChange={handleLocationChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                label="Ulica"
                name="street"
                type="text"
                tooltip="Ulica"
                value={currentLocation.street}
                onChange={handleLocationChange}
                onKeyDown={handleKeyDown}
              />
              <TextInput
                className={styles.field}
                label="Numer budynku"
                name="number"
                type="text"
                tooltip="Numer budynku"
                value={currentLocation.number}
                onChange={handleLocationChange}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <button className={cn("button")} onClick={handleLocationAppend}>
            Dodaj lokalizację
          </button>
        </div>
      </div>
    </Card>
  );
};
export default LocationsInput;
