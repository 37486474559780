import Modal from "components/Modal";
import React, { useEffect, useState } from "react";
import styles from "./UserModal.module.sass";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { hideUserInfo } from "store/userInfo/actions";
import devscntrAuth from "api/Instance/devscntrAuth";
import axios from "axios";
import Icon from "components/Icon";

const UserModal = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const { visible, id } = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    display_name: "Konrad_21",
    firstname: "Konrad",
    lastname: "Test",
    email: "test@pwsz.xd",
    phone: "+48000213456",
    created_at: "2022-08-01T08:10:40.475114Z",
    followers_count: 21,
  });

  const getUserInfo = async (cancelToken) => {
    try {
      const response = await devscntrAuth.get(`/accounts/me/user-info/`, {
        headers: {
          "USER-ID": id,
        },
        cancelToken: cancelToken.token,
      });
      console.log(response?.data);
      setUser(response?.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("get user canceled");
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (isMounted) {
      //getUserInfo(cancelToken);
    } else {
      setIsMounted(true);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [id]);

  const handleClose = () => {
    dispatch(hideUserInfo());
  };

  return (
    <Modal
      outerClassName={styles.modal}
      visible={visible}
      onClose={handleClose}
    >
      <div className={styles.container}>
        <div className={styles.details}>
          <div className={styles.image}>
            <img src={user.image || "/images/default-avatar.png"} alt="" />
          </div>
          <div className={styles.user_info}>
            <span className={styles.username}>{user.display_name}</span>
            <span className={styles.time}>
              Ostatnie logowanie: {user.created_at.slice(0, 10)}
            </span>
          </div>
        </div>
        <div className={styles.personal_informations}>
          <div className={cn("title-yellow", styles.informations_title)}>
            Informacje o użytkowniku
          </div>
          <div className={styles.list_item}>
            {user.firstname || "Brak imienia"}{" "}
            {user.lastname || "brak nazwiska"}
          </div>
          <div className={styles.list_item}>
            <b>@</b> {user.email || "Brak emailu"}
          </div>
          <div className={styles.list_item}>
            <Icon name="phone" size={16} />
            {user.phone || "Brak nr telefonu"}
          </div>
        </div>
        <div className={styles.account_informations}>
          <div className={cn("title-yellow", styles.informations_title)}>
            Informacje o koncie
          </div>
          <div className={styles.time}>
            Data utworzenia: {user.created_at.slice(0, 10)}
          </div>
          <div className={styles.followers}>
            Obserwujący: {user.followers_count}
          </div>
        </div>
        <button
          className={cn("button-stroke")}
          onClick={() => setShowActions((prev) => !prev)}
        >
          {showActions ? "Ukryj opcje" : "Pokaż opcje"}
        </button>
        {showActions && (
          <div className={styles.actions}>
            <button className={cn("button")}>Strona profilu</button>
            <button className={cn("button")}>Posty</button>
            <button className={cn("button")}>Kursy</button>
            <button className={cn("button")}>Oferty pracy</button>
            <button className={cn("button")}>Zresetuj hasło</button>
            <button className={cn("button")}>Zresetuj email</button>
            <button className={cn("button")}>Pokaż logi</button>
            <button className={cn("button")}>Zablokuj</button>
          </div>
        )}
      </div>
    </Modal>
  );
};
export default UserModal;
