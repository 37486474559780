import React from "react";
import cn from "classnames";
import Card from "../../../components/Card";
import styles from "./Category.module.sass";
import Scroll from "../../../components/Scroll"

// const category = [
//     {
//         name: "kategoria 1",
//         image: "/images/content/post-pic-5.jpg"
//     },
//     {
//         name: "kategoria 2",
//         image: "/images/content/post-pic-4.jpg"
//     },
//     {
//         name: "kategoria 3",
//         image: "/images/content/post-pic-3.jpg"
//     },
//     {
//         name: "kategoria 4",
//         image: "/images/content/post-pic-2.jpg"
//     },
//     {
//         name: "kategoria 5",
//         image: "/images/content/post-pic-1.jpg"
//     },
//     {
//         name: "kategoria 6",
//         image: "/images/content/post-pic-5.jpg"
//     },
//     {
//         name: "kategoria 6",
//         image: "/images/content/post-pic-5.jpg"
//     },
//     {
//         name: "kategoria 6",
//         image: "/images/content/post-pic-5.jpg"
//     },
//     {
//         name: "kategoria 6",
//         image: "/images/content/post-pic-5.jpg"
//     },
//     {
//         name: "kategoria 6",
//         image: "/images/content/post-pic-5.jpg"
//     },
// ]

const Category = (props) => {
    return(
        <Scroll />
    );
}
export default Category;