import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
  message: "",
  type: "error",
};

export const messageSlice = createSlice({
  name: "message",
  initialState: initialState,
  reducers: {
    showMessage: (state, action) => {
      state.visible = true;
      const { message, type } = action.payload;
      state.message = message;
      state.type = type;
    },
    clearMessage: (state) => {
      state.visible = false;
      state.message = "";
    },
  },
});
