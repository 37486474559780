import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Comments.module.sass";
import Item from "./Item";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import axios from "axios";
import { validateInput } from "../../../../utils/validation";

import useAuth from "../../../../hooks/useAuth";
import devscntrAuth from "../../../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../../../api/Instance/devscntrNoauth";
import Comment from "components/Comment";
import { showLoginModal } from "utils/showLoginModal";

const Comments = ({ className, article }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [content, setContent] = useState("");
  const [comments, setComments] = useState([]);

  const [replied, setReplied] = useState(false);
  const [allowClick, setAllowClick] = useState(true);

  const getCommentsApi = async (cancelToken) => {
    try {
      const response = await axiosInstance.request({
        method: "get",
        url: "/course/course-comment-anon/",
        headers: {
          "COURSE-ID": article.id,
        },
        cancelToken: cancelToken.token,
      });
      const sortedComments =
        response?.data?.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        ) || [];
      setComments(sortedComments);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("course comments canceled");
      } else {
        console.log(error);
      }
    }
  };

  const onComment = () => {
    setReplied((prev) => !prev);
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCommentsApi(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [replied]);

  const addCommentApi = async () => {
    try {
      const response = await devscntrAuth.post("/course/course-comment/", {
        courseID: article.id,
        text: content,
      });
      console.log(response);
      onComment();
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    handleClick();
  };

  const handleClick = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (!allowClick) return;
    setAllowClick(false);
    setTimeout(() => setAllowClick(true), 2000);

    if (!validateInput(content, 255)) return;
    addCommentApi();
    //setTimeout(getCommentsApi(), 1000);
    setContent("");
  };

  return (
    <>
      <div className={cn(styles.comments, className)}>
        <div className={styles.head}>
          <div className={styles.title}>
            <div className={styles.counter}>
              {comments?.length > 0 ? comments?.length : "0"}
            </div>
            Komentarze
          </div>
        </div>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className={styles.editor}
          onKeyDown={handleKeyDown}
          placeholder="Podziel się swoją opinią..."
        />
        <button className={cn("button", styles.add_btn)} onClick={handleClick}>
          Dodaj
        </button>
        <div className={styles.list}>
          {comments?.length > 0 &&
            comments?.map((x, index) => (
              <Comment
                key={`comment_${index}`}
                className={styles.item}
                onComment={onComment}
                item={x}
                type="course"
              />
            ))}
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Comments;
