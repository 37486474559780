import React, { useState } from "react";
import styles from "./Item.module.sass";
import { Link, Redirect } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Checkbox from "components/Checkbox";
import Icon from "components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { showUserInfo } from "store/userInfo/actions";
import Actions from "components/Actions";
import { adminEndpoints } from "api/endpoints";
import devscntrAuth from "api/Instance/devscntrAuth";

const Item = ({ tag, selected, setSelected, refresh }) => {
  const authCtx = useAuth();
  const isAdmin = true;
  const dispatch = useDispatch();

  const handleShowUserInfo = (id) => {
    dispatch(showUserInfo(id));
  };

  const handleSelect = () => {
    if (selected) {
      setSelected((prev) => prev.filter((item) => item.id === tag?.id));
    } else {
      setSelected((prev) => [...prev, tag?.id]);
    }
  };

  const banUser = () => {
    console.log("ban user");
    deleteTag();
  };

  const editTag = () => {
    console.log("edit tag");
  };

  const deleteTag = async () => {
    try {
      const response = await devscntrAuth.delete(
        `${adminEndpoints.tags.show}${tag.id}/`
      );
      console.log({ response: response.data });
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  const actions = [
    {
      title: "Usuń tag i autora",
      icon: "lock",
      color: true,
      action: banUser,
    },
    {
      title: "Usuń tag",
      icon: "trash",
      color: true,
      action: deleteTag,
    },
  ];

  const showSettings = () => {
    console.log("ustawienia konta");
  };

  return (
    isAdmin && (
      <div className={styles.item}>
        <div className={styles.data}>
          <Checkbox
            className={styles.select}
            value={selected}
            onChange={handleSelect}
          />
          <Link to={`/admin/tags/${tag.id}`} className={styles.col}>
            {tag?.name || "Brak nazwy tagu"}
          </Link>
          <div className={styles.col}>
            <button
              className={styles.color}
              style={{ backgroundColor: tag.colour || "#ffffff" }}
              onClick={() => {
                navigator.clipboard.writeText(tag.colour || "#ffffff");
              }}
            ></button>
          </div>
          <div className={styles.col}>{tag?.tags_count || "Brak danych"}</div>
          <Link to={`/admin/users/${tag.author}`} className={styles.col}>
            {tag?.author_displayname || "Brak nazwy"}
          </Link>
        </div>
        {/* <button className={styles.settings} onClick={showSettings}>
          <Icon name="more-horizontal" size={24} />
        </button> */}
        <Actions
          className={styles.menu}
          classActionsHead={styles.settings}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
        />
      </div>
    )
  );
};
export default Item;
