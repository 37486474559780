import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Profile.module.sass";
import Icon from "../../../components/Icon";
import axios from "axios";
import ProfileAvatar from "../../../components/ProfileAvatar";

const socials = [
  /*{
  title: "twitter",
  url: "https://twitter.com/ui8",
},
{
  title: "instagram",
  url: "https://www.instagram.com/ui8net/",
},
{
  title: "pinterest",
  url: "https://www.pinterest.com/ui8m/",
},*/
];

const Profile = ({
  name,
  image,
  userId,
  level,
  currentUserId,
  isFollowed,
  triggerFollow,
  isAuthor,
}) => {
  return (
    <div className={styles.profile}>
      <div className={styles.details}>
        <ProfileAvatar image={image} level={level} />
        {/* <div className={styles.avatar}>
          <img
            src={image ? image : "/images/default-avatar.png"}
            alt="Avatar"
          />
          <div className={styles.level}>{level}</div>
          {false && (
            <button className={styles.add}>
              <Icon name="add" />
            </button>
          )}
        </div> */}
        <div className={styles.wrap}>
          <div className={cn("h4", styles.man)}>
            <span className={styles.display_name}>{name}</span>
            {isAuthor && (
              <div className={cn("status-yellow", styles.status)}>
                <Icon name="check" size={20} />
              </div>
            )}
          </div>
        </div>
        {currentUserId !== userId && currentUserId !== null && (
          <button
            className={cn(
              "button" + (isFollowed && "-stroke"),
              styles.button,
              isFollowed ? styles.unfollow : styles.follow
            )}
            onClick={() => triggerFollow(userId, isFollowed)}
          >
            {!isFollowed ? "Obserwuj" : "Obserwujesz"}
          </button>
        )}
      </div>
      <div className={styles.contacts}>
        <div className={styles.socials}>
          {socials.map((x, index) => (
            <a
              className={styles.social}
              href={x.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <Icon name={x.title} size="24" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;
