import React, { useState, useEffect } from "react";
import styles from "./Courses.module.sass";
import axios from "axios";
import { Link } from "react-router-dom";
import cn from "classnames";
import MiniCourse from "components/MiniCourse";

import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import devscntrNoauth from "api/Instance/devscntrNoauth";
import Loader from "components/MiniCourse/Loader";

const sortOptions = [
  {
    option: "Najnowsze",
    value: "latest",
  },
  {
    option: "Oceny (od najlepszych)",
    value: "ratingDesc",
  },
  {
    option: "Cena (rosnąco)",
    value: "priceAsc",
  },
  {
    option: "Cena (malejąco)",
    value: "priceDesc",
  },
];
const ratingOptions = [
  "od 1 gwiazdki",
  "od 2 gwiazdek",
  "od 3 gwiazdek",
  "od 4 gwiazdek",
  "od 5 gwiazdek",
];
const difficultyFilterOptions = [
  "Początkujący",
  "Średnio zaawansowany",
  "Zaawansowany",
];
const formatOptions = ["artykuł", "wideo", "online", "stacjonarnie"];
const languageOptions = ["polski", "angielski"];

const Courses = ({ author }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  // LOADER
  const [isLoading, setIsLoading] = useState(true);

  // CATEGORIES
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  // PAGINATION
  const [currentPage, setCurentPage] = useState(1);

  // ARTICLES
  const [articles, setArticles] = useState([]);

  // SORTING OPTIONS
  const sortOptionsNames = [...sortOptions.map((option) => option.option)];
  const [sortBy, setSortBy] = useState(sortOptionsNames[0]);

  // FILTERS
  const [rating, setRating] = useState(ratingOptions[0]);
  const [languageFilter, setLanguageFilter] = useState(languageOptions[0]);
  const [difficulty, setDifficulty] = useState(difficultyFilterOptions[0]);
  const [format, setFormat] = useState(formatOptions[0]);
  const [priceFilters, setPriceFilters] = useState({
    showFree: false,
    showPaid: false,
    minPrice: 0,
    maxPrice: 100,
    priceRange: [23, 87],
  });

  const getCourses = async (cancelToken) => {
    try {
      const response = await axiosInstance.request({
        method: "GET",
        url: "/course/user-course/",
        headers: {
          "USER-ID": author,
          METHOD: "list",
        },
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      setArticles(response.data);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("user courses canceled");
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCourses(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader cards={3} />
      ) : articles?.length > 0 ? (
        <div className={styles.article}>
          {/* {loading ? <div className={styles.list}>{articles}</div> : "Loading"} */}
          <div className={styles.list}>
            {articles.map((item, index) => (
              <MiniCourse key={index} article={item} />
            ))}
          </div>
        </div>
      ) : (
        <p className={styles.info}>Użytkownik nie ma jeszcze żadnych kursów</p>
      )}
    </>
  );
};

export default Courses;
