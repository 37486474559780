import React, { useEffect, useState, useRef, useContext } from "react";
import styles from "./NewCourse.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Modal from "../../components/Modal";
//import Schedule from "../../components/Schedule";
//import NameAndDescription from "./NameAndDescription";
//import Image from "./Image";
//import CategoryAndAttibutes from "./CategoryAndAttibutes";
//import Preview from "./Preview";
import Icon from "../../components/Icon";
import Panel from "screens/NewLayouts/Panel";
import { EditorState, convertToRaw } from "draft-js";
import axios from "axios";
import cn from "classnames";
import Card from "../../components/Card";
import { useHistory } from "react-router-dom";
import Details from "./Details";
//import Tags from "./Tags";
import api from "../../api/devscntr";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import AuthContext from "../../context/AuthContext";
import PhotoUploader from "../../components/PhotoUploader";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import {
  courseDifficulty,
  courseFormat,
  courseLanguage,
} from "../../data/dataArrays";
import TagsInput from "screens/NewLayouts/TagsInput";
import ImageInput from "screens/NewLayouts/ImageInput";
import Content from "screens/NewLayouts/Content";
import { showError } from "utils/showError";
import { validateInput, validateUrl } from "utils/validation";
import useAuth from "hooks/useAuth";
import ModalSchedule from "components/ModalSchedule";
import LocationsInput from "screens/NewLayouts/LocationsInput";

const DUMMY_TAGS = [{ name: "jak zacząć", count: 20 }];

const setUpFormData = (data) => {
  let newFormData = new FormData();
  for (let key in data) {
    if (key == "image") newFormData.append(key, data[key], data[key].name);
    else newFormData.append(key, data[key]);
  }
  return newFormData;
};

const NewCourse = () => {
  const authCtx = useAuth();
  //const [errorMsg, setErrorMsg] = useState("");

  const [imgSuccessMsg, setImgSuccessMsg] = useState("");
  //const [imgErrorMsg, setImgErrorMsg] = useState("");

  const imageInputRef = useRef();
  const location = useHistory();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleSchedule, setVisibleSchedule] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [isPostCreated, setIsPostCreated] = useState(false);

  const [title, setTitle] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [detailsData, setDetailsData] = useState({
    duration: "",
    certificate: false,
    courseFormat: "Wybierz formę kursu",
    courseLocation: {
      city: "",
      code: "",
      street: "",
      address: "",
    },
    language: "PL",
    reflink: "",
    coursePrice: "",
    courseSalePrice: "",
    courseDifficulty: "Wybierz poziom kursu",
  });
  const [files, setFiles] = useState(null);
  const [tags, setTags] = useState([]);
  const [locations, setLocations] = useState([]);

  const prepareTagList = (tags) => {
    return tags.map((tag) => tag).join(",");
  };

  const preparePrice = (price) => {
    return price.replace(",", ".");
  };

  const validation = (data) => {
    console.log(data);
    // TITLE
    if (!validateInput(title.trim(), 100, 10, "Tytuł")) return false;

    // DESCRIPTION
    if (
      detailsData.reflink === "" &&
      JSON.stringify(convertToRaw(editorState.getCurrentContent())).length < 133
    ) {
      showError("Opis nie może być pusty");
      return false;
    }

    // TAGS
    if (tags.length < 1) {
      showError("Musisz dodać co najmniej jeden tag");
      return false;
    }

    // DURATION
    // if (data.duration.length < 1) {
    //   setErrorMsg("Czas trwania jest wymagany");
    //   return false;
    // }
    if (!validateInput(data?.duration?.trim(), 5, 1, "czas trwania", 3))
      return false;

    // DROPDOWNS
    if (!courseFormat.includes(data.courseFormat)) {
      showError("Wybierz formę kursu");
      return false;
    }
    if (!courseDifficulty.includes(data.courseDifficulty)) {
      showError("Wybierz poziom kursu");
      return false;
    }
    if (!courseLanguage.includes(data.language)) {
      showError("Wybierz język kursu");
      return false;
    }

    // LOCALIZATION
    // if (courseFormat.indexOf(detailsData.courseFormat) + 1 === 4) {
    //   let isLocation = true;
    //   if (!detailsData.courseLocation.city.length > 0) isLocation = false;
    //   if (!detailsData.courseLocation.code.length > 0) isLocation = false;
    //   if (!detailsData.courseLocation.street.length > 0) isLocation = false;
    //   if (!detailsData.courseLocation.address.length > 0) isLocation = false;

    //   !isLocation && showError("Podaj lokalizację");
    //   return isLocation;
    // }

    // VALIDATE REFLINK
    if (data.reflink !== "") {
      if (!validateUrl(data.reflink.trim())) return false;
    }
    // if (!isValidUrl(data.reflink) && data.reflink.length > 0) {
    //   setErrorMsg("Podany link nie jest poprawny");
    //   return false;
    // }

    return true;
  };

  const prepareFormData = () => {
    const formData = new FormData();

    const stringTags = prepareTagList(tags);
    const preparedPrice = preparePrice(detailsData.coursePrice);
    const preparedSalePrice = preparePrice(detailsData.courseSalePrice);

    // BASIC DATA
    formData.append("title", title.trim());
    formData.append("price", preparedPrice.trim());
    formData.append("specialprice", preparedSalePrice.trim());
    formData.append("duration", detailsData.duration.trim());
    formData.append(
      "format",
      courseFormat.indexOf(detailsData.courseFormat) + 1
    );
    formData.append(
      "difficulty",
      courseDifficulty.indexOf(detailsData.courseDifficulty) + 1
    );
    formData.append(
      "language",
      courseLanguage.indexOf(detailsData.language) + 1
    );
    formData.append("certificate", detailsData.certificate);
    formData.append("reflink", detailsData.reflink);
    formData.append("tags", stringTags);
    formData.append(
      "content",
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );

    // LOCATIONS
    if (locations.length > 0) {
      locations.forEach((item, index) => {
        formData.append(`localization[${index}]city`, item.city);
        formData.append(`localization[${index}]code`, item.code);
        formData.append(`localization[${index}]street`, item.street);
        formData.append(`localization[${index}]adres`, item.number);
      });
    }

    // IMAGE
    if (files) {
      formData.append("image", files);
    }

    return formData;
  };

  const addCourseApi = async (data) => {
    //console.log(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
    try {
      const response = await devscntrAuth.post(
        "/course/course-actions/",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            method: "create",
          },
        }
      );
      console.log(response);
      setIsPostCreated(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleTitleChange = (event) => {
    const val = event.target.value;
    setTitle(val);
  };

  const handleSubmit = () => {
    const stringTags = prepareTagList(tags);
    const preparedPrice = preparePrice(detailsData.coursePrice);
    const preparedSalePrice = preparePrice(detailsData.courseSalePrice);

    if (validation(detailsData)) {
      let dataToSend = {
        title: title,
        price: preparedPrice,
        specialprice: preparedSalePrice,
        duration: detailsData.duration,
        format: courseFormat.indexOf(detailsData.courseFormat) + 1,
        "localization[0]city": detailsData.courseLocation.city,
        "localization[0]code": detailsData.courseLocation.code,
        "localization[0]street": detailsData.courseLocation.street,
        "localization[0]adres": detailsData.courseLocation.address,
        difficulty: courseDifficulty.indexOf(detailsData.courseDifficulty) + 1,
        language: courseLanguage.indexOf(detailsData.language) + 1,
        content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
        certificate: detailsData.certificate,
        reflink: detailsData.reflink,
        tags: stringTags,
      };

      if (files) {
        dataToSend = { ...dataToSend, image: files };
      }
      //console.log(dataToSend);
      const formData = prepareFormData();
      console.log(formData);
      addCourseApi(formData);
    } else {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    console.log("file change");
    console.log(files);
  }, [files]);

  return (
    <>
      <div className={styles.row}>
        {!isPostCreated ? (
          <>
            <div className={styles.col}>
              <div className={styles.course_info}>
                {/* {errorMsg.length > 0 && (
                  <div className={styles.error}>
                    <Icon name="info" size="24" />
                    {errorMsg}
                  </div>
                )} */}
                {/* <NameAndDescription
                  className={styles.card}
                  onTitleChange={handleTitleChange}
                  onDescriptionChange={handleEditorStateChange}
                  titleValue={title}
                  descriptionValue={editorState}
                /> */}
                <Content
                  className={styles.card}
                  onTitleChange={handleTitleChange}
                  onDescriptionChange={handleEditorStateChange}
                  title={title}
                  description={editorState}
                />
                <Details data={detailsData} setData={setDetailsData} />
                {detailsData.courseFormat === "stacjonarny" && (
                  <LocationsInput
                    locations={locations}
                    setLocations={setLocations}
                  />
                )}
                {/* <Image
                  className={styles.card}
                  inputRef={imageInputRef}
                  onChange={handleFileChange}
                  successMsg={imgSuccessMsg}
                  errorMsg={imgErrorMsg}
                />{" "} */}
                <ImageInput
                  className={styles.card}
                  title={"Miniaturka kursu"}
                  setFile={setFiles}
                  fileName={`course_img_u${authCtx.userID}`}
                />
                {/* <Tags tags={tags} setTags={setTags} maxTagNumber={12} /> */}
                <TagsInput tags={tags} setTags={setTags} maxTagNumber={12} />
                {/* <CategoryAndAttibutes className={styles.card} /> */}
              </div>
            </div>
            {/* <div className={styles.col}>
              <Preview
                visible={visiblePreview}
                onClose={() => setVisiblePreview(false)}
                setVisible={setVisiblePreview}
                image={image}
                title={title}
                styles={{ pointerEvents: "none" }}
              />
            </div> */}
          </>
        ) : (
          <Card className={styles.postcreated}>
            <p>Kurs został utworzony!</p>
            <div className={styles.panel}>
              <button
                className={cn("button", styles.homebtn)}
                onClick={() => location.push("/courses")}
              >
                Zobacz listę kursów
              </button>
              <button
                className={cn("button-stroke", styles.newbtn)}
                onClick={window.location.reload}
              >
                Utwórz kolejny kurs
              </button>
            </div>
          </Card>
        )}
      </div>
      {!isPostCreated && (
        <Panel
          setVisiblePreview={setVisiblePreview}
          setVisibleSchedule={setVisibleSchedule}
          onSubmit={handleSubmit}
        />
      )}
      <TooltipGlodal />
      <ModalSchedule
        visible={visibleSchedule}
        setVisible={setVisibleSchedule}
        startDate={startDate}
        setStartDate={setStartDate}
        startTime={startTime}
        setStartTime={setStartTime}
      />
    </>
  );
};

export default NewCourse;
