import React, {useState} from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "../Item.module.sass";
import Icon from "../../../../Icon";
import useAxiosFunction from "../../../../../hooks/useAxiosFunction";
import axios from "axios";
import api from "../../../../../api/devscntr";
import devscntrAuth from "../../../../../api/Instance/devscntrAuth";

const ItemPost = ({ className, item, onClose }) => {
  console.log(item);

  const [isRead, setIsRead] = useState(false )

  const date = new Date(item.created_at);

  const setAsRead = () => {
    devscntrAuth.delete(`/accounts/me/notification/`, {
      headers: {
        type: "post",
        id: item.id,
      },
    });
    setIsRead(true )
  };


  return (
      <div
          className={cn(styles.item,{ [styles.new]: !isRead }, className)}
      >
        <div
            className={cn(styles.isread, { [styles.new]: !isRead })}
            onClick={setAsRead}
        ></div>
        <div className={styles.avatar}>
          <img
              src={
            item.user_image
                ? item.user_image
                : "/images/default-avatar.png"
          }
              alt="Avatar"
          />
          <div className={styles.icon}>
            <Icon name="list" />
          </div>
        </div>
        <Link
            className={styles.details}
            to={"/post/" + item.id}
            onClick={()=> {
              setAsRead()
              onClose()
            }}
        >
          <div className={styles.line}>
            <div className={styles.subtitle}>
              {item.title ? item.title.length > 30 ? item.title.substring(0, 27)+"..." : item.title : ""}
            </div>
            <div className={styles.time}>{date.toLocaleString()}</div>
          </div>
          <div className={styles.content}>
            {item.user_displayname + " dodał nowy post!"}
          </div>
        </Link>
      </div>
  );
};

export default ItemPost;
