import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

import api from "../../../../../api/devscntr";
import useAxiosFunction from "../../../../../hooks/useAxiosFunction";
import user from "../../../../../components/Header/User";
import Icon from "../../../../../components/Icon";
import { Link } from "react-router-dom";

import devscntrAuth from "../../../../../api/Instance/devscntrAuth";

const Item = ({ className, item, type, isAllRead }) => {
  const [visible, setVisible] = useState(false);
  // Controlled input's value in reply
  const [currentValue, setCurrentValue] = useState("");
  const [isRead, setIsRead] = useState(false)

  const date = new Date(item.created_at);

  const setAsRead = () => {
    if(!isRead && !isAllRead)
    devscntrAuth.delete(`/accounts/me/notification/`, {
      headers: {
        type: "post",
        id: item.id,
      },
    });
    setIsRead(true)
  };

  return (
    <div
      className={cn(styles.item,{ [styles.new]: !isRead && !isAllRead }, className)}
    >
      <div
          className={cn(styles.isread, { [styles.new]: !isRead && !isAllRead })}
          onClick={setAsRead}
      >
        {
          !isRead && !isAllRead
            ? "Nowe"
            : "Przeczytano"
        }
      </div>
      <div className={styles.avatar}>
        {type == "post" && (
          <>
            <img
              src={
                item.user_image
                  ? item.user_image
                  : "/images/default-avatar.png"
              }
              alt="Avatar"
            />
            <div className={styles.icon}>
              <Icon name="list" />
            </div>
          </>
        )}
      </div>
      <Link
          className={styles.details}
          to={"/post/" + item.id}
          onClick={setAsRead}
      >
        <div className={styles.line}>
          <div className={styles.subtitle}>{item.title}</div>
          <div className={styles.time}>{date.toLocaleString()}</div>
        </div>
        <div className={styles.content}>
          {item.user_displayname + " dodał nowy post!"}
        </div>
      </Link>
    </div>
  );
};

export default Item;
 