import React, { useContext, useState } from "react";
import axios from "axios";
import cn from "classnames";
import styles from "./Login.module.sass";
import Item from "../Item";
import TextInput from "../../../components/TextInput";
import Icon from "../../../components/Icon";
import AuthContext from "../../../context/AuthContext";
import useAuth from "../../../hooks/useAuth";
import devscntrAuth from "../../../api/Instance/devscntrAuth";

const CHANGE_PASSWORD_URL = "/accounts/password";

const Login = ({ className, showMessage }) => {
  const authCtx = useAuth();

  //const [errMsg, setErrMsg] = useState("");
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    newPassword2: "",
  });

  const verifyPassword = (passwd, passwd2) => {
    return passwd === passwd2 ? true : false;
  };

  const changePasswordAPI = async () => {
    try {
      // Send data
      const response = await devscntrAuth.post(
        CHANGE_PASSWORD_URL,
        JSON.stringify({
          old_password: passwordData.oldPassword,
          new_password: passwordData.newPassword,
        })
      );
      console.log("Done");
      console.log(`new password: ${passwordData.newPassword}`);

      // Clear inputs
      setPasswordData({
        oldPassword: "",
        newPassword: "",
        newPassword2: "",
      });

      showMessage("success", "Hasło zmieniono pomyślnie!");
    } catch (error) {
      if (!error?.response) {
        showMessage("error", "Brak odpowiedzi serwera");
      } else if (error.response?.status === 400) {
        error?.response?.data[0]?.key === "old_password"
          ? showMessage("error", "Stare hasło jest niepoprawne")
          : showMessage("error", "Nowe hasło jest niepoprawne");
      } else if (error.response?.status === 401) {
        showMessage("error", "Błędne hasło");
      } else {
        showMessage("error", "Wystąpił błąd serwera");
      }
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setPasswordData((prev) => ({
      ...prev,
      [name]: value,
    }));
    //console.log(passwordData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submit");
    console.log(passwordData);
    if (verifyPassword(passwordData.newPassword, passwordData.newPassword2)) {
      changePasswordAPI();
    } else {
      showMessage("error", "Podane hasła nie są identyczne");
    }
  };

  return (
    <Item
      className={cn(styles.card, className)}
      title="Konto"
      classTitle="title-purple"
    >
      {/* {errMsg.length > 0 && (
        <div className={styles.error}>
          <Icon name="info" size="24" />
          {errMsg}
        </div>
      )} */}
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Aktualne hasło"
          name="oldPassword"
          type="password"
          tooltip="Wprowadź swoje hasło"
          value={passwordData.oldPassword}
          onChange={handleChange}
          required
        />
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="Nowe hasło"
            name="newPassword"
            type="password"
            tooltip="Wprowadź hasło (dobre hasło zawiera małe, duże litery, cyfry i znaki np. !@#$%^&*.)"
            value={passwordData.newPassword}
            onChange={handleChange}
            required
          />
          <TextInput
            className={styles.field}
            label="Potwierdź nowe hasło"
            name="newPassword2"
            type="password"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            value={passwordData.newPassword2}
            onChange={handleChange}
            required
          />
        </div>
        <button className={cn("button", styles.button)} onClick={handleSubmit}>
          Zmień hasło
        </button>
      </div>
    </Item>
  );
};

export default Login;
