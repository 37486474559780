import React, { useState } from "react";
import styles from "./Company.module.sass";
import cn from "classnames";
import TextInput from "components/TextInput";
import PhoneNumberInput from "components/PhoneNumberInput";
import Dropdown from "components/Dropdown";
import { companySize } from "data/dataArrays";
import ThumbnailEditor from "components/ThumbnailEditor";
import { validateInput, validateUrl } from "utils/validation";
import { validationRules } from "data/validationRules";
import devscntrAuth from "api/Instance/devscntrAuth";
import { adminEndpoints } from "api/endpoints";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { showSuccess } from "utils/showSuccess";
import LocationsInput from "screens/NewLayouts/LocationsInput";
import Editor from "components/Editor";

const Company = ({ user, refresh }) => {
  // FIELDS--------------------------------------------------------------------------FIELDS

  const [companyName, setCompanyName] = useState(user?.company?.name || "");
  const [size, setSize] = useState(user?.company?.size || "1");
  const [websiteUrl, setWebsiteUrl] = useState(user?.company?.website || "");
  const [taxNumber, setTaxNumber] = useState(user?.company?.NIP || "");
  const [email, setEmail] = useState(user?.company?.mail || "");
  const [phone, setPhone] = useState(user?.company?.phone || "");
  //const [locations, setLocations] = useState(user?.company?.locations || []);
  const [locations, setLocations] = useState(
    user?.company?.locations.map((item) => ({
      city: item.city || "",
      code: item.postcode || "",
      street: item.street || "",
      number: item.house_number || "",
    }))
  );
  const [file, setFile] = useState(null);

  const [editorState, setEditorState] = useState(
    user?.company?.description
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(user?.company?.description))
        )
      : EditorState.createEmpty()
  );
  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  // FIELDS--------------------------------------------------------------------------FIELDS
  // VALIDATION------------------------------------------------------------------VALIDATION

  const validation = () => {
    // COMPANY NAME
    if (
      !validateInput(
        companyName.trim(),
        validationRules.companyName.maxChars,
        validationRules.companyName.minChars,
        validationRules.companyName.name,
        validationRules.companyName.security
      )
    ) {
      return false;
    }

    // WEBSITE URL
    if (!validateUrl(websiteUrl.trim())) {
      return false;
    }

    // TAX NUMBER
    if (
      !validateInput(
        taxNumber.trim(),
        validationRules.taxNumber.maxChars,
        validationRules.taxNumber.minChars,
        validationRules.taxNumber.name,
        validationRules.taxNumber.security
      )
    ) {
      return false;
    }

    // EMAIL
    if (
      !validateInput(
        email.trim(),
        validationRules.email.maxChars,
        validationRules.email.minChars,
        validationRules.email.name,
        validationRules.email.security
      )
    ) {
      return false;
    }

    return true;
  };

  // VALIDATION------------------------------------------------------------------VALIDATION
  // PREPARE DATA--------------------------------------------------------------PREPARE DATA

  const prepareData = () => {
    const formData = new FormData();

    // BASIC DATA
    formData.append("name", companyName.trim());
    formData.append("phone", phone.trim());
    formData.append("mail", email.trim());
    formData.append("website", websiteUrl.trim());
    formData.append("NIP", taxNumber.trim());
    formData.append("size", size);
    formData.append(
      "description",
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );

    // LOCATIONS
    if (locations.length > 0) {
      locations.forEach((item, index) => {
        formData.append(`locations[${index}]city`, item.city);
        formData.append(`locations[${index}]postcode`, item.code);
        formData.append(`locations[${index}]street`, item.street);
        formData.append(`locations[${index}]house_number`, item.number);
      });
    }

    // IMAGE
    if (file) {
      formData.append("logo", file);
    }

    return formData;
  };

  // PREPARE DATA--------------------------------------------------------------PREPARE DATA
  // UPDATE API CALL--------------------------------------------------------UPDATE API CALL

  const updateProfile = async (data) => {
    try {
      const response = await devscntrAuth.put(
        `${adminEndpoints.company.update}${user.company.id}/`,
        data,
        {
          headers: {
            method: "update",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      showSuccess("Zmiany zapisane pomyślnie");
      refresh && refresh();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    if (validation()) {
      const preparedData = prepareData();
      updateProfile(preparedData);
    }
  };

  // UPDATE API CALL--------------------------------------------------------UPDATE API CALL

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={cn("title-blue", styles.section_header)}>Firma</div>
        <ThumbnailEditor
          className={styles.image_editor}
          id={user?.company?.id}
          img={user?.company?.logo}
          setFile={setFile}
        />
        <div className={styles.fields}>
          <TextInput
            className={styles.field}
            label="Nazwa firmy"
            name="companyName"
            type="text"
            tooltip="Wyświetlana nazwa użytkownika"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <Dropdown
            label="Liczba pracowników"
            value={size}
            setValue={setSize}
            options={companySize}
            classDropdownHead="Liczba pracowników"
          />
          <TextInput
            className={styles.field}
            label="Numer NIP"
            name="taxNumber"
            type="text"
            value={taxNumber}
            onChange={(e) => setTaxNumber(e.target.value)}
          />
          <TextInput
            className={styles.field}
            label="Strona internetowa (adres URL)"
            name="websiteUrl"
            type="text"
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
          />
          <TextInput
            className={styles.field}
            label="Email"
            name="email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <PhoneNumberInput
            className={styles.field}
            label="Numer telefonu"
            name="phone"
            value={phone}
            onChange={setPhone}
          />
        </div>
        <Editor
          editorState={editorState}
          onEditorChange={handleEditorStateChange}
          classEditor={styles.editor}
          label="Opis firmy"
        />
        <LocationsInput
          className={styles.locations}
          locations={locations}
          setLocations={setLocations}
        />
        <button className="button" onClick={handleSubmit}>
          Zapisz zmiany
        </button>
      </div>
    </div>
  );
};

export default Company;
