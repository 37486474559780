import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../components/Icon";
import Overview from "./Overview";
import Comments from "./Comments";
import { useParams, useLocation } from "react-router-dom";
import ModalPin from "../../../components/ModalPin";
import axios from "axios";

import useAuth from "../../../hooks/useAuth";
import devscntrAuth from "../../../api/Instance/devscntrAuth";
import { showLoginModal } from "utils/showLoginModal";

const Details = ({
  className,
  setValue,
  activeIndex,
  setActiveIndex,
  article,
  author,
}) => {
  const authCtx = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [pinState, setPinState] = useState(article.pinned);
  const [currentFollows, setCurrentFollows] = useState([]);
  const [isFollowed, setIsFollowed] = useState(false);

  useEffect(() => {
    //getMyFollows();
    //setPinState(article.pinned);
    //if (currentFollows) setIsFollowed(currentFollows.includes(article.author));
    if (!currentFollows) {
      //getMyFollows();
      //setIsLoading(true);
      //fetchFollows(authCtx.userId);
    }
    console.log(`pinState: ${pinState}`);
    console.log(`isFollowed: ${isFollowed}`);
    console.log(article);
    console.log(author);
  }, [pinState, authCtx, currentFollows, isFollowed]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (authCtx.isLoggedIn) {
      fetchFollows(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const fetchFollows = (cancelToken) => {
    devscntrAuth
      .get("/accounts/me/follow-user/", { cancelToken: cancelToken.token })
      .then(function (response) {
        let followings = response.data.map((item) => item.id);
        setCurrentFollows(followings);
        setIsFollowed(followings.includes(article.author));
        setIsLoading(false);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log("account follows canceled");
        } else {
          console.log(error);
        }
      });
  };

  const handleFollow = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    setIsFollowed((prev) => !prev);
    followUserApi();
  };

  const followUserApi = () => {
    const headers = {
      METHOD: isFollowed ? "unfollow" : "follow",
    };
    devscntrAuth
      .post(
        "/accounts/me/follow-user/",
        { id: article.author },
        { headers: { ...headers } }
      )
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  // -----------------------------------------------------
  // <----- !!!! PRZYPINKI !!!! ----->
  // -----------------------------------------------------
  const location = useLocation();
  const [StarModalVisible, setStarModalVisible] = useState();
  const { name } = useParams();
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();
  const pinsPath = location.pathname.slice(0, 5);

  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    setStarModalVisible(true);
  };

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    if (pinsPath === "/pins") {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };

  const handleClick = (index) => {
    setActiveIndex(index);
    index === 0 && setValue(false);
    index === 1 && setValue(true);
  };

  return (
    <div className={cn(styles.details, className)}>
      {isLoading ? (
        <p className={cn("loading")}>
          <Icon name="repeat" size="24" /> Loading...
        </p>
      ) : (
        <>
          <div className={styles.head}>
            {(StarModalVisible || redirectedUser || unpinPost) && (
              <ModalPin
                visible={StarModalVisible}
                onClose={() => setStarModalVisible(false)}
                article={article}
                setPinState={setPinState}
                redirectedUser={redirectedUser}
                setRedirectedUser={setRedirectedUser}
                unpinPost={unpinPost}
                setunpinPost={setunpinPost}
                pinUrl={"/course/user-course/"}
                idName={"course_id"}
              />
            )}

            <div className={styles.btns}>
              <button
                className={cn(styles.favorite)}
                onClick={pinState ? unpinHandler : pinHandler}
              >
                <img
                  src={`/images/Icons/heart/${
                    pinState ? "filled-color.svg" : "stroke.svg"
                  }`}
                  alt="favourite"
                />
              </button>
              {authCtx.userID !== article.author && (
                <button
                  className={cn(
                    "button" + (isFollowed && "-stroke"),
                    styles.follow
                  )}
                  onClick={handleFollow}
                >
                  {!isFollowed ? (
                    <span className={styles.inner}>Obserwuj</span>
                  ) : (
                    <span className={styles.inner}>Obserwujesz</span>
                  )}
                </button>
              )}
            </div>
          </div>
          <Overview article={article} author={author} />
          <Comments article={article} />
        </>
      )}
    </div>
  );
};

export default Details;
