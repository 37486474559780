import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../components/Icon";
import Overview from "./Overview";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import Modal from "../../../components/Modal";
import useAuth from "../../../hooks/useAuth";
import devscntrAuth from "../../../api/Instance/devscntrAuth";

import { showJobsMap } from "store/jobsMap/actions";
import { store } from "store/rootReducer";

const Details = ({
  className,
  setValue,
  activeIndex,
  setActiveIndex,
  article,
  author,
}) => {
  const authCtx = useAuth();
  const showMap = () => {
    store.dispatch(showJobsMap());
  };

  const { name } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState("");
  const [isMounted, setIsMounted] = useState(false);

  const handleClick = (index) => {
    setActiveIndex(index);
    index === 0 && setValue(false);
    index === 1 && setValue(true);
  };

  // FOLLOWS
  const [currentFollows, setCurrentFollows] = useState([]);
  const [isFollowed, setIsFollowed] = useState(false);

  const fetchFollows = () => {
    devscntrAuth
      .get("/accounts/me/follow-user/")
      .then(function (response) {
        let followings = response.data.map((item) => item.id);
        setCurrentFollows(followings);
        setIsFollowed(followings.includes(article.author_id));
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const followUserApi = () => {
    const headers = {
      METHOD: isFollowed ? "unfollow" : "follow",
    };
    devscntrAuth
      .post(
        "/accounts/me/follow-user/",
        { id: article.author_id },
        { headers: { ...headers } }
      )
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleFollow = () => {
    setIsFollowed((prev) => !prev);
    followUserApi();
  };

  useEffect(() => {
    //getMyFollows();
    if (authCtx.isLoggedIn) {
      fetchFollows();
    }
  }, []);

  // PINS
  const [pinState, setPinState] = useState(article.pinned);

  const pinApi = () => {
    console.log("pinchange");
    console.log(pinState);
  };

  const handlePin = () => {
    setPinState((prev) => !prev);
  };

  useEffect(() => {
    if (isMounted) {
      pinApi();
    }
  }, [pinState]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);

  return (
    <div className={cn(styles.details, className)}>
      {isLoading ? (
        <p className={cn("loading")}>
          <Icon name="repeat" size="24" /> Loading...
        </p>
      ) : (
        <>
          <div className={styles.head}>
            {/* <span className={styles.date}>
              {article?.created_at?.slice(0, 10)}
            </span> */}
            <div className={styles.btns}>
              {/* <button
                className={cn("button-stroke", styles.show_map_btn)}
                onClick={showMap}
              >
                Pokaż mapę
              </button> */}
              <button className={cn(styles.favorite)} onClick={handlePin}>
                <img
                  src={`/images/Icons/heart/${
                    pinState ? "filled-color.svg" : "stroke.svg"
                  }`}
                  alt="favourite"
                />
              </button>
              {authCtx.userID !== article?.author_id && (
                <button
                  className={cn(
                    "button" + (isFollowed && "-stroke"),
                    styles.follow
                  )}
                  onClick={handleFollow}
                >
                  {!isFollowed ? (
                    <span className={styles.inner}>Obserwuj</span>
                  ) : (
                    <span className={styles.inner}>Obserwujesz</span>
                  )}
                </button>
              )}
            </div>
          </div>
          <Overview article={article} author={author} />
          {/* <Comments article={article} /> */}
        </>
      )}
    </div>
  );
};

export default Details;
