import React, { useEffect, useState } from "react";
import styles from "./JobOffer.module.sass";
import cn from "classnames";
import Details from "./Details";
//import Panel from "./Panel";
import { useParams } from "react-router-dom";
import axios from "axios";
import Panel from "screens/DetailsLayout/Panel";
import useAuth from "../../hooks/useAuth";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../api/Instance/devscntrNoauth";
import SkeletonLoader from "screens/DetailsLayout/Loader";
import { jobsEndpoints } from "api/endpoints";

const DUMMY_DATA = {
  company: {
    id: 1,
    image: "/images/logo-light.svg",
    company_name: "DevsCNTR",
    content:
      '{"blocks":[{"key":"auasq","text":"uighuigyuifyufyuj","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    company_size: "12",
    about_company:
      '{"blocks":[{"key":"auasq","text":"uighuigyuifyufyuj","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
  },
  jobOffer: {
    id: 4,
    image: "/images/logo-light.svg",
    pinned: true,
    created_at: "2022-08-05T14:16:36.235655Z",
    title: "Software junior frontend developer React",
    content:
      '{"blocks":[{"key":"auasq","text":"uighuigyuifyufyuj","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    format: "3",
    salary_from: "2200.00",
    salary_to: "2400.00",
    seniority: "5",
    application_link: "https://devscntr.com/",
    recruitment_format: "1",
    technologies: [
      {
        name: "React",
        level: 4,
      },
      {
        name: "VueJS",
        level: 5,
      },
    ],
    localization: [
      {
        id: 4,
        city: "Leszno",
        code: "64-100",
        street: "Testowa",
        number: "12a/2",
      },
      {
        id: 5,
        city: "Góra",
        code: "56-200",
        street: "Policyjna",
        number: "14/1",
      },
      {
        id: 6,
        city: "Wschowa",
        code: "56-256",
        street: "Inna",
        number: "10",
      },
    ],
  },
};

const JobOffer = () => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { id } = useParams();

  const [article, setArticle] = useState();
  const [author, setAuthor] = useState();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getJobOffer(cancelToken);

    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [id, refresh]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    if (article) {
      getUserInfo(cancelToken);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [article]);

  const getJobOffer = async (cancelToken) => {
    try {
      // Request user data
      const response = await axiosInstance.request({
        method: "get",
        url: `${jobsEndpoints.show}${id}`,
        cancelToken: cancelToken.token,
      });

      console.log(response.data);
      setArticle(response.data[0]);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("job detail canceled");
      } else {
        console.log(error);
      }
    }
  };

  const getUserInfo = async (cancelToken) => {
    try {
      // Request user data
      const response = await axiosInstance.request({
        method: "get",
        url: `/accounts/me/user-info/`,
        headers: {
          "USER-ID": article.author_id,
        },
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      setAuthor(response.data.user);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("user info canceled");
      } else {
        console.log(error);
      }
    }
  };

  const handleClose = () => {
    setActiveIndex(0);
    setVisible(false);
  };

  return (
    <div className={cn(styles.product, { [styles.active]: visible })}>
      {author && article ? (
        <>
          <Details
            className={styles.details}
            setValue={setVisible}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            article={article}
            author={author}
          />
          {/* <Panel className={styles.panel} article={article} author={author} /> */}
          <Panel
            className={styles.panel}
            type="job"
            id={article?.id}
            author={article.author}
            author_image={article.author_image}
            refresh={() => setRefresh((prev) => !prev)}
          />
        </>
      ) : (
        <SkeletonLoader />
      )}
    </div>
  );
};

export default JobOffer;
