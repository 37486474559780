import React, { useEffect, useState } from "react";
import styles from "./Map.module.sass";
import cn from "classnames";
import Modal from "components/Modal";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { Link, Redirect, useHistory } from "react-router-dom";
import { currencyList } from "data/dataArrays";

const currencyCode = currencyList[0].code;

const DEFAULT_LATITUDE = 52.376;
const DEFAULT_LANGITUDE = 19.516;

const DUMMY_DATA = [
  {
    id: 43,
    x: 52.3,
    y: 19.2,
  },
  {
    id: 42,
    x: 52.6,
    y: 19.9,
  },
  {
    id: 41,
    x: 52.5,
    y: 19.4,
  },
];

const MapPlaceholder = () => {
  return (
    <p>
      Mapa lokalizacji kursu.{" "}
      <noscript>Aktywuj JavaScript, żeby zobaczyć mapę.</noscript>
    </p>
  );
};

const Map = ({ className }) => {
  const history = useHistory();

  const [locations, setLocations] = useState(DUMMY_DATA);

  useEffect(() => {
    console.log("map render");
  }, []);

  const markerIcon = new L.Icon({
    iconUrl: "/images/icons/map_markers/php.png",
    iconSize: [35, 35],
    iconAnchor: [17, 46],
    popupAnchor: [0, -46],
  });

  const handleMarkerClick = (id) => {
    //history.push(`/jobs/${id}`);
    //handleJobIdChange(id);
    //handleClose();
  };

  return (
    <div className={cn(styles.container, className)}>
      <MapContainer
        center={[DEFAULT_LATITUDE, DEFAULT_LANGITUDE]}
        zoom={7}
        scrollWheelZoom={false}
        placeholder={<MapPlaceholder />}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "10px",
          zIndex: 0,
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations?.map((item, index) => (
          <Marker
            position={[item.x, item.y]}
            eventHandlers={{ click: () => handleMarkerClick(item.id) }}
            key={`course_map_marker_${index}`}
          >
            <Popup>test</Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default Map;
