import React from "react";
import styles from "./ProfileAvatar.module.sass";

const levelRanks = {
  bronze: 0,
  silver: 10,
  gold: 20,
  platinium: 30,
  diamond: 40,
  master: 50,
  grandmaster: 60,
  challenger: 70,
};

const ProfileAvatar = ({ image, level, onClick, small }) => {
  return (
    <div
      className={small ? styles.avatar_small : styles.avatar}
      onClick={onClick}
    >
      <img src={image ? image : "/images/default-avatar.png"} alt="Avatar" />
      <div className={styles.level}>{level}</div>
    </div>
  );
};
export default ProfileAvatar;
