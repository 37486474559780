import React, { useState } from "react";
import styles from "./Admin.module.sass";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Users from "./Users";
import UserModal from "./UserModal";
import Tags from "./Tags";
import Icon from "components/Icon";
import UserDetail from "./Users/UserDetail";
import TagModal from "./TagModal";
import TagDetail from "./Tags/TagDetail";
import Dashboard from "./Dashboard";

//const navigation = ["Użytkownicy", "Tagi", "Treści"];
const navigation = [
  {
    title: "Dashboard",
    icon: "lightning",
    url: "/admin",
  },
  {
    title: "Użytkownicy",
    icon: "profile-circle",
    url: "/admin/users",
  },
  {
    title: "Tagi",
    icon: "ticket",
    url: "/admin/tags",
  },
];

const Admin = ({ children }) => {
  const authCtx = useAuth();

  return authCtx.isAdmin ? (
    <>
      <UserModal />

      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.nav}>
            {navigation.map((item, index) => (
              <Link
                to={item.url}
                className={styles.item}
                //onClick={() => setActiveTag(index)}
                key={`admin_nav_${index}`}
              >
                <Icon name={item.icon} size={20} />
                {item.title}
              </Link>
            ))}
          </div>
          <div className={styles.content}>{children}</div>

          <Switch>
            <Route exact path="/admin">
              <Dashboard />
            </Route>
            <Route exact path="/admin/users">
              <Users />
            </Route>
            <Route exact path="/admin/users/:id">
              <UserDetail />
            </Route>
            <Route exact path="/admin/tags">
              <Tags />
            </Route>
            <Route exact path="/admin/tags/:id">
              <TagDetail />
            </Route>
          </Switch>
          {/* <div className={styles.content}>{activeTab === 0 && <Users />}</div>
          <div className={styles.content}>{activeTab === 1 && <Tags />}</div> */}
        </div>
      </div>
    </>
  ) : (
    // <Redirect to="/home" />
    ""
  );
};
export default Admin;
