import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./JobOffers.module.sass";
import LatestCourses from "components/LatestCourses";
import LatestComments from "components/LatestComments";
import TooltipGlodal from "components/TooltipGlodal";
import MiniJobOffer from "components/MiniJobOffer";
import axios from "axios";
import SkeletonLoader from "components/MiniJobOffer/Loader";
import Sorting from "./Sorting";
import {
  jobSortingOptions,
  jobSeniority,
  jobFormat,
  jobRecruitment,
  pageSize,
} from "data/dataArrays";
import Filtering from "./Filtering";
import cn from "classnames";

import TagFilter from "components/TagFilter";
import { useLocation, useHistory } from "react-router-dom";
import devscntrNoauth from "api/Instance/devscntrNoauth";

import { showJobsMap } from "store/jobsMap/actions";
import { store } from "store/rootReducer";
import devscntrAuth from "api/Instance/devscntrAuth";
import useAuth from "hooks/useAuth";

const postsPerPage = pageSize;

const JobOffers = () => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(false);
  const [mapVisible, setMapVisible] = useState(false);
  const showMap = () => {
    store.dispatch(showJobsMap());
  };

  const search = useLocation().search;
  const history = useHistory();

  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  const [jobsList, setJobsList] = useState([]);
  const [sortMethod, setSortMethod] = useState(jobSortingOptions[0].value);
  const [filters, setFilters] = useState({
    seniority: "Wybierz poziom",
    format: "Wybierz formę pracy",
    recruitmentFormat: "Wybierz formę rekurtacji",
  });

  // PAGINATION
  const [currentPage, setCurentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [refresher, setRefresher] = useState(false);

  const observer = useRef();
  const lastPostRef = useCallback(
    (post) => {
      console.log(isLoading);

      //if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting) {
          console.log("We are near the last post!");
          setCurentPage((prev) => ++prev);
        }
      });
      if (post) observer.current.observe(post);
    },
    [jobsList, isLastPage]
  );

  // GET QUERIES FROM URL
  const getUrlParams = () => {
    let sortBy = new URLSearchParams(search).get("sortby");
    let seniority =
      jobSeniority[new URLSearchParams(search).get("seniority")] ||
      "Wybierz poziom";
    let format =
      jobFormat[new URLSearchParams(search).get("format")] ||
      "Wybierz formę pracy";
    let recruitmentFormat =
      jobRecruitment[new URLSearchParams(search).get("recruitment_format")] ||
      "Wybierz formę rekurtacji";

    setSortMethod(sortBy || jobSortingOptions[0].value);
    setFilters({
      seniority: seniority,
      format: format,
      recruitmentFormat: recruitmentFormat,
    });
  };

  // API
  const getJobs = async (cancelToken) => {
    setIsLoading(true);
    console.log("IsLoading ON");

    const params = `?page=${currentPage}${
      tags.length > 0 ? `&tags=${tags.join("%26")}` : ""
    }&page_size=${postsPerPage}`;
    const applyFilters = getFilterString();

    await axiosInstance
      .request({
        method: "get",
        url: `/jobs/show-job/${params}&sortby=${sortMethod}${applyFilters}`,
        cancelToken: cancelToken.token,
      })
      .then((response) => {
        if (response.data.next == null) setIsLastPage(true);
        else setIsLastPage(false);

        const loadedArticles = response.data.results.map((item, index) => {
          if (response.data.results.length - 1 === index + 1) {
            return (
              <MiniJobOffer
                article={item}
                key={`job_${item.id}`}
                ref={lastPostRef}
              />
            );
          }
          return <MiniJobOffer article={item} key={`job_${item.id}`} />;
        });

        if (jobsList.length === 0) setJobsList(loadedArticles);
        else setJobsList((prevState) => [...prevState, ...loadedArticles]);

        setIsLoading(false);
        console.log("IsLoading OFF");
        console.log(`Załadowano ${currentPage} strone`);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("jobs canceled");
        } else {
          console.log(error);
        }
      });
  };

  console.log("JOB:", jobsList);

  useEffect(() => {
    setIsLoading(true);
    setCurentPage(1);
    setIsLastPage(false);
    setJobsList([]);
    setRefresher((prev) => !prev);
  }, [tags, filters, sortMethod]);

  const compareNumbers = (a, b) => {
    return b.tags_count - a.tags_count;
  };

  const getFilterString = () => {
    let filterString = "";
    filterString = `${
      jobSeniority.includes(filters.seniority)
        ? `&seniority=${jobSeniority.indexOf(filters.seniority)}`
        : ""
    }${
      jobFormat.includes(filters.format)
        ? `&type=${jobFormat.indexOf(filters.format)}`
        : ""
    }${
      jobRecruitment.includes(filters.recruitmentFormat)
        ? `&recrutype=${jobRecruitment.indexOf(filters.recruitmentFormat)}`
        : ""
    }`;

    return filterString;
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    //getCategories(cancelToken);
    getUrlParams();

    return () => {
      cancelToken.cancel();
    };
  }, []);

  useEffect(() => {
    const applyFilters = getFilterString();
    const params = `&sortby=${
      sortMethod !== null ? sortMethod : jobSortingOptions[0].value
    }${applyFilters.length > 0 ? applyFilters : ""}${
      tags.length > 0 ? `&tags=${tags.join("%26")}` : ""
    }`;

    history.push({
      search: params,
    });
    //console.log(filters);
  }, [filters, sortMethod, currentPage, tags]);
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    !isLastPage && getJobs(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [currentPage, search, refresher]);
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {/* <Category
            categories={categories}
            onChangeCategory={handleChangeCategory}
          /> */}
          <TagFilter
            categories={categories}
            selectedTags={tags}
            setSelectedTags={setTags}
          />
          <div className={styles.options}>
            <Sorting
              setSortMethod={setSortMethod}
              style={{ justifySelf: "start" }}
            />
            <div className={styles.box}>
              {/* <button
                className={cn("button-stroke", styles.open_map_btn)}
                style={{ justifySelf: "center" }}
                onClick={showMap}
              >
                Pokaż mapę
              </button> */}
              <Filtering filters={filters} setFilters={setFilters} />
            </div>
          </div>

          {jobsList?.length > 0 ? (
            <div className={styles.items_list} id={"jobs-list"}>
              {jobsList}
              {isLoading && <SkeletonLoader cards={postsPerPage} />}
            </div>
          ) : (
            !isLoading && (
              <div className={styles.info}>
                Nie znaleźliśmy żadnych ofert pracy
              </div>
            )
          )}
          {isLoading && !isLastPage && currentPage < 2 && (
            <SkeletonLoader cards={postsPerPage} />
          )}

          <div className={styles.foot}>
            {!isLastPage && (
              <span
                className={cn("button-stroke button-small", styles.button)}
                style={{ marginLeft: "12px" }}
              >
                {isLoading ? "Ładowanie..." : "Następna"}
              </span>
            )}
          </div>
        </div>
        <div className={styles.col}>
          <LatestCourses className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default JobOffers;
