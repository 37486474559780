import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Search from "./Search";
import Messages from "./Messages";
import Notification from "./Notification";
import User from "./User";
import OutsideClickHandler from "react-outside-click-handler";
import AddButton from "./AddButton";
import useAuth from "../../hooks/useAuth";

const Header = ({ onOpen }) => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    //setVisible(false);
  };

  const authCtx = useAuth();
  const isLoggedIn = authCtx.isLoggedIn;
  const isAuthor = authCtx.isAuthor;

  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>
      <Search className={cn(styles.search, { [styles.visible]: visible })} />
      <button
        className={cn(styles.buttonSearch, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button>

      <div className={styles.control} onClick={() => setVisible(false)}>
        {isAuthor && (
          <div className={styles.adb}>
            <AddButton />
          </div>

          // {/* <Link className={cn("button", styles.button)} to="/new-post">
          //   <Icon name="add" size="24" />
          //   <span>Wpis</span>
          // </Link> */}
        )}

        {isLoggedIn ? (
          <>
            <Notification className={styles.notification} />
            <User className={styles.user} />
          </>
        ) : (
          <Link to="/sign-in" className={cn("button")}>
            Zaloguj się
          </Link>
        )}
      </div>
      {/* <div className={styles.btns}>
        <Link className={styles.link} to="/sign-in">
          Sign in
        </Link>
        <Link className={cn("button", styles.button)} to="/sign-up">
          Sign up
        </Link>
      </div> */}
    </header>
  );
};

export default Header;
