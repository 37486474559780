import React, { useEffect, useState, useCallback, useRef } from "react";
import cn from "classnames";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import axios from "axios";
import LatestComments from "../../components/LatestComments";
import LatestCourses from "../../components/LatestCourses";
import TagFilter from "../../components/TagFilter";

import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import devscntrNoauth from "api/Instance/devscntrNoauth";
import MiniPost from "components/MiniPost";
import { pageSize } from "data/dataArrays";
import Loader from "components/MiniPost/Loader";

const postsPerPage = pageSize;

const Home = () => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  const [isLoading, setIsLoading] = useState(false);

  const [tags, setTags] = React.useState([]);
  const [articles, setArticles] = React.useState([]);

  // PAGINATION
  const [currentPage, setCurentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [refresher, setRefresher] = useState(false);

  const observer = useRef();
  const lastPostRef = useCallback(
    (post) => {
      console.log("lastPostRef");
      //if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting && !isLastPage) {
          console.log("We are near the last post!");
          setCurentPage((prev) => ++prev);
        }
      });

      if (post) observer.current.observe(post);
    },
    [articles, isLoading]
  );

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    !isLastPage && fetchPosts(cancelToken);
    //fetchCategories(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [currentPage, refresher]);

  useEffect(() => {
    setCurentPage(1);
    setIsLastPage(false);
    setArticles([]);
    setRefresher((prev) => !prev);
  }, [tags]);

  const fetchPosts = (cancelToken) => {
    //if (isLoading) return;
    setIsLoading(true);
    console.log("isLoading ON");
    const params =
      "?page=" +
      currentPage +
      (tags.length > 0 ? "&tags=" + tags.join("%26") : "") +
      "&page_size=" +
      postsPerPage;

    axiosInstance
      .request({
        method: "GET",
        url: `/posts/article/show-post${params}`,
        cancelToken: cancelToken.token,
      })
      .then(function (response) {
        if (response.data.next == null) setIsLastPage(true);
        else setIsLastPage(false);

        const loadedArticles = response.data.results.map((item, index) => {
          if (response.data.results.length - 1 === index) {
            return (
              <MiniPost
                key={`post_${item.id}`}
                article={item}
                ref={lastPostRef}
              />
            );
          }
          return <MiniPost key={`post_${item.id}`} article={item} />;
        });

        if (articles.length === 0) setArticles(loadedArticles);
        else setArticles((prevState) => [...prevState, ...loadedArticles]);

        setIsLoading(false);
        console.log("isLoading OFF");
        console.log(`Załadowano ${currentPage} strone`);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log("posts canceled");
        } else {
          console.log(error);
        }
      });
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {/* <Category
            categories={categories}
            onChangeCategory={handleChangeCategory}
          /> */}
          <TagFilter selectedTags={tags} setSelectedTags={setTags} />
          {articles.length > 0 && (
            <div id="article-list">
              {/* <Article className={styles.card} articles={articles} /> */}
              {/* {articles.map((x) => (
                  <MiniPost key={x.id} article={x} />
                ))} */}
              {articles}
              {isLoading && <Loader cards={postsPerPage} />}

              <div className={styles.foot}>
                {!isLastPage && (
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      setCurentPage((prevState) => ++prevState);
                    }}
                  >
                    {isLoading ? "Ładowanie..." : "Następna"}
                  </button>
                )}
              </div>
            </div>
          )}
          {isLoading && !isLastPage && currentPage < 2 && (
            <Loader cards={postsPerPage * currentPage} />
          )}
        </div>
        <div className={styles.col}>
          {/* <PopularProducts className={styles.card} views="4" />
          <Comments className={styles.card} />
          <RefundRequests title="Refund requests" classTitle="title-red" /> */}
          <LatestCourses className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;
