import React from "react";
import style from "./Loader.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Loader({ cards }) {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <SkeletonTheme key={i}>
        <div className={style.skeleton} key={i}>
          <div className={style.content}>
            <div className={style.img}>
              <Skeleton width={80} height={80} borderRadius={8} />
            </div>
            <div className={style.title}>
              <Skeleton count={1} height={16} />
              <Skeleton count={1} height={14} width={130} />
            </div>
          </div>
          <div className={style.rest}>
            <Skeleton count={2} height={16} />
          </div>
        </div>
      </SkeletonTheme>
    ));
};