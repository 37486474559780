import React, { useState } from "react";
import cn from "classnames";
import Card from "../../../components/Card";
import styles from "./SortingAndFiltering.module.sass";
import Scroll from "../../../components/Scroll";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import FilterSettings from "./FilterSettings";

const SortingAndFiltering = (props) => {
  return (
    <>
      {/*<Card
      title="Sortowanie i filtry"
      classTitle="title-yellow"
      className={styles.card}
      oneLine={true}
      head={
        <div className={styles.container}>
          <Dropdown options={sortOptions} value={sortBy} setValue={setSortBy} />
          <Filters title="Filtrowanie kursów">Test</Filters>
        </div>
      }
    ></Card>
    */}
      <div className={styles.container}>
        <div className={styles.col}>
          {/* <h2>Kursy</h2> */}
          <Dropdown
            options={props.sortOptions}
            value={props.sortBy}
            setValue={props.setSortBy}
          />
        </div>
        <div className={styles.col}>
          <Filters className={styles.filters} title="Filtrowanie kursów">
            <FilterSettings {...props} />
          </Filters>
        </div>
      </div>
    </>
  );
};
export default SortingAndFiltering;
