import React from "react";
import MiniPost from "../../../components/MiniPost";
const Article = ({ articles }) => {


  const articleElements = articles.map((x) => (

    <MiniPost key={x.id} article={x} />
  ))

  return (
      <section>
          {articleElements}
      </section>
  );
};
export default Article;