import React, { useState, useEffect } from "react";
import styles from "./SubsCarusel.module.sass";
import { Link } from "react-router-dom";
import api from "../../../api/devscntr";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import PostLoaderSkeleton from "../../../components/PostLoaderSkeleton/userSubsLoader";


const SideSubs = () => {
  const [response, error, loading, axiosFetch] = useAxiosFunction();

  const getData = () => {
    axiosFetch({
      axiosInstance: api,
      method: "get",
      url: "/accounts/me/follow-user/",
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
 
  return (
    <ul className={styles.SubsCarusel}>
      {/* DISPLAY LOADDER */}
      {loading && <PostLoaderSkeleton cards={10} />}

      {/* DISPLAY ERROR */}
      {!loading && error && <p className="errMsg">{error}</p>}

      {/* DISPLAY POSTS*/}
      {!loading &&
        !error &&
        response.length >= 1 &&
        response.map((res) => (
          <li key={res.id}>
            <Link to={`/profile/${res.id}`}>
              <img
                src={res.image ? res.image : "/images/default-avatar.png"}
                alt=""
              />
              <p className={styles.userName}>{res.display_name ? res.display_name : "Brak Nazwy"}</p>
              
            </Link>
          </li>
        ))}

      {/* DISPLAY VALUE NULL */}
      {!loading && !error && response.length === 0 &&  <p>Brak Subskrybcji</p>}
    </ul>
  );
};

export default SideSubs;
