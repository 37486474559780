import React, { useEffect, useContext } from "react";
import styles from "./SidePinGroups.module.sass";
import { NavLink } from "react-router-dom";
import Icon from "../../Icon";
import useAxios from "../../../hooks/useAxios";
import GroupContext from "../../../context/group-context";

const SidePinGroups = (props) => {
  const groupCtx = useContext(GroupContext);

  useEffect(() => {
    getAllGroups();
  }, [groupCtx.groupHandler]);

  const [resOfGroupes, error, loading, GetAllGroups] = useAxios();
  const getAllGroups = () => {
    GetAllGroups({
      method: "get",
      url: "/accounts/me/managegroup/",
    });
  };

  //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@");
  //console.log("SIDE : ", resOfGroupes.length);
  // console.log("ctx : ", groupCtx.groupHandler);
  // console.log("length : ", resultLength);

  return (
    <ul className={styles.sidesubs}>
      {/* DISPLAY POSTS*/}
      {!loading &&
        resOfGroupes.length > 0 &&
        resOfGroupes.map((res) => (
          <li key={res.id}>
            <NavLink
              to={`/pins/${res.name}`}
              key={res.id}
              className={styles.link}
              activeClassName={styles.active}
              onClick={props.onClose}
              exact
            >
              {res.name ? res.name : "Brak Nazwy "}
              <Icon name="arrow-next" size="24" />
            </NavLink>
          </li>
        ))}
      {/* DISPLAY VALUE NULL */}
      {!loading && resOfGroupes.length === 0 && <p>Brak Utworzonych Grup</p>}
    </ul>
  );
};

export default SidePinGroups;
