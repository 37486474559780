import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

import api from "../../../../../api/devscntr";
import useAxiosFunction from "../../../../../hooks/useAxiosFunction";
import user from "../../../../../components/Header/User";
import Icon from "../../../../../components/Icon";
import { Link } from "react-router-dom";
import axios from "axios";
import devscntrAuth from "../../../../../api/Instance/devscntrAuth";

const Item = ({ className, item, isAllRead }) => {
  const [visible, setVisible] = useState(false);
  // Controlled input's value in reply
  const [currentValue, setCurrentValue] = useState("");
  const [isRead, setIsRead] = useState(false);
  const date = new Date(item.created_at);

  const setAsRead = () => {
    if (!isRead && !isAllRead) {
      devscntrAuth
        .delete(`/accounts/me/notification/`, {
          headers: {
            TYPE: "misc",
            ID: item.id,
          },
        })
        .then(() => {
          setIsRead(true);
        });
    }
  };

  return (
    <div className={cn(styles.item, { [styles.new]: !isRead && !isAllRead }, className)}>
      <div
          className={cn(styles.isread, { [styles.new]: !isRead && !isAllRead })}
          onClick={setAsRead}
      >
        {
          !isRead && !isAllRead
              ? "Nowe"
              : "Przeczytano"
        }
      </div>
      <div className={styles.avatar}>
        <img
            src={
              item.foreign_user_image
                  ? item.foreign_user_image
                  : "/images/default-avatar.png"
            }
            alt="Avatar"
        />
        <div className={styles.icon}>
          <Icon name="list" />
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={styles.subtitle}>{item.title}</div>
          <div className={styles.time}>{date.toLocaleString()}</div>
        </div>
        <div className={styles.content}>
          {item.foreign_user_name}
          <p>{item.text}</p>
        </div>
      </div>
    </div>
  );
};

export default Item;
