import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./AddGroupSideButton.module.sass";
import Icon from "../../Icon";
import axios from "axios";
import devscntrAuth from "../../../api/Instance/devscntrAuth";
import useAxios from "../../../hooks/useAxios";
import GroupContext from "../../../context/group-context";

const AddGroupSideButton = ({ className }) => {
  const groupCtx = useContext(GroupContext);

  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [invalid, setInvalid] = useState(false);
  const isEmpty = title.trim() === "";

  const [response, error, loading, FetchAllGroups] = useAxios();

  const postNewGroup = () => {
    FetchAllGroups({
      method: "post",
      url: "/accounts/me/managegroup/",
      requestConfig: {
        name: title,
      },
      customHeaders: {
        METHOD: "create",
      },
    }, );
  };

  const handleSubmit = () => {
    if (!isEmpty) {
      postNewGroup();
      setVisible(false);
      setTitle("");
      groupCtx.setGroupHandler((prevState) => !prevState);
    } else {
      setInvalid(true);
    }
  };

  const handleCancelled = () => {
    setVisible(false);
    setInvalid(false);
    setTitle("");
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    handleSubmit();
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setVisible(false);
        setInvalid(false);
      }}
    >
      <div
        className={cn(styles.notification, className, {
          [styles.active]: visible,
        })}
      >
        <button
          className={cn("add", styles.add, styles.active)}
          onClick={() => setVisible(!visible)}
        >
          <Icon name="plus" size="10" />
        </button>
        <div className={styles.body}>
          <input
            placeholder="Nowa Grupa..."
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className={`${styles.input} ${!invalid ? "" : styles.invalid}`}
            onKeyDown={handleKeyDown}
            maxLength={20}
          ></input>
          <span className={styles.validationCounter}>{title.length}/20</span>
          <div className={styles.groupButtons}>
            <button
              onClick={handleCancelled}
              className={cn("button", styles.button, styles.cancel)}
            >
              Odrzuć
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              onKeyDown={handleKeyDown}
              className={cn("button", styles.button)}
            >
              Dodaj
            </button>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default AddGroupSideButton;
