import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Details.module.sass";
import Card from "components/Card";
import Icon from "components/Icon";
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";

const Details = ({ className, reflink, setReflink }) => {
  const [isReflink, setIsReflink] = useState(false);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Szczegóły"
      classTitle="title-yellow"
      // head={
      //   <Link
      //     className={cn("button-stroke button-small", styles.button)}
      //     to="/"
      //   >
      //     <Icon name="arrow-left" size="24" />
      //     <span>Powrót</span>
      //   </Link>
      // }
    >
      <Checkbox
        className={styles.field}
        content="Link do zewnętrznego wpisu"
        value={isReflink}
        onChange={(e) => {
          setIsReflink(e.target.checked);
          setReflink("");
        }}
      />
      {isReflink && (
        <TextInput
          className={styles.field}
          icon="link"
          label="Link do wpisu"
          placeholder="https://devscntr.com/"
          value={reflink}
          onChange={(e) => setReflink(e.target.value)}
          disabled={!isReflink}
        />
      )}
    </Card>
  );
};

export default Details;
