import React, { useState, useEffect, useCallback, useRef } from "react";
import cn from "classnames";
import styles from "./Courses.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import ModalLogin from "../../components/ModalLogin";
import SortingAndFiltering from "./SortingAndFiltering";
import axios from "axios";
import RecommendedPosts from "../../components/RecommendedPosts";
import LatestComments from "../../components/LatestComments";
import TagFilter from "../../components/TagFilter";

import useAuth from "../../hooks/useAuth";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../api/Instance/devscntrNoauth";
import { courseSortingOptions, pageSize } from "data/dataArrays";
import MiniCourse from "../../components/MiniCourse";
import Loader from "components/MiniCourse/Loader";

const sortOptions = [
  {
    option: "Najnowsze",
    value: "newest",
  },
  {
    option: "Najstarsze",
    value: "oldest",
  },
  {
    option: "Oceny (od najlepszych)",
    value: "",
  },
  {
    option: "Cena (rosnąco)",
    value: "cheapest",
  },
  {
    option: "Cena (malejąco)",
    value: "mostexpensive",
  },
];
const ratingOptions = [
  "od 1 gwiazdki",
  "od 2 gwiazdek",
  "od 3 gwiazdek",
  "od 4 gwiazdek",
  "od 5 gwiazdek",
];
const difficultyFilterOptions = [
  "wszystkie",
  "początkujący",
  "średnio zaawansowany",
  "zaawansowany",
];
const formatOptions = [
  "wszystkie",
  "artykuł",
  "wideo",
  "online",
  "stacjonarnie",
];
const languageOptions = ["wszystkie", "polski", "angielski"];

const postsPerPage = pageSize;

const Courses = () => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(false);

  // CATEGORIES
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  // PAGINATION
  const [currentPage, setCurentPage] = useState(1);
  const [isLastPage, setIsLastPage] = React.useState(false);
  const [refresher, setRefresher] = useState(false);

  const observer = useRef();
  const lastPostRef = useCallback(
    (post) => {
      console.log("lastPostRef");
      //if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((posts) => {
        if (posts[0].isIntersecting) {
          console.log("We are near the last post!");
          setCurentPage((prev) => ++prev);
        }
      });

      if (post) observer.current.observe(post);
    },
    [articles, isLoading]
  );

  // ARTICLES
  const [articles, setArticles] = useState([]);

  // SORTING OPTIONS
  const sortOptionsNames = [
    ...courseSortingOptions.map((option) => option.name),
  ];
  const [sortBy, setSortBy] = useState(sortOptionsNames[0]);

  // FILTERS
  const [filters, setFilters] = useState("");
  const [rating, setRating] = useState(ratingOptions[0]);
  const [languageFilter, setLanguageFilter] = useState(languageOptions[0]);
  const [difficulty, setDifficulty] = useState(difficultyFilterOptions[0]);
  const [format, setFormat] = useState(formatOptions[0]);
  const [priceFilters, setPriceFilters] = useState({
    showFree: false,
    showPaid: false,
    minPrice: 0,
    maxPrice: 100,
    priceRange: [23, 87],
  });

  // "?page=" +
  // currentPage +
  // (tags.length > 0 ? "&tags=" + tags : "") +
  // "&page_size=" +
  // postsPerPage;

  useEffect(() => {
    console.log(difficulty);
  }, [difficulty]);

  const getCourses = async (cancelToken) => {
    //if (isLoading) return;
    setIsLoading(true);

    const params = `?page=${currentPage}${
      tags.length > 0 ? `&tags=${tags.join("%26")}` : ""
    }&page_size=${postsPerPage}`;
    const index = sortOptions.map((x) => x.option).indexOf(sortBy);
    const sortMethod = `&sortby=${sortOptions[index].value}`;

    try {
      //console.log(tags);
      const response = await axiosInstance.request({
        method: "get",
        url: `/course/show-course/${params}${sortMethod}${filters}`,
        cancelToken: cancelToken.token,
      });

      if (response.data.next == null) setIsLastPage(true);
      else setIsLastPage(false);

      const loadedArticles = response?.data?.results.map((x, index) => {
        if (response.data.results.length - 1 === index + 1)
          return <MiniCourse key={x.id} article={x} ref={lastPostRef} />;
        return <MiniCourse key={x.id} article={x} />;
      });

      if (articles.length === 0) setArticles(loadedArticles);
      else setArticles((prevState) => [...prevState, ...loadedArticles]);

      console.log(`Załadowano ${currentPage} strone`);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("courses canceled");
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setCurentPage(1);
    setIsLastPage(false);
    setArticles([]);
    setRefresher((prev) => !prev);
  }, [tags, filters, sortBy]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    !isLastPage && getCourses(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [currentPage, refresher]);

  useEffect(() => {
    handleFiltersSubmit();
    console.log(filters);
  }, [languageFilter, difficulty, format, priceFilters]);

  const handleFiltersSubmit = () => {
    console.log(sortBy);
    console.log(priceFilters);
    setFilters(
      `${
        difficultyFilterOptions[0] !== difficulty
          ? `&difficulty=${difficultyFilterOptions.indexOf(difficulty)}`
          : ""
      }${
        languageOptions[0] !== languageFilter
          ? `&language=${languageOptions.indexOf(languageFilter)}`
          : ""
      }${
        formatOptions[0] !== format
          ? `&type=${formatOptions.indexOf(format)}`
          : ""
      }${priceFilters.showFree ? "&price=free" : ""}`
    );
  };

  const handleFiltersClear = () => {
    setFilters("");
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {/* <Category
            categories={categories}
            onChangeCategory={handleChangeCategory}
          /> */}
          <TagFilter
            categories={categories}
            selectedTags={tags}
            setSelectedTags={setTags}
            //onChangeCategory={handleChangeCategory}
          />
          <SortingAndFiltering
            sortOptions={sortOptionsNames} // SORT
            sortBy={sortBy}
            setSortBy={setSortBy}
            ratingOptions={ratingOptions} // RATING
            rating={rating}
            setRating={setRating}
            languageOptions={languageOptions} // LANGUAGE
            languageFilter={languageFilter}
            setLanguageFilter={setLanguageFilter}
            difficultyOptions={difficultyFilterOptions} // DIFFICULTY
            difficulty={difficulty}
            setDifficulty={setDifficulty}
            formatOptions={formatOptions} // FORMAT
            format={format}
            setFormat={setFormat}
            priceFilters={priceFilters} // PRICE
            setPriceFilters={setPriceFilters}
            onFiltersSubmit={handleFiltersSubmit} // ACTIONS
            onFiltersClear={handleFiltersClear}
          />
          <ModalLogin visible={false} />
          {articles.length > 0 ? (
            <>
              {articles}
              {isLoading && <Loader cards={postsPerPage} />}

              <div className={styles.foot}>
                {!isLastPage && (
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      setCurentPage((prevState) => ++prevState);
                    }}
                  >
                    {isLoading ? "Ładowanie..." : "Następna"}
                  </button>
                )}
              </div>
            </>
          ) : (
            !isLoading && <div className={styles.info}>Brak kursów</div>
          )}
          {isLoading && !isLastPage && currentPage < 2 && (
            <Loader cards={postsPerPage * currentPage} />
          )}
        </div>
        <div className={styles.col}>
          <RecommendedPosts className={styles.card} limit={3} />
          {/* <Comments className={styles.card} comments={comments} /> */}
          <LatestComments className={styles.card} limit={3} />
          {/* <RefundRequests title="Refund requests" classTitle="title-red" /> */}
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Courses;
