import React, { useRef, useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "components/TextInput";
import Image from "components/Image";
import Icon from "components/Icon";
import PhoneInput from "react-phone-number-input";
import PhoneNumberInput from "components/PhoneNumberInput";

const Entry = ({
  onConfirm,
  onChange,
  phoneData,
  onPhoneChange,
  registerData,
  errorMsg,
}) => {
  //const [phone, setPhone] = useState("");
  return (
    <div className={styles.entry}>
      {/* <div className={styles.head}>
        <div className={styles.info}>
          Zarejestruj się przez konto zewnętrzne
        </div>
        <div className={styles.btns}>
          <button className={cn("button-stroke", styles.button)}>
            <img src="/images/content/google.svg" alt="Google" />
            Google
          </button>
          <button className={cn("button-stroke", styles.button)}>
            <Image
              className={styles.pic}
              src="/images/content/apple-dark.svg"
              srcDark="/images/content/apple-light.svg"
              alt="Apple"
            />
            Apple ID
          </button>
        </div>
      </div> */}
      <div className={styles.body}>
        {/* <div className={styles.info}>Lub kontynuuj z adresem email</div> */}

        {errorMsg && (
          <div className={styles.error}>
            <Icon name="info" size="24" />
            {errorMsg}
          </div>
        )}

        <TextInput
          className={styles.field}
          name="email"
          type="email"
          placeholder="Email"
          required
          icon="mail"
          onChange={onChange}
          value={registerData.email}
        />
        <TextInput
          className={styles.field}
          name="displayName"
          type="text"
          placeholder="Nazwa użytkownika"
          required
          icon="smile"
          onChange={onChange}
          value={registerData.displayName}
        />
        {/* <TextInput
          className={styles.field}
          name="phone"
          type="text"
          placeholder="Your phone"
          required
          icon="phone"
          onChange={onChange}
          value={registerData.phone}
        /> */}
        <PhoneNumberInput
          className={styles.field}
          name="phone"
          value={phoneData}
          onChange={onPhoneChange}
        />
        <TextInput
          className={`${styles.field}`}
          name="passwd"
          type="password"
          placeholder="Hasło"
          required
          icon="lock"
          onChange={onChange}
          value={registerData.passwd}
        />
        <TextInput
          className={styles.field}
          name="passwd2"
          type="password"
          placeholder="Powtórz hasło"
          required
          icon="lock"
          onChange={onChange}
          value={registerData.passwd2}
        />
        <button
          className={cn("button", styles.button)}
          onClick={(e) => onConfirm(e)}
          type="submit"
        >
          Zarejestruj się
        </button>
        {/* <div className={styles.note}>
          Ta strona jest zabezpieczona przez reCAPTCHA i Politykę Prywatności
          Google
        </div> */}
      </div>
    </div>
  );
};

export default Entry;
