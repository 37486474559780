import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import Icon from "../../components/Icon";

import useAuth from "../../hooks/useAuth";
import devscntrNoauth from "../../api/Instance/devscntrNoauth";

const LOGIN_URL = "accounts/login";
const PHONE_VERIFY_URL = "accounts/login/phone-verifications";

// SignIn component
const SignIn = () => {
  // Authorization context
  const authCtx = useAuth();

  const heightWindow = use100vh();
  const history = useHistory();

  // Manage inputs, errors
  const [errMsg, setErrMsg] = useState("");
  const [loginData, setLoginData] = useState({
    email: "",
    passwd: "",
  });

  // Send login data to API, handle response
  const loginVerification = async () => {
    try {
      // Send data
      const response = await devscntrNoauth.post(
        LOGIN_URL,
        JSON.stringify({ email: loginData.email, password: loginData.passwd })
      );
      console.log(response);

      // Clear login inputs
      setLoginData((prev) => ({
        ...prev,
        email: "",
        passwd: "",
      }));

      // If there is a reponse, set accessToken
      const accessToken = response?.data?.access;
      //const accessToken = response?.data?.token;
      const expirationTime = new Date(new Date().getTime() + 86300 * 1000);
      // Login user
      authCtx.login(response.data.access, expirationTime.toISOString()); //expirationTime.toISOString());

      // Verify your token, get bearer access token in response
      //phoneVerification(accessToken);
    } catch (error) {
      // if (!error?.response) {
      //   setErrMsg("Brak odpowiedzi");
      // } else if (error.response?.status === 400) {
      //   setErrMsg("Uzupełnij pola");
      // } else if (error.response?.status === 401) {
      //   setErrMsg("Nieprawidłowy email lub hasło");
      // } else {
      //   setErrMsg("Wystąpił błąd serwera");
      // }
      setErrMsg("Nieprawidłowy email lub hasło");
    }
  };

  /*
  // Phone verification, set login if given token is accepted
  const phoneVerification = async (token) => {
    try {
      // Send data
      const response = await devscntrNoauth.post(
        PHONE_VERIFY_URL,
        JSON.stringify({ token: token, code: "0" })
      );

      // Clear login inputs
      setLoginData((prev) => ({
        ...prev,
        email: "",
        passwd: "",
      }));

      const expirationTime = new Date(new Date().getTime() + 86300 * 1000);

      // Login user
      authCtx.login(response.data.access, expirationTime.toISOString()); //expirationTime.toISOString());

      // Redirect to '/' path after succeed
      history.replace("/");
    } catch (error) {
      setErrMsg("Wystąpił błąd serwera");
    }
  };
  */

  // Handle changes in inputs, set values in registerData every change
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    loginVerification();
  };

  // Handle submit, login
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Try to login
    loginVerification();
  };

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-dark.svg"
            srcDark="/images/logo-light.svg"
            alt="DevsCNTR"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Zaloguj się</div>
        <div className={styles.head}>
          <div className={styles.subtitle}>
            Zaloguj się przez konto zewnętrzne
          </div>
          <div className={styles.btns}>
            <button className={cn("button-stroke", styles.button)}>
              <img src="/images/content/google.svg" alt="Google" />
              Google
            </button>
            <button className={cn("button-stroke", styles.button)}>
              <Image
                className={styles.pic}
                src="/images/content/apple-dark.svg"
                srcDark="/images/content/apple-light.svg"
                alt="Apple"
              />
              Apple ID
            </button>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.subtitle}>Lub kontynuuj z adresem email</div>

          {errMsg.length > 0 && (
            <div className={styles.error}>
              <Icon name="info" size="24" />
              {errMsg}
            </div>
          )}

          <TextInput
            className={styles.field}
            name="email"
            type="email"
            placeholder="Twój email"
            required
            icon="mail"
            onChange={handleChange}
            value={loginData.email}
            onKeyDown={handleKeyDown}
          />
          <TextInput
            className={styles.field}
            name="passwd"
            type="password"
            placeholder="Hasło"
            required
            icon="lock"
            onChange={handleChange}
            value={loginData.passwd}
            onKeyDown={handleKeyDown}
          />
          <button
            className={cn("button", styles.button)}
            onClick={handleSubmit}
          >
            Zaloguj się
          </button>
          {/* <div className={styles.note}>
            Ta strona jest zabezpieczona przez reCAPTCHA i Politykę Prywatności
            Google
          </div> */}
          <div className={styles.info}>
            Nie masz jeszcze konta?{" "}
            <Link className={styles.link} to="/sign-up">
              Zarejestruj się
            </Link>
          </div>
          <Link
            to="/home"
            className={cn("button-stroke", styles.button, styles.guest)}
          >
            Kontynuuj jako gość
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
