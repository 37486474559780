import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
//const BASE_URL = "https://devscntr-staging.herokuapp.com/api/v1";
//const BASE_URL = "https://server.devscntr.com/api/v1/";

const devscntrNoauth = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default devscntrNoauth;
