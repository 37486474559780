import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./LatestCourses.module.sass";
import { Link } from "react-router-dom";
import Card from "../Card";
import ModalProduct from "../ModalProduct";
import axios from "axios";
import Item from "./Item";
import SkeletonLoader from "./Loader";

import useAuth from "../../hooks/useAuth";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../api/Instance/devscntrNoauth";

const LatestCourses = ({ className, limit }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [latestCourses, setLatestCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [preparedCourses, setPreparedCourses] = useState(true);

  const getLatestCourses = async (cancelToken) => {
    try {
      const response = await axiosInstance.request({
        method: "get",
        url: "/misc/newest-courses/",
        cancelToken: cancelToken.token,
      });
      setLatestCourses(response.data);
      setIsLoading(false);
      // console.log(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("latest courses canceled");
      } else {
        console.log(error);
      }
    }
  };

  const preparePosts = () => {
    setPreparedCourses(() =>
      latestCourses.map(
        (course, index) => limit > index && <Item course={course} key={index} />
      )
    );
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getLatestCourses(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [isLoading]);

  useEffect(() => {
    preparePosts();
  }, [latestCourses]);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Najnowsze kursy"
      classTitle="title-blue"
    >
      <div className={styles.popular}>
        {/* <div className={styles.head}>
            <div className={styles.stage}>Nazwa</div>
            <div className={styles.stage}>Kategoria</div>
          </div> */}
        {isLoading ? (
          <SkeletonLoader cards={3} />
        ) : (
          <div className={styles.list}>{preparedCourses}</div>
        )}
        <Link className={cn("button-stroke", styles.button)} to="/courses">
          Wszystkie kursy
        </Link>
      </div>
    </Card>
  );
};

export default LatestCourses;
