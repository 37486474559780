export const publications = [
  {
    id: 0,
    product: "Bento Matte 3D Illustration",
    link: "ui8.net/product/product-link",
    image: "/images/content/product-pic-1.jpg",
    image2x: "/images/content/product-pic-1@2x.jpg",
    date: "17:23 11.08.2022",
    // time: "17:23",
    ratingValue: 4.8,
    ratingCounter: 87,
    status: 0,
    type: 0,
    views: 51000,
    lead: 300,
    likes: 100,
    unlikes: 100,
  },
  {
    id: 1,
    product: "Node - Crypto iOS UI design kit",
    link: "ui8.net/product/product-link",
    image: "/images/content/product-pic-2.jpg",
    image2x: "/images/content/product-pic-2@2x.jpg",
    date: "17:23 11.08.2022",
    ratingValue: 1.1,
    ratingCounter: 123,
    status: 1, //Scheduled
    type: 1, //Course
    views: 2510345,
    lead: 3824,
    likes: 12224,
    unlikes: 1354,
  },
  {
    id: 2,
    product: "TaskEz: Productivity App iOS UI Kit",
    link: "ui8.net/product/product-link",
    image: "/images/content/product-pic-3.jpg",
    image2x: "/images/content/product-pic-3@2x.jpg",
    date: "17:23 11.08.2022",
    status: 2, //Draft
    type: 2, //Job offer
    views: 999,
    lead: 0,
    likes: 123,
    unlikes: 42,
  },
  {
    id: 3,
    product: "qwerqwerqwerqwerqwerqwerqwreqwerqwerqwerqwerqwerasdfasdfsafdasdfasdfasfd",
    link: "ui8.net/product/product-link",
    image: "/images/content/product-pic-3.jpg",
    image2x: "/images/content/product-pic-3@2x.jpg",
    date: "17:55 11.08.2022",
    status: 1,
    type: 3, //Technology
  },
];
