import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "../Item.module.sass";
import Icon from "../../../../Icon";
import useAxiosFunction from "../../../../../hooks/useAxiosFunction";
import axios from "axios";
import api from "../../../../../api/devscntr";
import devscntrAuth from "../../../../../api/Instance/devscntrAuth";

const ItemMisc = ({ className, item, onClose }) => {
  const [isRead, setIsRead] = useState(false);

  const [userInfo, userInfoError, userInfoLoading, userInfoAxiosFetch] =
    useAxiosFunction();

  const date = new Date(item.created_at);

  const setAsRead = () => {
    if (!isRead) {
      devscntrAuth
        .delete(`/accounts/me/notification/`, {
          headers: {
            TYPE: "misc",
            ID: item.id,
          },
        })
        .then(() => {
          setIsRead(true);
        });
    }
  };

  return (
      <div
          className={cn(styles.item, { [styles.new]: !isRead }, className)}
          onClick={setAsRead}
      >
        <div className={styles.avatar}>
          <img
              src={
                item.foreign_user_image
                    ? item.foreign_user_image
                    : "/images/default-avatar.png"
              }
              alt="Avatar"
          />
          <div className={styles.icon}>
            <Icon name="list" />
          </div>
        </div>
        <div className={styles.details}>
          <div className={styles.line}>
            <div className={styles.subtitle}>{item.title}</div>
            <div className={styles.time}>{date.toLocaleString()}</div>
          </div>
          <div className={styles.content}>
            {item.foreign_user_name}
            <p>{item.text}</p>
          </div>
        </div>
      </div>
  );
};

export default ItemMisc;
