import React from "react";
import styles from "./Tags.module.sass";
import cn from "classnames";
import uuid from "react-uuid";

const Tags = ({ className, tags }) => {
  return (
    <ul className={cn(styles.tags, className && className)}>
      {tags?.map((item) => (
        <li
          key={uuid()}
          className={styles.tag}
          style={{ backgroundColor: item?.colour || "#ffffff" }}
        >
          {item?.name}
        </li>
      ))}
    </ul>
  );
};

export default Tags;
