import React, { useEffect, useRef, useState } from "react";
import styles from "./MiniJobOffer.module.sass";
import cn from "classnames";
import { Link, useLocation } from "react-router-dom";
import relatedStyle from "./RelatedMiniJob.module.sass";

import Icon from "../Icon";
import {
  jobContractType,
  jobFormat,
  currencyList,
  jobSeniority,
} from "../../data/dataArrays";
import Actions from "components/Actions";
import useAuth from "hooks/useAuth";
import ModalPin from "components/ModalPin";
import { showLoginModal } from "utils/showLoginModal";
import Thumbnail from "components/MiniItemsLayout/Thumbnail";
import Tags from "components/MiniItemsLayout/Tags";
import MoreOptions from "components/MiniItemsLayout/MoreOptions";
import useBreakpoints from "hooks/useBreakpoints";

const currencyCode = currencyList[0].code;

const MiniJobOffer = React.forwardRef(({ article, related }, ref) => {
  const authCtx = useAuth();
  const [hidden, setHidden] = useState(false);
  const [extendLocations, setExtendLocations] = useState(false);

  const stylesRef = useRef(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ["desktop", Infinity],
    ["mobile", 716],
  ];
  useBreakpoints(stylesRef.current, breakpoints);

  const now = new Date();
  const date = new Date(article?.created_at);
  const diff = now - date;
  const daysAgo = Math.ceil(diff / (1000 * 3600 * 24));

  // -----------------------------------------------------
  // <----- !!!! PRZYPINKI !!!! ----->
  // -----------------------------------------------------
  const [pinState, setPinState] = useState(article?.pinned);
  const [StarModalVisible, setStarModalVisible] = useState(false);
  const location = useLocation();
  // <----- WALIDACJA ODPINANIA ----->
  const pinsPath = location.pathname.slice(0, 5);
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (pinsPath === "/pins") {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };
  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    setStarModalVisible(true);
  };
  // -----------------------------------------------------
  // -----------------------------------------------------

  return (
    article &&
    !hidden && (
      <article ref={stylesRef}>
        <div className={styles.item} ref={ref}>
          <Link to={`/jobs/${article.id}`} className={styles.image}>
            <Thumbnail
              className={article?.company_logo && styles.image_wrapper}
              image={article?.company_logo}
              imageFit="contain"
            />
          </Link>
          <div className={styles.details}>
            <div className={styles.top}>
              <div className={styles.row}>
                <Link to={`/jobs/${article.id}`}>
                  <div className={styles.title}>
                    {article?.title || "Brak tytułu"}
                  </div>
                </Link>
                {/* <----- !!!! MODALPIN !!!! -----> */}

                {(StarModalVisible || redirectedUser || unpinPost) && (
                  <ModalPin
                    visible={StarModalVisible}
                    onClose={() => setStarModalVisible(false)}
                    article={article}
                    setPinState={setPinState}
                    redirectedUser={redirectedUser}
                    setRedirectedUser={setRedirectedUser}
                    unpinPost={unpinPost}
                    setunpinPost={setunpinPost}
                    pinUrl={"/jobs/user-job/"}
                    idName={"job_id"}
                  />
                )}
                {/* <----- !!!! PRZYCISK !!!! -----> */}
                <div
                  className={!related ? styles.actions : relatedStyle.actions}
                >
                  <button
                    className={!related ? styles.pin_btn : relatedStyle.pin_btn}
                    onClick={pinState ? unpinHandler : pinHandler}
                  >
                    <img
                      src={
                        pinState
                          ? "/images/Icons/heart/filled-color.svg"
                          : "/images/Icons/heart/stroke.svg"
                      }
                      alt="favourite"
                    />
                  </button>
                  <MoreOptions
                    item={article}
                    type="job"
                    onDelete={() => setHidden(true)}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.company_name}>
                  {article?.company_name || "Brak nazwy firmy"}
                </div>
                {/* <div className={styles.seniority}>
              {jobSeniority[article?.seniority - 1]}
            </div> */}
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.row}>
                <div className={styles.div_placeholder}></div>
                <div className={styles.format}>
                  {jobFormat[article?.format - 1]}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.salary}>
                  {article?.salary?.length > 0 && (
                    <>
                      <p>{jobContractType[article?.salary[0]?.type - 1]}: </p>
                      {parseFloat(article?.salary[0].type).toFixed(2) == 3
                        ? `${parseFloat(article?.salary[0]?.hour_wage).toFixed(
                            2
                          )} ${currencyCode}/h`
                        : `${parseFloat(
                            article?.salary[0]?.salary_from
                          ).toFixed(2)} ${currencyCode} - ${parseFloat(
                            article?.salary[0]?.salary_to
                          ).toFixed(2)} ${currencyCode}`}
                    </>
                  )}
                </div>

                {article?.tags?.length > 0 && (
                  // <div className={styles.tags}>
                  //   {article?.tags?.map(
                  //     (item, index) =>
                  //       index < 2 && (
                  //         <div className={styles.tag} key={index}>
                  //           {item.name}
                  //         </div>
                  //       )
                  //   )}
                  // </div>
                  <Tags className={styles.tags} tags={article?.tags} />
                )}
              </div>
              <div className={cn(styles.row, styles.mobile_row_reverse)}>
                <div className={styles.locations}>
                  <img
                    src={"/images/Icons/location/stroke.svg"}
                    alt="Localization"
                  />
                  {article?.localization?.length > 0 ? (
                    <>
                      {article?.localization[0]?.city}
                      {article?.localization?.length > 1 &&
                        `, +${article?.localization?.length - 1} inne`}
                      {article?.localization?.length > 1 && (
                        <button
                          className={styles.extend_locations}
                          onClick={() => setExtendLocations((prev) => !prev)}
                        >
                          <Icon
                            name="arrow-down"
                            size="20"
                            className={extendLocations && styles.rotate180}
                          />
                        </button>
                      )}
                      <div
                        className={cn(
                          styles.show_locations,
                          extendLocations && styles.active
                        )}
                      >
                        <ul>
                          {article?.localization?.map((location, index) => (
                            <Link to={`/jobs/${article.id}`} key={index}>
                              <li>
                                ul. {location.street} {location.number},{" "}
                                {location.city}
                              </li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    </>
                  ) : (
                    "Brak lokalizacji"
                  )}
                </div>
                <div className={styles.date}>
                  {/* {daysAgo < 2 ? (
                  <div className={styles.new_badge}>Nowe</div>
                ) : (
                  article?.created_at?.slice(0, 10)
                )} */}
                  {article?.created_at?.slice(0, 10)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    )
  );
});

export default MiniJobOffer;
