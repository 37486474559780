export const labels = [
  {
    name: 'Produkt',
    style: 'product'
  },
  {
    name: 'Status',
    style: 'status'
  },
  {
    name: 'Typ',
    style: 'type'
  },
  {
    name: 'Wyświetlenia',
    style: 'views'
  },
  {
    name: 'Lead-y',
    style: 'leads'
  },
  {
    name: 'Reakcje',
    style: 'reactions'
  },
  {
    name: 'Ocena',
    style: 'rating'
  },
  {
    name: '',
    style: 'options'
  },
];