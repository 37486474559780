import React from "react";
import style from "./Skeleton.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      // zrobic light mode
      <SkeletonTheme key={i}>
        <div className={style.skeleton} key={i}>
          <div className={style.head_btns}>
            <div>
              <Skeleton width={150} height={50} borderRadius={10} />
              <Skeleton width={50} height={50} borderRadius={10} />
            </div>
            <div>
              <Skeleton width={50} height={50} borderRadius={10} />
              <Skeleton width={150} height={50} borderRadius={10} />
            </div>
          </div>
          <div className={style.title}>
            <div className={style.img}>
              <Skeleton width={200} height={150} borderRadius={10} />
            </div>
            <div className={style.title_content}>
              <Skeleton count={3} />
              <Skeleton width={150} height={50} borderRadius={10} />
            </div>
          </div>

          <div className={style.card}>
            <div className={style.head}>
              <Skeleton width={200} height={20} borderRadius={10} />
            </div>
            <div className={style.body}>
              <Skeleton count={3} />
            </div>
          </div>

          <div className={style.card}>
            <Skeleton height={200} borderRadius={10} />
          </div>

          <div className={style.card}>
            <div className={style.head}>
              <Skeleton width={200} height={20} borderRadius={10} />
            </div>
            <div className={style.body}>
              <Skeleton count={3} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default SkeletonLoader;
