import React, { useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import Checkbox from "../../../components/Checkbox";
import {
  courseDifficulty,
  courseFormat,
  courseLanguage,
} from "../../../data/dataArrays";

const Details = ({ data, setData }) => {
  const [isCourseFree, setIsCourseFree] = useState(true);
  const [isReflink, setIsReflink] = useState(false);

  const handleCourseFormatChange = (value) => {
    setData((prev) => ({ ...prev, courseFormat: value }));
  };

  const handleCourseDifficultyChange = (value) => {
    setData((prev) => ({ ...prev, courseDifficulty: value }));
  };

  const handleCourseLanguageChange = (value) => {
    setData((prev) => ({ ...prev, language: value }));
  };

  return (
    <Card
      className={cn(styles.card)}
      title="Szczegóły kursu"
      classTitle="title-red"
    >
      <div className={styles.details}>
        <div className={styles.col}>
          <TextInput
            icon="clock"
            label="Czas trwania (w godzinach)"
            value={data.duration}
            onChange={(e) =>
              setData((prev) => ({ ...prev, duration: e.target.value }))
            }
          />
          <Dropdown
            label="Forma kursu"
            value={data.courseFormat}
            setValue={handleCourseFormatChange}
            options={courseFormat}
            classDropdownHead="Forma kursu"
          />

          {/* {data.courseFormat === "stacjonarny" && (
            <div className={styles.location}>
              <div className={styles.col}>
                <TextInput
                  label="Miasto"
                  value={data.courseLocation.city}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      courseLocation: {
                        ...prev.courseLocation,
                        city: e.target.value,
                      },
                    }))
                  }
                />
                <TextInput
                  label="Kod pocztowy"
                  value={data.courseLocation.code}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      courseLocation: {
                        ...prev.courseLocation,
                        code: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className={styles.col}>
                <TextInput
                  label="Ulica"
                  value={data.courseLocation.street}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      courseLocation: {
                        ...prev.courseLocation,
                        street: e.target.value,
                      },
                    }))
                  }
                />
                <TextInput
                  label="Adres"
                  value={data.courseLocation.address}
                  onChange={(e) =>
                    setData((prev) => ({
                      ...prev,
                      courseLocation: {
                        ...prev.courseLocation,
                        address: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </div>
          )} */}
          <Checkbox
            content="Certyfikat ukończenia"
            value={data.certificate}
            onChange={(e) => {
              setData((prev) => ({ ...prev, certificate: !prev.certificate }));
            }}
          />
          <Checkbox
            content="Kurs jest darmowy"
            value={isCourseFree}
            onChange={(e) => {
              setIsCourseFree(e.target.checked);
              setData((prev) => ({ ...prev, coursePrice: "" }));
            }}
          />
          {!isCourseFree && (
            <div className={styles.price}>
              <TextInput
                icon="diamond"
                label="Cena (zł)"
                value={data.coursePrice}
                onChange={(e) =>
                  setData((prev) => ({ ...prev, coursePrice: e.target.value }))
                }
                disabled={isCourseFree}
              />
              <TextInput
                icon="promotion"
                label="Cena promocyjna (zł)"
                value={data.courseSalePrice}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    courseSalePrice: e.target.value,
                  }))
                }
                disabled={isCourseFree}
              />
            </div>
          )}
        </div>
        <div className={styles.col}>
          <Dropdown
            label="Poziom kursu"
            value={data.courseDifficulty}
            setValue={handleCourseDifficultyChange}
            options={courseDifficulty}
          />
          <Dropdown
            label="Język kursu"
            value={data.language}
            setValue={handleCourseLanguageChange}
            options={courseLanguage}
          />
          <Checkbox
            content="Link do kursu"
            value={isReflink}
            onChange={(e) => {
              setIsReflink(e.target.checked);
              setData((prev) => ({ ...prev, reflink: "" }));
            }}
          />
          {isReflink && (
            <TextInput
              icon="link"
              label="Link do kursu"
              placeholder="https://devscntr.com/"
              value={data.reflink}
              onChange={(e) =>
                setData((prev) => ({ ...prev, reflink: e.target.value }))
              }
              disabled={!isReflink}
            />
          )}
        </div>
      </div>
      <div className={styles.row}></div>
    </Card>
  );
};

export default Details;
