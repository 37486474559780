import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Filtering.module.sass";
import Filters from "../../../components/Filters";
import Dropdown from "../../../components/Dropdown";
import {
  jobSeniority,
  jobRecruitment,
  jobFormat,
} from "../../../data/dataArrays";

const Filtering = ({ filters, setFilters }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [seniority, setSeniority] = useState(filters.seniority);
  const [format, setFormat] = useState(filters.format);
  const [recruitmentFormat, setRecruitmentFormat] = useState(
    filters.recruitmentFormat
  );

  useEffect(() => {
    if (isMounted) {
      setFilters((prev) => ({
        ...prev,
        seniority: seniority,
        format: format,
        recruitmentFormat: recruitmentFormat,
      }));
    } else {
      setIsMounted(true);
    }
  }, [seniority, format, recruitmentFormat]);

  useEffect(() => {
    setSeniority(filters.seniority);
    setFormat(filters.format);
    setRecruitmentFormat(filters.recruitmentFormat);
  }, [filters]);

  const handleFiltersClear = () => {
    setFilters({
      seniority: "Wybierz poziom",
      format: "Wybierz formę pracy",
      recruitmentFormat: "Wybierz formę rekurtacji",
    });
    setSeniority("Wybierz poziom");
    setFormat("Wybierz formę pracy");
    setRecruitmentFormat("Wybierz formę rekurtacji");
  };

  return (
    <Filters className={styles.filter} title="Filtrowanie">
      <div className={styles.filter_body}>
        <Dropdown
          label="Poziom"
          options={jobSeniority}
          value={seniority}
          setValue={setSeniority}
        />
        <Dropdown
          label="Forma pracy"
          options={jobFormat}
          value={format}
          setValue={setFormat}
        />
        {/* <Dropdown
          label="Forma rekrutacji"
          options={jobRecruitment}
          value={recruitmentFormat}
          setValue={setRecruitmentFormat}
        /> */}

        <button
          className={cn("button", styles.button)}
          onClick={handleFiltersClear}
        >
          Resetuj filtry
        </button>
      </div>
    </Filters>
  );
};
export default Filtering;
