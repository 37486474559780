import React, { useEffect, useState } from "react";
import styles from "./JobsMap.module.sass";
import "./Leaflet.sass";
import { hideJobsMap, setJobId } from "store/jobsMap/actions";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { Link, Redirect, useHistory } from "react-router-dom";
import { currencyList } from "data/dataArrays";

const currencyCode = currencyList[0].code;

const DEFAULT_LATITUDE = 52.376;
const DEFAULT_LANGITUDE = 19.516;

const DUMMY_DATA = [
  {
    title: "Programista PHP",
    company_name: "DevsCNTR",
    // company_logo:
    //   "https://d315baofnr6zvj.cloudfront.net/userimages/profile_img_5.jpeg",
    salary: [
      {
        type: "2",
        salary_from: "3200.00",
        salary_to: "4700.00",
        hour_wage: "30.00",
      },
    ],
    id: 43,
    x: 52.3,
    y: 19.2,
  },
  {
    title: "Programista PHP",
    company_name: "DevsCNTR",
    salary: [
      {
        type: "1",
        salary_from: "3200.00",
        salary_to: "4700.00",
        hour_wage: "30.00",
      },
    ],
    id: 42,
    x: 52.6,
    y: 19.9,
  },
  {
    title: "Programista PHP",
    company_name: "DevsCNTR",
    salary: [
      {
        type: "3",
        salary_from: "3200.00",
        salary_to: "4700.00",
        hour_wage: "30.00",
      },
    ],
    id: 41,
    x: 52.5,
    y: 19.4,
  },
];

const MapPlaceholder = () => {
  return (
    <p>
      Mapa pracy. <noscript>Aktywuj JavaScript, żeby zobaczyć mapę.</noscript>
    </p>
  );
};

const JobsMap = () => {
  const { visible, currentId } = useSelector((state) => state.jobsMap);
  const dispatch = useDispatch();
  const history = useHistory();

  const [jobs, setJobs] = useState(DUMMY_DATA);

  const handleClose = () => {
    dispatch(hideJobsMap());
  };

  const handleJobIdChange = (id) => {
    dispatch(setJobId(id));
  };

  useEffect(() => {
    console.log("map render");
  }, []);

  const markerIcon = new L.Icon({
    iconUrl: "/images/icons/map_markers/php.png",
    iconSize: [35, 35],
    iconAnchor: [17, 46],
    popupAnchor: [0, -46],
  });

  const handleMarkerClick = (id) => {
    //history.push(`/jobs/${id}`);
    //handleJobIdChange(id);
    //handleClose();
  };

  return (
    <Modal
      outerClassName={styles.modal}
      visible={visible}
      onClose={handleClose}
    >
      <div className={styles.container}>
        <MapContainer
          center={[DEFAULT_LATITUDE, DEFAULT_LANGITUDE]}
          zoom={7}
          scrollWheelZoom={true}
          placeholder={<MapPlaceholder />}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
            zIndex: 0,
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {jobs?.map((item, index) => (
            <Marker
              position={[item.x, item.y]}
              icon={
                new L.Icon({
                  iconUrl: item?.company_logo || "/images/default-logo.png",
                  iconSize: [35, 35],
                  iconAnchor: [17, 46],
                  popupAnchor: [0, -46],
                  className: styles.marker_icon,
                })
              }
              eventHandlers={{ click: () => handleMarkerClick(item.id) }}
              key={`map_marker_${index}`}
            >
              <Popup className={styles.popup}>
                <Link to={`/jobs/${item.id}`} className={styles.popup_item}>
                  <div className={styles.image}>
                    <img
                      src={item?.company_logo || "/images/default-logo.png"}
                    />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.popup_title}>{item.title}</div>
                    <div className={styles.popup_salary}>
                      {parseFloat(item?.salary[0].type).toFixed(2) == 3
                        ? `${parseFloat(item?.salary[0]?.hour_wage).toFixed(
                            2
                          )} ${currencyCode}/h`
                        : `${parseFloat(item?.salary[0]?.salary_from).toFixed(
                            2
                          )} ${currencyCode} - ${parseFloat(
                            item?.salary[0]?.salary_to
                          ).toFixed(2)} ${currencyCode}`}
                    </div>
                    <div className={styles.popup_company}>
                      {item.company_name}
                    </div>
                  </div>
                </Link>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </Modal>
  );
};

export default JobsMap;
