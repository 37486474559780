import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import ProfileAvatar from "../../ProfileAvatar";
import useAuth from "../../../hooks/useAuth";
/*
const items = [
  {
    menu: [
      {
        title: "Profile",
        url: "/profile/" + localStorage.getItem("userId"),
      },
      {
        title: "Account settings",
        url: "/settings",
      },
      {
        title: "Log out",
        url: "/logout",
      },
    ],
  },
  {
    menu: [
      {
        title: "Analytics",
        icon: "bar-chart",
        url: "/customers/overview",
      },
      {
        title: "Affiliate center",
        icon: "ticket",
        url: "/affiliate-center",
      },
      {
        title: "Explore creators",
        icon: "grid",
        url: "/explore-creators",
      },
    ],
  } {
    menu: [
      {
        title: "Upgrade to Pro",
        icon: "leaderboard",
        color: true,
        url: "/upgrade-to-pro",
      },
    ],
  },
  {
    menu: [

    ],
  },
  
  ,
];
*/
const User = ({ className }) => {
  const authCtx = useAuth();

  const [visible, setVisible] = useState(false);
  // const items = [
  //   {
  //     menu: [
  //       {
  //         title: "Profil",
  //         icon: "profile-circle",
  //         url: "/profile/" + authCtx.userID,
  //       },
  //       {
  //         title: "Panel twórcy",
  //         icon: "bar-chart",
  //         url: "/publications/",
  //       },
  //       {
  //         title: "Ustawienia konta",
  //         icon: "setting",
  //         url: "/settings",
  //       },
  //       {
  //         title: "Wyloguj się",
  //         icon: "arrow-right",
  //         url: "/logout",
  //         color: true,
  //       },
  //     ],
  //   },
  // ];
  const items = [
    {
      menu: [
        {
          title: "Profil",
          icon: "profile-circle",
          url: "/profile/" + authCtx.userID,
        },
        {
          title: "Ustawienia konta",
          icon: "setting",
          url: "/settings",
        },
        // authCtx.isAuthor
        //   ? {
        //       title: "Panel twórcy",
        //       icon: "bar-chart",
        //       url: "/publications/" + authCtx.userID,
        //     }
        //   : "",
        authCtx.isAdmin
          ? {
              title: "Narzędzia administratora",
              icon: "leaderboard",
              url: "/admin/",
            }
          : "",
      ],
    },
    {
      menu: [
        {
          title: "Wyloguj się",
          icon: "arrow-right",
          url: "/logout",
          color: true,
        },
      ],
    },
  ];

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        {/* <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img src={authCtx.userImg} alt="Avatar" />
        </button> */}
        <ProfileAvatar
          image={authCtx.userImg}
          level={authCtx.level}
          onClick={() => setVisible(!visible)}
          small
        />
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map(
                (x, index) =>
                  x.title &&
                  (x.url ? (
                    <NavLink
                      className={cn(styles.item, { [styles.color]: x.color })}
                      activeClassName={styles.active}
                      to={x.url}
                      onClick={() => setVisible(false)}
                      key={index}
                    >
                      {x.icon && <Icon name={x.icon} size="24" />}
                      {x.title}
                    </NavLink>
                  ) : (
                    <button
                      className={styles.item}
                      onClick={() => setVisible(false)}
                      key={index}
                    >
                      {x.title}
                    </button>
                  ))
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
