import React, { useEffect, useState, useRef } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import uuid from "react-uuid";

import Loader from "components/Loader";
import Row from "../Row";
import Checkbox from "components/Checkbox";

import useBreakpoints from "hooks/useBreakpoints";

import { labels } from "./data/labels";

export default function Table({ items, setItemsSelectedQuantity }) {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]); // tablica z id wszystkich wpisów
  const [list, setList] = useState([]); // lista wpisów
  const ref = useRef(null); // element DOM dla resizeObserver-a

  const breakpoints = [
    ["r-max", Infinity],
    ["r-lg", 1140],
    ["r-md", 996],
    ["r-sm", 858],
    ["r-sm-s", 712],
  ];
  useBreakpoints(ref.current, breakpoints);

  useEffect(() => {
    setItemsSelectedQuantity(isCheck.length);
  }, [isCheck]);

  useEffect(() => {
    setList(items);
  }, [items]);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);

    // lista zawiera wszystkie wpisy, do zaznaczenia wrzucane są id wpisów
    setIsCheckAll(latest => {
      latest ? setIsCheck(list.map(li => li.id)) : setIsCheck([]);
      return latest;
    });
  };

  const handleSelect = e => {
    const row = e.target.closest('.row');
    const rowId = +row.getAttribute('data-item-id');
    const checked = e.target.checked;

    // czyści checkboxa(all) przy zaznaczaniu pojedynczego
    setIsCheckAll(false);

    // wrzuca do isCheck obecne id oraz wszystkie poprzednie o ile się już tam nie znajduje
    if (!isCheck.includes(rowId)) {
      setIsCheck([...isCheck, rowId]);
    }

    // jeżeli nie jest zaznaczony to filtr go usunie z isCheck
    if (!checked) {
      setIsCheck(latest => latest.filter(item => item !== rowId));
    }
  };

  return (
    <>
      <div className={styles.wrapper} ref={ref}>
        <div className={cn(styles.label, styles.checkbox)}>
          <Checkbox
            className={styles.checkbox}
            onChange={handleSelectAll}
            value={isCheckAll}
          />
          <div className={cn(styles.label, styles.product, styles['product-tablet'])}>
            Produkt
          </div>
        </div>


        <div className={styles.labels}>
          {labels.map(label => (
            <div key={uuid()} className={cn(styles.label, styles[label.style])}>
              {label.name}
            </div>
          ))}
        </div>


        <div className={styles.divider}></div>


        <div className={styles.list}>
          {items.map(item => (
            <Row
              item={item}
              key={uuid()}
              handleSelect={handleSelect}
              isSelected={isCheck.includes(item.id)}
              parentRef={ref}
            />
          ))}
        </div>

      </div>


      <div className={styles.foot}>
        <button className={cn("button-stroke button-small", styles.button)}>
          <Loader className={styles.loader} />
          <span>Załaduj więcej</span>
        </button>
      </div>
    </>
  );
};