import React, { useContext, useEffect, useState } from "react";
import styles from "./Pins.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import { useHistory } from "react-router";
import LatestComments from "../../components/LatestComments";
import LatestCourses from "../../components/LatestCourses";
import { useParams } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import GroupContext from "../../context/group-context";
import MiniPost from "components/MiniPost";
import MiniCourse from "components/MiniCourse";
import MiniJobOffer from "components/MiniJobOffer";
import Loader from "components/MiniPost/Loader";

const Pins = ({ className }) => {
  const groupCtx = useContext(GroupContext);

  const [visible, setVisible] = useState(false);
  const { name } = useParams();

  const history = useHistory();

  // <----- POBIERNAIE KURSOW & POSTOW ----->
  const [Pins, errorPins, loadingPins, GetAllPins] = useAxios();
  const getPins = () => {
    GetAllPins({
      method: "get",
      url: `/accounts/user-pins/?groupnames=${name}`,
      customHeaders: {
        METHOD: "pins",
      },
    });
  };

  useEffect(() => {
    getPins();
  }, [name, groupCtx.deleteHandler]);

  // console.log("pins: ", Pins);
  // console.log("name: ", name);
  // <----- USUWANIE GURPY ----->
  const [Delete, errorDelete, loadingDelete, GetDelete] = useAxios();
  const deleteCurrentGroup = () => {
    GetDelete({
      method: "post",
      url: "/accounts/me/managegroup/",
      customHeaders: {
        METHOD: "delete",
      },
      requestConfig: {
        name: name,
      },
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    deleteCurrentGroup();
    groupCtx.setGroupHandler((prevState) => !prevState);
    history.push(`/home`);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.header}>
            <h1>{name}</h1>
            <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
              <div
                className={cn(styles.notification, className, {
                  [styles.active]: visible,
                })}
              >
                <button
                  className={styles.options}
                  onClick={() => setVisible(!visible)}
                >
                  <span></span>
                </button>
                <div className={styles.content}>
                  <button className={styles.groupDelete} onClick={handleDelete}>
                    Usuń Grupę
                  </button>
                </div>
              </div>
            </OutsideClickHandler>
          </div>

          {loadingPins ? (
            <Loader cards={6} />
          ) : (
            <>
              {Pins.count > 0 &&
                Pins.results.map((pin, index) => (
                  <li key={index} className={styles.pinElement}>
                    {pin.is_post && <MiniPost key={pin.id} article={pin} />}
                    {pin.is_course && <MiniCourse key={pin.id} article={pin} />}
                    {pin.is_job && <MiniJobOffer key={pin.id} article={pin} />}
                  </li>
                ))}
              {errorPins && <p>Coś poszło nie tak</p>}
              {Pins.count === 0 && <p>BRAK PRZYPINEK</p>}
            </>
          )}
        </div>
        <div className={styles.col}>
          <LatestCourses className={styles.card} limit={3} />
          <LatestComments className={styles.card} limit={3} />
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Pins;
