export const socialMedia = [
  {
    icon: "facebook",
    url: "https://www.facebook.com/groups/577615503221631",
  },
  // {
  //   icon: "instagram",
  //   url: "https://www.instagram.com/",
  // },
  // {
  //   icon: "twitter",
  //   url: "https://twitter.com/",
  // },
];

export const fileSizes = {
  avatar: 2,
  background: 5,
  thumbnail: 3,
};

export const currencyList = [
  {
    country: "POLAND",
    currency: "złoty",
    code: "PLN",
  },
];

// PAGE SIZE IN LIST PAGES
export const pageSize = 10;
export const adminPageSize = 10;

// EMOTION CHANGE DELAY IN MILISECONDS
export const emotionChangeDelay = 1000;

export const companySize = ["1", "10", "50", "100", "500", "1000"];

export const jobContractType = ["B2B", "UoP", "Zlecenie"];
export const jobSeniority = [
  "intern",
  "trainee",
  "junior",
  "regular",
  "mid",
  "senior",
  "lead",
];
export const jobFormat = ["stacjonarnie", "do wyboru", "zdalnie"];
export const jobRecruitment = ["online", "stacjonarnie"];
export const jobLevel = [
  "intern",
  "trainee",
  "junior",
  "regular",
  "mid",
  "senior",
  "lead",
];
export const jobSortingOptions = [
  {
    name: "Najnowsze",
    value: "newest",
  },
  {
    name: "Najstarsze",
    value: "oldest",
  },
  {
    name: "Najlepiej płatne",
    value: "highestpaid",
  },
  {
    name: "Najniżej płatne",
    value: "lowestpaid",
  },
  {
    name: "Największe doświadczenie",
    value: "highestexp",
  },
  {
    name: "Najniższy próg wejścia",
    value: "lowestexp",
  },
];

export const courseFormat = ["artykuł", "wideo", "online", "stacjonarny"];
export const courseDifficulty = [
  "początkujący",
  "średnio zaawansowany",
  "ekspert",
];
export const courseLanguage = ["PL", "ENG"];
export const courseSortingOptions = [
  {
    name: "Najnowsze",
    value: "newest",
  },
  {
    name: "Najstarsze",
    value: "oldest",
  },
  {
    name: "Oceny (od najlepszych)",
    value: "",
  },
  {
    name: "Cena (rosnąco)",
    value: "cheapest",
  },
  {
    name: "Cena (malejąco)",
    value: "mostexpensive",
  },
];

export const adminUsersRoles = [
  {
    name: "admin",
    value: "admin",
  },
  {
    name: "twórca",
    value: "author",
  },
  {
    name: "moderator",
    value: "moderator",
  },
];
