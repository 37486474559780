import React, { useEffect, useState } from "react";
import styles from "./NewJob.module.sass";
import TooltipGlodal from "components/TooltipGlodal";
import Modal from "components/Modal";
import Schedule from "components/Schedule";
import Icon from "components/Icon";
import Panel from "screens/NewLayouts/Panel";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import axios from "axios";
import cn from "classnames";
import Card from "components/Card";
import { useHistory, useParams } from "react-router-dom";
import Details from "./Details";
import Salary from "./Salary";
import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import { showError } from "utils/showError";
import { jobFormat, jobRecruitment, jobSeniority } from "data/dataArrays";
import { validateInput } from "utils/validation";
import TagsInput from "screens/NewLayouts/TagsInput";
import Content from "screens/NewLayouts/Content";
import LocationsInput from "screens/NewLayouts/LocationsInput";
import { adminEndpoints, jobsEndpoints } from "api/endpoints";

const EditJob = () => {
  // UTILS-------------------------------------------------------------------------UTILS

  const authCtx = useAuth();
  const [errorMsg, setErrorMsg] = useState("");
  const { id } = useParams();
  const history = useHistory();

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [article, setArticle] = useState({});

  // UTILS-------------------------------------------------------------------------UTILS
  // FIELDS-----------------------------------------------------------------------FIELDS

  const [title, setTitle] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [detailsData, setDetailsData] = useState({
    format: jobFormat[0],
    seniority: jobSeniority[0],
    application_link: "",
    recruitment_format: jobRecruitment[0],
  });

  const [salary, setSalary] = useState([]);
  const [locations, setLocations] = useState([]);
  const [companyLocations, setCompanyLocations] = useState([]);
  const [tags, setTags] = useState([]);

  // FIELDS-----------------------------------------------------------------------FIELDS
  // GET DATA-------------------------------------------------------------------GET DATA

  const getCompany = async (cancelToken) => {
    try {
      const response = await devscntrAuth.request({
        method: "get",
        url: "/accounts/company/show/",
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      const locations = response.data.locations;
      setCompanyLocations(
        locations?.map((item) => ({
          city: item.city,
          code: item.postcode,
          street: item.street,
          number: item.house_number,
        }))
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("cancel get company");
      } else {
        console.log(error);
      }
    }
  };

  const getJob = async (cancelToken) => {
    try {
      // Request user data
      const response = await devscntrAuth.request({
        method: "get",
        url: `${jobsEndpoints.show}${id}`,
        cancelToken: cancelToken.token,
      });
      setArticle(response?.data[0] || {});
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("job detail canceled");
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getJob(cancelToken);
    getCompany(cancelToken);

    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [id]);

  // INSERT DATA TO FIELDS
  useEffect(() => {
    if (article) {
      setTitle(article?.title || "");
      if (article?.content) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(article?.content))
          ) || EditorState.createEmpty()
        );
      }
      setDetailsData({
        format: jobFormat[parseInt(article?.format) - 1 || 0],
        seniority: jobSeniority[parseInt(article?.seniority) - 1 || 0],
        application_link: article?.application_link || "",
        recruitment_format:
          jobRecruitment[parseInt(article?.recruitment_format) - 1 || 0],
      });
      setSalary(article?.salary || []);
      setLocations(article?.localization || []);
      setTags(article?.tags?.map((item) => item.name) || []);
    }
  }, [article]);

  // GET DATA-------------------------------------------------------------------GET DATA
  // VALIDATION---------------------------------------------------------------VALIDATION

  const isValidUrl = (url) => {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  };

  const validateFields = (data) => {
    // TITLE
    if (!validateInput(data.title, 100, 3, "Tytuł")) return;

    // DESCRIPTION
    if (data.content.length < 133) {
      showError("Opis nie może być pusty");
      return false;
    }

    // SALARY
    if (data.salary.length < 1) {
      showError("Musisz dodać przynajmniej jedną opcję wynagrodzenia");
      return false;
    }

    // DROPDOWNS
    if (!jobFormat.includes(detailsData.format)) {
      showError("Wybierz formę pracy");
      return false;
    }
    if (!jobSeniority.includes(detailsData.seniority)) {
      showError("Wybierz doświadczenie");
      return false;
    }

    // LOCATIONS
    if (data.localization.length < 1) {
      showError("Musisz dodać przynajmniej jedną lokalizację firmy");
      return false;
    }

    // TECHNOLOGIES
    if (data.tags.length < 1) {
      showError("Musisz dodać przynajmniej jedną wymaganą technologię");
      return false;
    }

    // VALIDATE REFLINK
    if (
      !isValidUrl(data?.application_link) &&
      data?.application_link?.length > 0
    ) {
      showError("Podany link nie jest poprawny");
      return false;
    }

    return true;
  };

  // VALIDATION---------------------------------------------------------------VALIDATION
  // CREATE JOB REQUEST-----------------------------------------------CREATE JOB REQUEST

  const editJobApi = async (data) => {
    try {
      if (article.author === authCtx.userID) {
        const response = await devscntrAuth.post(
          `${jobsEndpoints.update}${id}/`,
          data,
          {
            headers: {
              method: "update",
            },
          }
        );
      } else if (authCtx.isAdmin) {
        const response = await devscntrAuth.put(
          `${adminEndpoints.jobs.update}${id}/`,
          data
        );
      } else {
        return;
      }
      history.push(`/jobs/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  // CREATE JOB REQUEST-----------------------------------------------CREATE JOB REQUEST
  // PREPARE DATA TO SEND-------------------------------------------PREPARE DATA TO SEND

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleTitleChange = (event) => {
    const val = event.target.value;
    setTitle(val);
  };

  const prepareTagList = (tags) => {
    return tags.map((tag) => tag).join(",");
  };

  const handleSubmit = () => {
    console.log(detailsData);
    const stringTags = prepareTagList(tags.map((item) => item));
    let preparedData = {
      title: title,
      content: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      format: jobFormat.indexOf(detailsData.format) + 1,
      salary: salary,
      seniority: jobSeniority.indexOf(detailsData.seniority) + 1,
      application_link: detailsData.application_link,
      recruitment_format:
        jobRecruitment.indexOf(detailsData.recruitment_format) + 1,
      tags: stringTags,
      localization: locations,
    };
    console.log(preparedData);
    if (validateFields(preparedData)) {
      editJobApi(preparedData);
    }
  };

  // PREPARE DATA TO SEND-------------------------------------------PREPARE DATA TO SEND

  return (
    <>
      <div className={styles.row}>
        <div className={styles.fields}>
          <Content
            className={styles.card}
            onTitleChange={handleTitleChange}
            onDescriptionChange={handleEditorStateChange}
            title={title}
            description={editorState}
          />
          <Salary data={salary} setData={setSalary} />
          <Details data={detailsData} setData={setDetailsData} />
          <LocationsInput
            locations={locations}
            setLocations={setLocations}
            savedLocations={companyLocations}
          />
          <TagsInput tags={tags} setTags={setTags} maxTagNumber={12} />
        </div>
      </div>
      <Panel
        setVisiblePreview={setVisiblePreview}
        setVisibleSchedule={setVisibleModal}
        onSubmit={handleSubmit}
        buttonText="Zapisz zmiany"
      />
      <TooltipGlodal />
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal>
    </>
  );
};

export default EditJob;
