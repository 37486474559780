import { useSelector, useDispatch } from "react-redux";
import { showError } from "./showError";

export const validateInput = (
  value = "",
  maxChars = 255,
  minChars = 0,
  fieldName = "",
  security = 1
) => {
  let allowedCharacters;
  switch (security) {
    case 0:
      // EVERYTHING ALLOWED
      console.log("Bez ograniczeń");
      allowedCharacters = /./g;
      break;
    case 1:
      // LETTERS, NUMBERS, SPECIAL CHARS
      console.log("Dozwolone znaki specjalne, cyfry i litery");
      allowedCharacters = /[a-zA-Z0-9\p{L}!@#$%\^&*\(\)+,.?\-]/g;
      break;
    case 2:
      // LETTERS AND NUMBERS
      console.log("Dozwolone tylko cyfry i litery");
      allowedCharacters = /[a-zA-Z0-9]/g;
      break;
    case 3:
      // PRICE
      console.log("Dozwolone tylko cyfry, przecinek i kropka");
      allowedCharacters = /[0-9\,\.]/g;
    default:
      allowedCharacters = /[a-zA-Z0-9]/g;
      break;
  }

  if (value.length <= 0) {
    showError(
      `Pole ${fieldName?.length > 1 ? `"${fieldName}"` : ""} nie może być puste`
    );
    return false;
  }
  if (minChars === maxChars) {
    if (value.length < maxChars) {
      showError(
        `Pole ${
          fieldName?.length > 1 ? `"${fieldName}"` : ""
        } musi zawierać ${maxChars} znaków`
      );
      return false;
    }
  } else {
    if (value.length < minChars) {
      showError(
        `Pole ${
          fieldName?.length > 1 ? `"${fieldName}"` : ""
        } musi zawierać od ${minChars} do ${maxChars} znaków`
      );
      return false;
    }
    if (value.length > maxChars) {
      showError(
        `Maksymalna ilość znaków pola ${
          fieldName?.length > 1 ? `"${fieldName}"` : ""
        } to ${maxChars}`
      );
      return false;
    }
  }

  if (!allowedCharacters.test(value)) {
    showError(
      `Pole ${
        fieldName?.length > 1 ? `"${fieldName}"` : ""
      } zawiera niedozwolone znaki`
    );
    return false;
  }

  return true;
};

export const validateUrl = (url) => {
  console.log("halo karthus");
  try {
    console.log("halo");
    console.log(Boolean(new URL(url)));
    if (Boolean(new URL(url))) {
      console.log("xd");
      return true;
    } else {
      showError(
        `Podany adres URL jest nieprawidłowy (przykład: https://devscntr.com/)`
      );
      return false;
    }
  } catch (e) {
    showError(
      `Podany adres URL jest nieprawidłowy (przykład: https://devscntr.com/)`
    );
    return false;
  }
};

export const validateImage = (file, maxFileSize = 5) => {
  // Allowed file extensions
  const allowedExtensions = /(\.png|\.jpg|\.jpeg)$/i;

  // Check file's size
  if (file?.size / 1024 / 1024 > maxFileSize) {
    showError(`Maksymalny rozmiar pliku to ${maxFileSize} MB`);
    return false;
  }
  // Check file's extension
  if (!allowedExtensions.exec(file?.name)) {
    showError(`Możesz przesłać tylko pliki w formacie .png, .jpg, .jpeg`);
    return false;
  }

  // If file is OK
  return true;
};
