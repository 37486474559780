import React from "react";
import cn from "classnames";
import styles from "./ModalLogin.module.sass";
import Modal from "../Modal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideGuestModal } from "store/guestModal/actions";

const ModalLogin = () => {
  const { visible } = useSelector((state) => state.guestModal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideGuestModal());
  };

  return (
    <Modal visible={visible} onClose={handleClose}>
      <div className={styles.logo}>
        <img src="/images/logo-light.svg" alt="DevsCNTR" />
      </div>
      <h2>Ta funkcja dostępna jest tylko dla zalogowanych użytkowników</h2>
      <div className={styles.btns}>
        <Link to="/sign-in" className={cn("button")}>
          Zaloguj się
        </Link>
        <button className={cn("button-stroke")} onClick={handleClose}>
          Kontynuuj jako gość
        </button>
      </div>
    </Modal>
  );
};
export default ModalLogin;
