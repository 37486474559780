import { useState, useCallback, useEffect } from "react";
import useResizeObserver from "use-resize-observer";
import setBreakpointClass from "utils/setBreakpointClass";

export default function useBreakpoints(containerElement, breakpoints = []) {
  const [breakpointName, setBreakpointName] = useState(undefined);

  useResizeObserver({
    ref: containerElement,
    onResize: useCallback(
      // wyszukuje nazwy pierwszego pasującego breakpoint (od najmniejszej)
      ({ width }) => {
        for (let i = breakpoints.length - 1; i >= 0; i--) {
          if (width < breakpoints[i][1]) {
            setBreakpointName(breakpoints[i][0]);
            break;
          }
        }
      },
      [breakpoints]
    ),
  });

  // ustawianie klas kontenerowi
  useEffect(() => {
    setBreakpointClass(containerElement, breakpoints, breakpointName);
  }, [containerElement, breakpointName]);
}
