import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
//import img from 'src/assets/logo-cropp.webp';
import "react-easy-crop/react-easy-crop.css";

import styles from "./PhotoCropper.module.sass";

const PhotoCropper = ({ onCropComplete, image, onSubmit, width, height }) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const handleOnCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    onCropComplete(croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={width / height}
          onCropComplete={handleOnCropComplete}
          onCropChange={setCrop}
          onZoomChange={setZoom}
        />
      </div>
      <div className={styles.control}>
        <button
          className={styles.button}
          onClick={() => onSubmit(croppedAreaPixels)}
        >
          Zapisz
        </button>
      </div>
    </div>
  );
};

export default PhotoCropper;
