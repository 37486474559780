import styles from "./StarRating.module.sass";
import Icon from "../../../../components/Icon";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Rating } from "react-simple-star-rating";
import { showLoginModal } from "utils/showLoginModal";
import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";

const StarRating = ({ article }) => {
  const [rateChangeVisible, setRateChangeVisible] = useState(false);
  const [currentRating, setCurrentRating] = useState(
    article?.rating_avg?.toFixed(1) || 0
  );
  const [rating, setRating] = useState(
    article?.user_rating ? (article?.user_rating * 100) / 5 : null
  );
  const ratings_count = article?.ratings?.length || 0;

  const isMounted = useRef(false);
  const authCtx = useAuth();

  /*
  useEffect(() => {
    rating && postRating((rating * 5) / 100); //ratingApi((rating * 5) / 100);
    console.log((rating * 5) / 100);
  }, [rating]);
  */

  useEffect(() => {
    //console.log(rating);
    if (isMounted.current) {
      rating && postRating((rating * 5) / 100); //ratingApi((rating * 5) / 100);
      //console.log((rating * 5) / 100);
      ratings_count === 0
        ? setCurrentRating((rating * 5) / 100)
        : setCurrentRating((prev) => (((prev * 100) / 5 + rating) / 200) * 5);
    } else {
      isMounted.current = true;
    }
  }, [rating]);

  const postRating = (rating) => {
    devscntrAuth
      .post("/course/course-rate/", {
        course: article.id,
        rating: rating,
        author: article.author,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*
  const ratingApi = (newRating) => {
    axiosFetch({
      axiosInstance: api,
      method: "post",
      url: "/course/course-rate/",
      requestConfig: {
        data: {
          course: article.id,
          rating: newRating,
          author: article.author,
        },
      },
    });
    console.log(response);
    console.log(error);
  };
*/
  return (
    <div
      className={styles.rating}
      onMouseEnter={() => setRateChangeVisible(true)}
      onMouseLeave={() => setRateChangeVisible(false)}
    >
      <Icon name="star-fill" size="24" />
      {/* ((article?.rating_avg * 100) / 100).toFixed(1) */}
      {/* {!article?.rating_avg
        ? (rating * 5) / 100
        : article.rating_avg.toFixed(1)} */}
      {currentRating && parseFloat(currentRating).toFixed(1)}
      {rateChangeVisible && (
        <div className={styles.ratechange}>
          <Rating
            size={20}
            ratingValue={rating}
            onClick={(rate) => {
              if (!authCtx.isLoggedIn) {
                showLoginModal();
                return;
              }
              rating !== rate ? setRating(rate) : setRating(0);
            }}
          />
        </div>
      )}
    </div>
  );
};
export default StarRating;
