import React from "react";
import styles from "./Loader.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loader = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <SkeletonTheme key={`minipost_loader_${i}`}>
        <div className={styles.container} key={i}>
          <div className={styles.image}>
            <Skeleton borderRadius={10} />
          </div>
          <div className={styles.details}>
            <div className={styles.head}>
              <div className={styles.author}>
                <Skeleton height={35} borderRadius={5} />
              </div>
              <div className={styles.more}>
                <Skeleton height={35} borderRadius={5} />
              </div>
            </div>
            <div className={styles.title}>
              <Skeleton height={20} borderRadius={5} />
            </div>
            <div className={styles.content}>
              <Skeleton height={20} borderRadius={5} />
            </div>
            <div className={styles.foot}>
              <div className={styles.reactions}>
                <Skeleton height={20} borderRadius={5} />
              </div>
              <div className={styles.time}>
                <Skeleton height={20} borderRadius={5} />
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default Loader;
