import React, { useState } from "react";
import styles from "./Item.module.sass";
import cn from "classnames";
import { Link, Redirect } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Checkbox from "components/Checkbox";
import Icon from "components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { showUserInfo } from "store/userInfo/actions";
import Actions from "components/Actions";
import { adminEndpoints } from "api/endpoints";

const Item = ({ user, selected, setSelected }) => {
  const authCtx = useAuth();
  const dispatch = useDispatch();

  const handleShowUserInfo = (id) => {
    dispatch(showUserInfo(id));
  };

  const handleSelect = () => {
    if (selected) {
      setSelected((prev) => prev.filter((item) => item.id === user.id));
    } else {
      setSelected((prev) => [...prev, user.id]);
    }
  };

  const handlePasswordReset = () => {
    console.log("passwd reset");
  };

  const actions = [
    // {
    //   title: "Podgląd",
    //   icon: "expand",
    //   action: () => handleShowUserInfo(user.id),
    // },
    {
      title: "Zresetuj hasło",
      icon: "lock",
      action: handlePasswordReset,
    },
    {
      title: "Zablokuj",
      icon: "close",
      color: true,
      action: () => console.log("Ban"),
    },
  ];

  const showSettings = () => {
    console.log("ustawienia konta");
  };

  return (
    authCtx.isAdmin && (
      <div className={styles.item}>
        <div className={styles.user_data}>
          <Checkbox
            className={styles.user_select}
            value={selected}
            onChange={handleSelect}
          />
          <Link to={`/admin/users/${user?.id}`} className={styles.col}>
            <div className={styles.image}>
              <img src={user?.image || "/images/default-avatar.png"} />
            </div>
          </Link>
          <Link to={`/admin/users/${user?.id}`} className={styles.username}>
            {user?.display_name || user?.username || "Brak nazwy"}
            {user?.is_admin && (
              <div
                className={cn("status-red", styles.status)}
                style={{ marginLeft: "12px" }}
              >
                Administrator
              </div>
            )}
          </Link>
          <div className={styles.col}>{user?.follower_count}</div>
          <div className={styles.col}>{user?.views_count || "0"}</div>
          <div className={styles.col}>
            {user?.posts_count || "0"}/{user?.courses_count || "0"}/
            {user?.jobs_count || "0"}
          </div>
        </div>
        {/* <button className={styles.settings} onClick={showSettings}>
          <Icon name="more-horizontal" size={24} />
        </button> */}
        <Actions
          className={styles.menu}
          classActionsHead={styles.settings}
          classActionsBody={styles.actionsBody}
          classActionsOption={styles.actionsOption}
          items={actions}
        />
      </div>
    )
  );
};
export default Item;
