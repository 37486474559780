import React from "react";
import style from "./Skeleton.module.sass";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      // zrobic light mode
      <SkeletonTheme key={i}>
        <div className={style.skeleton} key={i}>
          <div className={style.img}>
            <Skeleton width={50} height={50} borderRadius={50} />
          </div>
          <div className={style.container}>
            <div className={style.content}>
              <Skeleton count={1} />
            </div>
            <div className={style.button}>
              <Skeleton width={120} height={40} count={1} borderRadius={10} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    ));
};

export default SkeletonLoader;
