import React, { useRef, useState } from "react";
import styles from "./BackgroundImageEditor.module.sass";
import cn from "classnames";
import PhotoUploader from "components/PhotoUploader";
import { validateImage } from "utils/validation";
import Icon from "components/Icon";

const BackgroundImageEditor = ({ className, userId, img, setFile }) => {
  const inputRef = useRef();

  const [image, setImage] = useState(img || null);
  const [cropperData, setCropperData] = useState({
    visible: false,
    image: null,
    width: 0,
    height: 0,
    filename: "",
  });

  const handleClick = () => {
    inputRef.current.click();
  };

  const removeImage = () => {
    setImage(null);
    setFile(null);
  };

  // Hide image cropper
  const hideCropper = () => {
    setCropperData((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  const onCropSubmit = (file, imgBase64) => {
    hideCropper();
    setFile(file);
    setImage(imgBase64);
  };

  // LOGO CROPPING
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!validateImage(file, 5)) return;

    setCropperData({
      visible: true,
      image: file,
      width: 1100,
      height: 400,
      filename: `background_img_${Math.floor(Math.random() * 10000000000)}`,
      onSubmit: onCropSubmit,
    });
  };

  return (
    <div className={cn(styles.container, className && className)}>
      <div className={styles.image}>
        <img src={image || "/images/default-bg-img.jpg"} alt="" />

        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          ref={inputRef}
        />
        {cropperData.visible && (
          <PhotoUploader
            onSubmit={cropperData.onSubmit}
            sourceImage={cropperData.image}
            width={cropperData.width}
            height={cropperData.height}
            filename={cropperData.filename}
          />
        )}
        <div className={styles.buttons}>
          <button className={cn("button", styles.button)} onClick={handleClick}>
            Prześlij zdjęcie
          </button>
          <button
            className={cn("button-stroke", styles.button, styles.button_filled)}
            onClick={removeImage}
          >
            Usuń
          </button>
        </div>
      </div>
    </div>
  );
};
export default BackgroundImageEditor;
