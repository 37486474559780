import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
  currentId: null,
};

export const jobsMapSlice = createSlice({
  name: "jobsMap",
  initialState: initialState,
  reducers: {
    showJobsMap: (state) => {
      state.visible = true;
    },
    hideJobsMap: (state) => {
      state.visible = false;
    },
    setCurrentId: (state, action) => {
      const { id } = action.payload;
      state.currentId = id;
    },
  },
});
