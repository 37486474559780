import React, { useState, useEffect } from "react";
import cn from "classnames";
import Card from "../Card";
import styles from "./TagFilter.module.sass";
import Scroll from "../Scroll";
import ModalTags from "./ModalTags";
import axios from "axios";
import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import devscntrNoauth from "api/Instance/devscntrNoauth";
import { tagsEndpoints } from "api/endpoints";
import Loader from "./Loader";

const LIMIT = 10;

const TagFilter = ({ selectedTags, setSelectedTags }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [tags, setTags] = useState([]);

  const compareNumbers = (a, b) => {
    return b.tags_count - a.tags_count;
  };

  const getTags = async (cancelToken) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.request({
        method: "get",
        url: `${tagsEndpoints.show}`,
        cancelToken: cancelToken.token,
      });
      const cats = response?.data?.sort(compareNumbers);
      setTags(cats);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getTags(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const toggleTag = (index) => {
    if (selectedTags?.includes(tags[index].name)) {
      setSelectedTags((prev) =>
        prev.filter((item) => item !== tags[index].name)
      );
    } else {
      setSelectedTags((prev) => [...prev, tags[index].name]);
    }
  };

  return (
    <Card
      title="Kategorie"
      classTitle="title-green"
      className={styles.card}
      oneLine={true}
      head={
        isLoading ? (
          <Loader cards={1} />
        ) : (
          <div className={styles.tags}>
            {tags?.map(
              (item, index) =>
                LIMIT > index && (
                  <button
                    className={cn(
                      styles.tag,
                      selectedTags?.includes(item.name) && styles.selected
                    )}
                    style={{ backgroundColor: item?.colour || "#ffffff" }}
                    key={`tag_${index}`}
                    onClick={() => toggleTag(index)}
                  >
                    {item?.name}
                  </button>
                )
            )}
            {tags?.length > LIMIT && (
              <button
                className={styles.show_all_btn}
                onClick={() => setModalVisible(true)}
              >
                ...
              </button>
            )}
          </div>
        )
      }
    >
      {tags?.length > LIMIT && (
        <ModalTags
          tags={tags}
          visible={modalVisible}
          setVisible={() => setModalVisible(false)}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        ></ModalTags>
      )}
    </Card>
  );
};
export default TagFilter;
