import React, { useEffect, useState } from "react";
import styles from "./Company.module.sass";
import cn from "classnames";
import Item from "../Item";
import Icon from "components/Icon";

import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import Editor from "components/Editor";
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";
import { validateImage, validateInput, validateUrl } from "utils/validation";
import PhoneNumberInput from "components/PhoneNumberInput";
import PhotoUploader from "components/PhotoUploader";
import Dropdown from "components/Dropdown";
import { companySize } from "data/dataArrays";
import devscntrAuth from "api/Instance/devscntrAuth";

const Company = ({ data }) => {
  const [companyLogo, setCompanyLogo] = useState();
  const [companyData, setCompanyData] = useState({
    companyName: data?.name || "",
    companyTaxNumber: data?.NIP || "",
    companyUrl: data?.website || "",
    companyEmail: data?.mail || "",
    companyPhone: data?.phone || "",
    companySize: companySize[companySize.indexOf(data?.size)] || companySize[0],
    onlyRemote: false,
  });
  const [phone, setPhone] = useState(data?.phone || "");
  const [companyLocations, setCompanyLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({
    locationCity: "",
    locationCode: "",
    locationStreet: "",
    locationAddress: "",
  });
  const [cropperData, setCropperData] = useState({
    visible: false,
    image: null,
    width: 0,
    height: 0,
    filename: "",
  });

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [file, setFile] = useState(null);

  useEffect(() => {
    console.log(companyLocations);
  }, [companyLocations]);

  useEffect(() => {
    setCompanyData({
      companyName: data?.name || "",
      companyTaxNumber: data?.NIP || "",
      companyUrl: data?.website || "",
      companyEmail: data?.mail || "",
      companyPhone: data?.phone || "",
      companySize:
        companySize[companySize.indexOf(data?.size)] || companySize[0],
      onlyRemote: false,
    });
    setPhone(data?.phone || "");
    if (data?.description) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(data?.description))
        ) || EditorState.createEmpty()
      );
    }
    setCompanyLogo(data?.logo || null);
    if (data?.locations?.length > 0) {
      setCompanyLocations(
        data.locations.map((item) => ({
          locationCity: item.city,
          locationCode: item.postcode,
          locationStreet: item.street,
          locationAddress: item.house_number,
        }))
      );
    }
  }, [data]);

  const handleRemoveImage = () => {};

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  // Handle inputs changes
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCompanyData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSizeChange = (value) => {
    setCompanyData((prev) => ({ ...prev, companySize: value }));
  };

  const handleLocationChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setCurrentLocation((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const removerLocation = (index) => {
    setCompanyLocations(companyLocations.filter((location, i) => i !== index));
  };

  const handleLocationAppend = (e) => {
    e.preventDefault();
    console.log(currentLocation);
    if (checkLocationFields()) {
      setCompanyLocations((prev) => [...prev, currentLocation]);
      setCurrentLocation({
        locationCity: "",
        locationCode: "",
        locationStreet: "",
        locationAddress: "",
      });
    }
  };

  const checkLocationFields = () => {
    if (!validateInput(currentLocation.locationCity, 40, 2, "Miasto"))
      return false;
    if (!validateInput(currentLocation.locationCode, 6, 6, "Kod pocztowy"))
      return false;
    if (!validateInput(currentLocation.locationStreet, 60, 2, "Ulica"))
      return false;
    if (!validateInput(currentLocation.locationAddress, 20, 1, "Numer budynku"))
      return false;

    return true;
  };

  const checkFields = () => {
    if (!validateInput(companyData.companyName, 255, 3, "Nazwa firmy"))
      return false;
    if (!validateInput(companyData.companyTaxNumber, 10, 10, "NIP"))
      return false;
    if (!validateUrl(companyData.companyUrl)) return false;
    if (!validateInput(companyData.companyEmail, 100, 3, "E-mail"))
      return false;

    return true;
  };

  const prepareFormData = () => {
    const formData = new FormData();

    // BASIC DATA
    formData.append("name", companyData.companyName.trim());
    formData.append("phone", phone.trim());
    formData.append("mail", companyData.companyEmail.trim());
    formData.append("website", companyData.companyUrl.trim());
    formData.append("NIP", companyData.companyTaxNumber.trim());
    formData.append("size", companyData.companySize);
    formData.append(
      "description",
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );

    // LOCATIONS
    if (companyLocations.length > 0) {
      companyLocations.forEach((item, index) => {
        formData.append(`locations[${index}]city`, item.locationCity);
        formData.append(`locations[${index}]postcode`, item.locationCode);
        formData.append(`locations[${index}]street`, item.locationStreet);
        formData.append(
          `locations[${index}]house_number`,
          item.locationAddress
        );
      });
    }

    // IMAGE
    if (file) {
      formData.append("logo", file);
    }

    return formData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (checkFields()) {
      const preparedData = prepareFormData();
      updateCompany(preparedData);
    }
  };

  const updateCompany = async (data) => {
    try {
      const response = await devscntrAuth.post(
        "/accounts/company/create/",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            method: "create",
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // Hide image cropper
  const hideCropper = () => {
    setCropperData((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  const onCropSubmit = (file, imgBase64) => {
    hideCropper();
    setFile(file);
    setCompanyLogo(imgBase64);
  };

  // LOGO CROPPING
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!validateImage(file, 5)) return;

    setCropperData({
      visible: true,
      image: file,
      width: 250,
      height: 200,
      filename: `company_logo_${Math.floor(Math.random() * 10000000000)}`,
      onSubmit: onCropSubmit,
    });
  };

  return (
    <Item
      className={cn(styles.card)}
      title="Twoja firma"
      classTitle="title-blue"
    >
      {/* {errMsg.length > 0 && (
        <div className={styles.error}>
          <Icon name="info" size="24" />
          {errMsg}
        </div>
      )} */}
      <h3 className={styles.heading}>Informacje podstawowe</h3>
      <div className={styles.profile}>
        <div className={styles.avatar}>
          <img src={companyLogo || "/images/default-logo.png"} alt="Logo" />
          <button className={styles.remove} onClick={handleRemoveImage}>
            <Icon name="close" />
          </button>
        </div>
        <div className={styles.file}>
          <input type="file" onChange={handleFileChange} />
          {cropperData.visible && (
            <PhotoUploader
              onSubmit={cropperData.onSubmit}
              sourceImage={cropperData.image}
              width={cropperData.width}
              height={cropperData.height}
              filename={cropperData.filename}
            />
          )}
          <button className={cn("button", styles.button)} type="button">
            <Icon name="add" size="24" />
            <span>Prześlij logo</span>
          </button>
        </div>
        <button
          className={cn("button-stroke", styles.button)}
          onClick={handleRemoveImage}
        >
          Usuń
        </button>
      </div>
      <div className={styles.basic}>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              label="Nazwa firmy"
              name="companyName"
              type="text"
              tooltip="Nazwa twojej firmy"
              value={companyData.companyName}
              onChange={handleChange}
            />
          </div>
          <div className={styles.col}>
            <Dropdown
              label="Liczba pracowników"
              value={companyData.companySize}
              setValue={handleSizeChange}
              options={companySize}
              classDropdownHead="Liczba pracowników"
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              label="Numer NIP"
              name="companyTaxNumber"
              type="text"
              tooltip="Numer identyfikacji podatkowej twojej firmy"
              value={companyData.companyTaxNumber}
              onChange={handleChange}
            />
          </div>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              label="Strona internetowa (adres URL)"
              name="companyUrl"
              type="text"
              tooltip="Wpisz link do strony internetowej Twojej firmy"
              value={companyData.companyUrl}
              onChange={handleChange}
            />
          </div>
        </div>
        <Checkbox
          className={styles.field}
          content={"Działamy zdalnie"}
          value={companyData.onlyRemote}
          onChange={() =>
            setCompanyData((prev) => ({
              ...prev,
              onlyRemote: !prev.onlyRemote,
            }))
          }
        />
        <Editor
          editorState={editorState}
          onEditorChange={handleEditorStateChange}
          classEditor={styles.field}
          label="O firmie"
          tooltip="Opisz działaność swojeje firmy"
        />
      </div>
      <div className={styles.contact}>
        <h3 className={styles.heading}>Dane kontaktowe</h3>
        <div className={styles.row}>
          <div className={styles.col}>
            <TextInput
              className={styles.field}
              label="Adres email"
              name="companyEmail"
              type="email"
              tooltip="Adres e-mail kontaktowy firmy"
              value={companyData.companyEmail}
              onChange={handleChange}
            />
          </div>
          <div className={styles.col}>
            {/* <TextInput
              className={styles.field}
              label="Telefon kontaktowy"
              name="companyPhone"
              type="text"
              tooltip="Telefon kontaktowy firmy"
              value={companyData.companyPhone}
              onChange={handleChange}
            /> */}
            <PhoneNumberInput
              className={styles.field}
              label="Numer telefonu"
              name="phone"
              type="text"
              tooltip="Wpisz numer telefonu firmy, wybierz kraj"
              value={phone}
              onChange={setPhone}
            />
          </div>
        </div>
      </div>
      <div className={styles.locations}>
        <div className={styles.locations_list}>
          <h3 className={styles.heading}>Lokalizacje firmy</h3>
          {companyLocations?.length > 0 ? (
            <ul>
              {companyLocations.map((x, index) => (
                <div className={styles.locaton_item} key={index}>
                  <div className={styles.location_item_data}>
                    <span style={{ marginRight: "16px" }}>{index + 1}.</span>
                    ul. {x.locationStreet} {x.locationAddress}, {x.locationCity}{" "}
                    {x.locationCode}
                  </div>
                  <button
                    className={styles.location_item_remove}
                    onClick={() => removerLocation(index)}
                  >
                    <Icon name="close" size={22} />
                  </button>
                </div>
              ))}
            </ul>
          ) : (
            <p>Nie masz żadnych dodanych lokalizacji</p>
          )}
        </div>
        <div className={styles.add_location}>
          <h3 className={styles.heading}>Dodaj lokalizację</h3>
          <div className={styles.row}>
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                label="Miasto"
                name="locationCity"
                type="text"
                tooltip="Miasto, w którym działa firma"
                value={currentLocation.locationCity}
                onChange={handleLocationChange}
              />
              <TextInput
                className={styles.field}
                label="Kod pocztowy"
                name="locationCode"
                type="text"
                tooltip="Kod pocztowy miasta"
                value={currentLocation.locationCode}
                onChange={handleLocationChange}
              />
            </div>
            <div className={styles.col}>
              <TextInput
                className={styles.field}
                label="Ulica"
                name="locationStreet"
                type="text"
                tooltip="Ulica"
                value={currentLocation.locationStreet}
                onChange={handleLocationChange}
              />
              <TextInput
                className={styles.field}
                label="Numer budynku"
                name="locationAddress"
                type="text"
                tooltip="Numer budynku"
                value={currentLocation.locationAddress}
                onChange={handleLocationChange}
              />
            </div>
          </div>
          <button
            className={cn("button-stroke")}
            onClick={handleLocationAppend}
          >
            Dodaj lokalizację
          </button>
        </div>
      </div>
      <button
        className={cn("button", styles.submit_button)}
        onClick={handleSubmit}
      >
        Zapisz zmiany
      </button>
    </Item>
  );
};

export default Company;
