import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Comments.module.sass";
import Item from "./Item";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import axios from "axios";

import useAuth from "../../../../hooks/useAuth";
import devscntrAuth from "../../../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../../../api/Instance/devscntrNoauth";
import { validateInput } from "../../../../utils/validation";
import Comment from "components/Comment";
import { showLoginModal } from "utils/showLoginModal";

const Comments = ({ className, article }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [content, setContent] = useState("");
  const [comments, setComments] = useState([]);
  const [replied, setReplied] = useState(false);

  const getComments = (cancelToken) => {
    //console.log(article);
    axiosInstance
      .get("/posts/article/comment-anon", {
        cancelToken: cancelToken.token,
        headers: {
          "POST-ID": article?.id,
        },
      })
      .then((response) => {
        const sortedComments =
          response?.data?.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          ) || [];
        setComments(sortedComments);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log("post comments canceled");
        } else {
          console.log(error);
        }
      });
  };

  const onComment = () => {
    setReplied((prev) => !prev);
  };

  const sendComment = () => {
    devscntrAuth
      .post("/posts/article/comment/", {
        text: content,
        postID: article.id,
      })
      .then(() => {
        setContent("");
        onComment();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    if (!validateInput(content, 255)) return;
    sendComment();
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (article) getComments(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [article, replied]);

  return (
    <>
      <div className={cn(styles.comments, className)}>
        <div className={styles.head}>
          <div className={styles.title}>
            <div className={styles.counter}>
              {comments?.length > 0 ? comments?.length : "0"}
            </div>
            Komentarze
          </div>
        </div>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className={styles.editor}
          placeholder="Podziel się swoją opinią..."
        />
        <button className={cn("button", styles.add_btn)} onClick={handleSubmit}>
          Wyślij
        </button>
        <div className={styles.list}>
          {comments?.map((x, index) => (
            <Comment
              key={index}
              className={styles.item}
              item={x}
              onComment={onComment}
              onReply={setReplied}
              type="post"
            />
          ))}
        </div>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Comments;
