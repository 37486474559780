import React, { useState } from "react";
import { useParams } from "react-router";
import cn from "classnames";
import styles from "./Drafts.module.sass";
import Card from "components/Card";
import Form from "components/Form";
import Table from "../Table";
import Product from "components/Product";
import Panel from "../Panel";
import Filters from "components/Filters";
import Dropdown from "components/Dropdown";
import Loader from "../Loader";

import { publications } from "mocks/publications";

import devscntrAuth from "api/Instance/devscntrAuth";

export default function Drafts() {
  const sortOptions = [
    "Nazwa (A-Z)",
    "Nazwa (Z-A)",
    "Najnowsze",
    "Najstarsze",
    "Status",
    "Wyświetlenia (malejąco)",
    "Wyświetlenia (rosnąco)",
    "Lead-y (malejąco)",
    "Lead-y (rosnąco)",
    "Polubienia (malejąco)",
    "Polubienia (rosnąco)",
    "Ocena (malejąco)",
    "Ocena (rosnąco)",
  ];

  const [search, setSearch] = useState("");
  const [itemsSelectedQuantity, setItemsSelectedQuantity] = useState(0);
  const [sortingValue, setSortingValue] = useState(sortOptions[0]);
  const [isLoading, setIsLoading] = useState(false); // ustawianie animacji w oczekiwaniu
  const { id } = useParams();

  // const authCtx = useAuth();
  // const isLoggedIn = authCtx.isLoggedIn;
  // const isAuthor = authCtx.isAuthor;

  // const getPublications = async (cancelToken) => {
  //   setIsLoading(true);

  //   // const params = `?page=${currentPage}${tags.length > 0 ? `&tags=${tags.join("%26")}` : ""
  //   //   }&page_size=${postsPerPage}`;
  //   // const applyFilters = getFilterString();

  //   try {
  //     // const response = await devscntrNoauth.request({
  //     //   method: "get",
  //     //   url: `/jobs/show-job/${params}&sortby=${sortMethod}${applyFilters}`,
  //     //   cancelToken: cancelToken.token,
  //     // });
  //     const response = await devscntrAuth.request({
  //       method: 'get',
  //       url: `/accounts/user-pins/`,
  //     });
  //     console.log(`Załadowano ${currentPage} strone`);
  //     const loadedArticles = response.data.results.map((item, index) => (
  //       <MiniJobOffer article={item} key={index} />
  //     ));

  //     if (jobsList.length === 0) setJobsList(loadedArticles);
  //     else setJobsList((prevState) => [...prevState, ...loadedArticles]);

  //     setIsLoading(false);
  //     document.addEventListener("scroll", trackScrolling);
  //     if (response.data.next == null) setIsLastPage(true);
  //   } catch (error) {
  //     if (axios.isCancel(error)) {
  //       console.log("jobs canceled");
  //     } else {
  //       console.log(error);
  //     }
  //   }
  // };

  return (
    <>
      <div className={styles.options}>
        <Dropdown
          className={styles.dropdown}
          classDropdownHead={styles.dropdownHead}
          value={sortingValue}
          setValue={setSortingValue}
          options={sortOptions}
        />
        <Filters />
      </div>
      <Card
        className={styles.card}
        classCardHead={styles.head}
        classTitle={cn("title-purple", styles.title)}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              // onSubmit={() => handleSubmit()}
              placeholder="Szukaj..."
              type="text"
              name="search"
              icon="search"
            />
          </>
        }
      >
        <div className={styles.wrapper}>
          {!isLoading && (
            <Table
              items={publications}
              setItemsSelectedQuantity={setItemsSelectedQuantity}
            />
          )}
          {isLoading && <Loader cards={5} />}
        </div>
      </Card>
      {/* <Panel
        quantity={itemsSelectedQuantity}
        disabled={!itemsSelectedQuantity}
      /> */}
    </>
  );
}
