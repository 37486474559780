import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";
import MoreCustomers from "../../screens/Home/MoreCustomers";
import { BsLaptop } from "react-icons/bs";
import useAuth from "hooks/useAuth";
import { socialMedia } from "data/dataArrays";

const Sidebar = ({ className, visible, setVisible, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const authCtx = useAuth();

  const navigation = [
    {
      title: "Strumień",
      icon: "home",
      url: "/home",
    },
    authCtx.isLoggedIn && {
      title: "Przypinki",
      icon: "heart",
      // url: "/pins",
      add: true,
      addgroups: true,

      dropdown: [
        {
          title: "Moje przypinki",
          url: "/",
          groups: true,
          // mapowanie nowo dodandych grup
        },
      ],
    },
    authCtx.isLoggedIn && {
      title: "Subskrypcje",
      icon: "profile-circle",
      dropdown: [
        {
          title: "Pokaż wszystkie",
          url: "/subscriptions",
          // counter: "2",
          // colorCounter: "#FFBC99",
          subs: true,
        },
      ],
    },
    {
      title: "Kursy",
      icon: "store",
      slug: "kursy",
      url: "/courses",
    },
    {
      title: "Oferty pracy",
      icon: "search",
      slug: "praca",
      url: "/jobs",
    },
    // {
    //   title: "Jak zacząć w IT",
    //   icon: "desktop",
    //   slug: "IT",
    //   url: "/jzwit",
    // },
  ];
  //const [visible, setVisible] = useState(false);

  return (
    <nav
      className={cn(styles.sidebar, className, { [styles.active]: visible })}
    >
      <button className={styles.close} onClick={onClose}>
        <Icon name="close" size="24" />
      </button>
      <Link className={styles.logo} to="/home">
        <Image
          className={styles.pic}
          src="/images/logo-dark.svg"
          srcDark="/images/logo-light.svg"
          alt="DevsCNTR"
        />
        {/* <h1 className={styles.logotitle}>evsCNTR</h1> */}
      </Link>
      <ul className={styles.menu}>
        {navigation.map(
          (x, index) =>
            x.title &&
            (x.url ? (
              <li key={index}>
                <NavLink
                  className={styles.item}
                  activeClassName={styles.active}
                  to={x.url}
                  exact
                >
                  <Icon name={x.icon} size="24" />
                  {x.title}
                </NavLink>
              </li>
            ) : (
              <li key={index}>
                <Dropdown
                  className={styles.dropdown}
                  visibleSidebar={visible}
                  setValue={setVisible}
                  key={index}
                  item={x}
                  onClose={onClose}
                />
              </li>
            ))
        )}
      </ul>
      <section className={styles.socialmedia}>
        {socialMedia.map((item, index) => (
          <a
            href={item.url}
            target="_blank"
            className={styles.link}
            key={`social_media_${index}`}
          >
            <Icon name={item.icon} size="24" />
          </a>
        ))}
      </section>

      <div className={styles.foot}>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          {/* <Icon name={visible ? "arrow-left" : "arrow-right"} size="24" /> */}
          <div className={styles.burger}></div>
          Zwiń menu
        </button>
        <Theme className={styles.theme} visibleSidebar={visible} />
      </div>
    </nav>
  );
};

export default Sidebar;
