import { messageSlice } from "./slice";

export default messageSlice.reducer;
export const messageActions = messageSlice.actions;
export const showMessage = (message, type) => (dispatch) => {
  return dispatch(messageActions.showMessage({ message, type }));
};
export const removeMessage = () => (dispatch) => {
  dispatch(messageActions.clearMessage());
};
