import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "../Item.module.sass";
import Icon from "../../../../Icon";
import useAxiosFunction from "../../../../../hooks/useAxiosFunction";
import axios from "axios";
import api from "../../../../../api/devscntr";
import Control from "./Control";

import useAuth from "../../../../../hooks/useAuth";
import devscntrAuth from "../../../../../api/Instance/devscntrAuth";

const ItemComments = ({ className, item, onClose, type }) => {
  const [visible, setVisible] = useState(false);
  // Controlled input's value in reply
  const [currentValue, setCurrentValue] = useState("");
  const [replied, setReplied] = useState(false);

  const authCtx = useAuth();

  const date = new Date(item.created_at);

  const setAsRead = () => {
    devscntrAuth.delete(`/accounts/me/notification/${item.id}/`);
  };

  const sendReply = () => {
    devscntrAuth
      .post("/posts/article/comment/", {
        text: currentValue,
        commentID: item.id,
      })
      .then(() => {
        setCurrentValue("");
        setReplied(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={cn(styles.item, styles.new, className)}>
      <div className={styles.avatar}>
        {item.user_image && (
          <>
            <img
              src={
                item.user_image
                  ? item.user_image
                  : "/images/default-avatar.png"
              }
              alt="Avatar"
            />
            <div className={styles.icon}>
              <Icon name="list" />
            </div>
          </>
        )}
      </div>
      <div className={styles.details}>
        <div className={styles.line}>
          <div className={styles.subtitle}>
            {item.user_displayname}
            <span> skomentował </span>
            <Link
              className={styles.link}
              to={
                type === "commentPost"
                  ? `/post/${item.post_id}`
                  : `/course/${item.course_id}`
              }
            >
              {type === "commentPost" ? "Twój post" : "Twój kurs"}
            </Link>
          </div>
          <div className={styles.time}>{date.toLocaleString()}</div>
        </div>
        <div className={styles.content}>"{item.text}"</div>
        <Control
          className={styles.control}
          value={visible}
          setValue={setVisible}
          valueAnswer={currentValue}
          setValueAnswer={setCurrentValue}
          sendReplyHandler={sendReply}
          isReplied={replied}
          userImg={
            authCtx.userImg != undefined
              ? authCtx.userImg
              : "/images/default-avatar.png"
          }
          comment={item}
          authCtx={authCtx}
        />
      </div>
    </div>
  );
};

export default ItemComments;
