import React from "react";
import styles from "./ModalTags.module.sass";
import cn from "classnames";
import Modal from "components/Modal";

const ModalTags = ({
  tags,
  visible,
  setVisible,
  selectedTags,
  setSelectedTags,
}) => {
  const toggleTag = (index) => {
    if (selectedTags?.includes(tags[index].name)) {
      setSelectedTags((prev) =>
        prev.filter((item) => item !== tags[index].name)
      );
    } else {
      setSelectedTags((prev) => [...prev, tags[index].name]);
    }
  };

  return (
    <Modal outerClassName={styles.test} visible={visible} onClose={setVisible}>
      <div className={styles.title}>Kategorie</div>
      <div className={styles.tags}>
        {tags?.map((item, index) => (
          <button
            className={cn(
              styles.tag,
              selectedTags?.includes(item.name) && styles.selected
            )}
            style={{ backgroundColor: item?.colour || "#ffffff" }}
            key={`tag_${index}`}
            onClick={() => toggleTag(index)}
          >
            {item?.name}
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default ModalTags;
