import React from "react";
import styles from "./Breadcrumbs.module.sass";


import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Icon from "../Icon";

const Breadcrumbs = props => {
  const {
    history,
    location: { pathname }
  } = props;
  const pathnames = pathname.slice(props.slice).split("/").filter(x => x);
  return (
<div className={styles.Breadcrumb}>
      <MUIBreadcrumbs aria-label="breadcrumb" color="initial">
      {pathnames ? (
        <Link onClick={() => history.push(props.history_push)} className={styles.JZWIT} ><Icon name={props.icon_name} size={24} className={styles.JZWITIcon}/>
        {props.headline}</Link>
      ) : (
        <Typography> Home </Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography key={name} className={styles.last}>{name}</Typography>
        ) : (
          <Link key={name} onClick={() => history.push(routeTo)}>
            {name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
</div>


  );
};

export default withRouter(Breadcrumbs);
