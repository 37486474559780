import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
  id: null,
};

export const tagInfoSlice = createSlice({
  name: "tagInfo",
  initialState: initialState,
  reducers: {
    showTagInfo: (state, action) => {
      state.visible = true;
      const { id } = action.payload;
      state.id = id;
    },
    clearTagInfo: (state) => {
      state.visible = false;
      //state.id = null;
    },
  },
});
