import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Search.module.sass";
import Icon from "../../Icon";
import Item from "./Item";
import ModalProduct from "../../../components/ModalProduct";
import { useHistory } from "react-router-dom";
import { validateInput } from "../../../utils/validation";
import axios from "axios";
import { miscEndpoints } from "api/endpoints";

import useAuth from "../../../hooks/useAuth";
import devscntrAuth from "../../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../../api/Instance/devscntrNoauth";
import SkeletonLoader from "./Loader";

const MAX_RESULTS = 10;

const Search = ({ className }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let searchDelay;

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (isMounted) {
      if (search.length > 0) {
        searchDelay = setTimeout(() => getSearchResults(cancelToken), 500);
        setIsLoading(true);
      } else {
        setResults([]);
      }
    } else {
      setIsMounted(true);
    }

    return () => {
      cancelToken.cancel();
    };
  }, [search]);

  const getSearchResults = async (cancelToken) => {
    //setIsLoading(true);
    setResults([]);
    try {
      const response = await axiosInstance.request({
        method: "get",
        url: `${miscEndpoints.search}?query=${search}&page=1&limit=${MAX_RESULTS}`,
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      setResults(response?.data.results);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("searchbar canceled");
      } else {
        console.log(error);
      }
    }
  };

  const handleChange = (e) => {
    //setVisible(true);
    clearTimeout(searchDelay);
    setSearch(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.key !== "Enter") return;
    handleSubmit();
  };

  const handleSubmit = () => {
    if (!validateInput(search, 255)) return;
    setVisible(false);
    history.push({
      pathname: `/search/${search}`,
      //search: `?query=${search}`,
    });
  };

  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name="search" size="24" />
          </button>
          <button className={styles.direction}>
            <Icon name="arrow-left" size="24" />
          </button>
          <input
            className={styles.input}
            type="text"
            placeholder="Szukaj..."
            value={search}
            onFocus={() => setVisible(true)}
            onBlur={() => setVisible(false)}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
          <button className={styles.result} onClick={handleSubmit}>
            Szukaj
          </button>
          <button className={styles.close} onClick={() => setVisible(false)}>
            <Icon name="close-circle" size="24" />
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.box}>
            <div className={styles.category}>
              {search?.length > 0 && `Wyniki wyszukiwania dla "${search}"`}
            </div>
            {/* <div className={styles.category}>Posty</div> */}
            <div className={styles.list}>
              {results?.length > 0 ? (
                results.map(
                  (x, index) =>
                    MAX_RESULTS > index && (
                      <Item className={styles.item} item={x} key={index} />
                    )
                )
              ) : search.length === 0 ? (
                <div className={styles.no_results}>
                  Daj znać czego szukasz...
                </div>
              ) : isLoading ? (
                <SkeletonLoader cards={1} />
              ) : (
                results?.length === 0 && (
                  <div className={styles.no_results}>Brak wyników</div>
                )
              )}
            </div>
            {results?.length > 0 && (
              <div className={styles.more_results} onClick={handleSubmit}>
                Zobacz wszystkie
              </div>
            )}
          </div>
          {/* <div className={styles.box}>
            <div className={styles.category}>Kursy</div>
            <div className={styles.list}>
              {result.map((x, index) => (
                <ItemPost
                  className={styles.item}
                  item={x}
                  key={index}
                  onClick={() => setVisibleModalProduct(true)}
                />
              ))}
            </div>
          </div>
          <div className={styles.more_results}>Zobacz wszystkie</div> */}
          {/* <div className={styles.box}>
            <div className={styles.category}>Suggestions</div>
            <div className={styles.list}>
              {suggestions.map((x, index) => (
                <Suggestion
                  className={styles.item}
                  item={x}
                  key={index}
                  onClick={() => setVisibleModalProduct(true)}
                />
              ))}
            </div>
          </div> */}
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Search;
