import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Pins from "./screens/Pins";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import NewPost from "./screens/NewPost";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import Courses from "./screens/Courses";
import CustomerList from "./screens/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Shop from "./screens/Shop";
import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import ProfilePage from "./screens/ProfilePage";
import PageList from "./screens/PageList";
import Login from "./Login";
import Subscriptions from "./screens/Subscriptions";
import Logout from "./components/Logout";
import AuthContext from "./context/AuthContext";
import Post from "./screens/Post";
import Course from "./screens/Course";
import NewCourse from "./screens/NewCourse";
import JobOffers from "./screens/JobOffers";
import Search from "./screens/Search";
import Modal from "./components/Modal";
import NewJob from "./screens/NewJob";
import JobOffer from "./screens/JobOffer";
import ModalMessage from "./components/ModalMessage";
import JZWIT from "./screens/JakZaczacWIt";
import TechnologyDetail from "./screens/JakZaczacWIt/TechnologyDetail";
import Jobs from "./screens/Jobs";
import Publications from "./screens/Publications";
import ModalGoogleMap from "components/ModalGoogleMap";
import NewTechnology from "./screens/NewTechnology";
import Admin from "screens/Admin";
import ModalLogin from "components/ModalLogin";
import Users from "screens/Admin/Users";
import ModalShare from "components/ModalShare";
import EditJob from "screens/NewJob/edit";
import EditPost from "screens/NewPost/edit";
import AcitvateAccount from "screens/SignUp/ActivateAccount";

function App() {
  // Authorization context
  const authCtx = useContext(AuthContext);
  //const messageCtx = useContext(MessageContext);
  // get isLoggedIn from context (true/false)
  const isLoggedIn = authCtx.isLoggedIn;
  // get user info from context
  const isAuthor = authCtx.isAuthor;
  const isAdmin = authCtx.isAdmin;
  const isSuperuser = authCtx.isSuperuser;

  // Return routes, protected routes (if user is not logged in, redirect to sign-in)
  return (
    <>
      <ModalMessage />
      <Router>
        <ModalLogin />
        <ModalShare />
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/courses">
            <Page title="">
              <Courses />
            </Page>
          </Route>
          <Route exact path="/course/:id">
            <Page title="">
              <Course />
            </Page>
          </Route>
          <Route exact path="/home">
            <Page title="">
              <Home />
            </Page>
          </Route>
          <Route exact path="/search/:search">
            <Page title="">
              <Search />
            </Page>
          </Route>
          <Route exact path="/profile/:id">
            <Page title="">
              <ProfilePage />
            </Page>
          </Route>
          <Route exact path="/post/:id">
            <Page title="">
              <Post />
            </Page>
          </Route>
          <Route exact path="/pins">
            {isLoggedIn && (
              <Page title="">
                <Pins />
              </Page>
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route>{" "}
          <Route exact path="/pins/:name">
            {isLoggedIn && (
              <Page title="">
                <Pins />
              </Page>
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route>
          <Route exact path="/subscriptions">
            {isLoggedIn && (
              <Page title="">
                <Subscriptions />
              </Page>
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route>
          {/* ------------------- JZWIT --------------------- */}
          {/* <Route exact path="/jzwit/">
            <Page title="">
              <JZWIT />
            </Page>
          </Route>
          <Route path="/jzwit/:idTech">
            <Page title="">
              <JZWIT />
            </Page>
          </Route>
          <Route exact path="/tech-detail/">
            <Page title="">
              <TechnologyDetail />
            </Page>
          </Route>
          <Route exact path="/tech-detail/:idTech">
            <Page title="">
              <TechnologyDetail />
            </Page>
          </Route>
          <Route exact path="/new-technology">
            {isAuthor ? (
              <Page title="Nowa Technologia" newElement={true}>
                <NewTechnology />
              </Page>
            ) : (
              <Redirect to="/home" />
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route> */}
          {/* ----------------------------------------------------------- */}
          <Route exact path={["/jobs", "/jobs/:id"]}>
            <Page title="">
              <Jobs />
            </Page>
          </Route>
          {/* <Route exact path="/publications/:id">
            {isAuthor ? (
              <Page title="">
                <Publications />
              </Page>
            ) : (
              <Redirect to="/home" />
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route> */}
          <Route exact path="/new-course">
            {isAuthor ? (
              <Page title="Dodaj nowy kurs" newElement={true}>
                <NewCourse />
              </Page>
            ) : (
              <Redirect to="/home" />
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route>
          <Route exact path="/new-job">
            {isAuthor ? (
              <Page title="Nowa oferta pracy" newElement={true}>
                <NewJob />
              </Page>
            ) : (
              <Redirect to="/home" />
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route>
          <Route exact path="/edit-job/:id">
            {isAuthor ? (
              <Page title="Edytuj ofertę pracy" newElement={true}>
                <EditJob />
              </Page>
            ) : (
              <Redirect to="/home" />
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
            {/* <Page title="Edytuj ofertę pracy" newElement={true}>
              <EditJob />
            </Page> */}
          </Route>
          <Route exact path="/new-post">
            {isAuthor ? (
              <Page title="Nowy Post" newElement={true}>
                <NewPost />
              </Page>
            ) : (
              <Redirect to="/home" />
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route>
          <Route exact path="/edit-post/:id">
            {isAuthor ? (
              <Page title="Edytuj ofertę pracy" newElement={true}>
                <EditJob />
              </Page>
            ) : (
              <Redirect to="/home" />
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
            {/* <Page title="Edytuj ofertę pracy" newElement={true}>
              <EditPost />
            </Page> */}
          </Route>
          <Route path="/settings">
            {isLoggedIn && (
              <Page title="Ustawienia">
                <Settings />
              </Page>
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route>
          <Route path="/notification">
            {isLoggedIn && (
              <Page title="Powiadomienia">
                <Notification />
              </Page>
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route>
          <Route path="/logout">
            {isLoggedIn && <Logout />}
            {!isLoggedIn && <Redirect to="/sign-in" />}
          </Route>
          <Route path="/sign-in">
            {!isLoggedIn && <SignIn />}
            {isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/sign-up">
            {!isLoggedIn && <SignUp />}
            {isLoggedIn && <Redirect to="/" />}
          </Route>
          <Route path="/activate-account/:uidb64/:token">
            <AcitvateAccount />
          </Route>
          <Route path="/admin">
            {isAdmin ? (
              <Page title="">
                <Admin />
              </Page>
            ) : (
              <Redirect to="/home" />
            )}
            {!isLoggedIn && <Redirect to="/sign-in" />}
            {/* <Page title="">
              <Admin></Admin>
            </Page> */}
          </Route>
          {
            // Handle not defined routes
          }
          <Route path="*">
            <Redirect to="/home" />
            {/* {!isLoggedIn && <SignIn />}
            {isLoggedIn && <Redirect to="/home" />} */}
          </Route>
          {/* <Route exact path="/sign-up" render={() => <SignUp />} />
            <Route exact path="/sign-in" render={() => <SignIn />} />
            <Route exact path="/pagelist" component={PageList} />
            <Route exact path="*" render={() => <Redirect to="/sign-in" />}/> */}
        </Switch>
      </Router>
    </>
  );
}

export default App;
