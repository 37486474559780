import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../components/Icon";
import Overview from "./Overview";
import Comments from "./Comments";
import axios from "axios";
import AuthContext from "../../../context/AuthContext";
import { useLocation, useParams } from "react-router";
import ModalPin from "../../../components/ModalPin";
import useAuth from "../../../hooks/useAuth";
import devscntrAuth from "../../../api/Instance/devscntrAuth";
import { showLoginModal } from "utils/showLoginModal";

const Details = ({
  className,
  setValue,
  activeIndex,
  setActiveIndex,
  article,
  fetchArticle,
}) => {
  const authCtx = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState("");
  const [pinState, setPinState] = useState(article?.pinned || false);
  const [currentFollows, setCurrentFollows] = useState();
  const [isFollowed, setIsFollowed] = useState(false);

  useEffect(() => {
    if (currentFollows) setIsFollowed(currentFollows.includes(article.author));
    if (!currentFollows && authCtx.isLoggedIn) {
      setIsLoading(true);
      fetchFollows(authCtx.userId);
    }
  }, [authCtx, currentFollows, isFollowed]);

  const fetchFollows = (id) => {
    devscntrAuth
      .get("/accounts/me/follow-user/")
      .then(function (response) {
        let followings = response.data.map((item) => item.id);
        setCurrentFollows(followings);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const triggerFollowUser = (userID) => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    if (isFollowed)
      setCurrentFollows((prevState) => prevState.filter((x) => x !== userID));
    else setCurrentFollows((prevState) => [...currentFollows, userID]);
    const headers = {
      METHOD: isFollowed ? "unfollow" : "follow",
    };
    devscntrAuth
      .post(
        "/accounts/me/follow-user/",
        { id: article.author },
        { headers: { ...headers } }
      )
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleClick = (index) => {
    setActiveIndex(index);
    index === 0 && setValue(false);
    index === 1 && setValue(true);
  };

  // -----------------------------------------------------
  // <----- !!!! PRZYPINKI !!!! ----->
  // -----------------------------------------------------
  const [StarModalVisible, setStarModalVisible] = useState();
  const location = useLocation();
  // <----- WALIDACJA ODPINANIA ----->
  const pinsPath = location.pathname.slice(0, 5);
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (pinsPath === "/pins") {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };
  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }
    setStarModalVisible(true);
  };
  // -----------------------------------------------------
  // -----------------------------------------------------

  useEffect(() => {
    setPinState(article.pinned);
    if (pinState !== null) setPinState(article?.pinned);
  }, [article?.pinned]);
  return (
    <div className={cn(styles.details, className)}>
      {isLoading ? (
        <p className={cn("loading")}>
          <Icon name="repeat" size="24" /> Loading...
        </p>
      ) : (
        <>
          {/* <----- !!!! MODALPIN !!!! -----> */}
          {(StarModalVisible || redirectedUser || unpinPost) && (
            <ModalPin
              visible={StarModalVisible}
              onClose={() => setStarModalVisible(false)}
              article={article}
              setPinState={setPinState}
              redirectedUser={redirectedUser}
              setRedirectedUser={setRedirectedUser}
              unpinPost={unpinPost}
              setunpinPost={setunpinPost}
              pinUrl={"/posts/article/user-post/"}
              idName={"post_id"}
            />
          )}

          <div className={styles.head}>
            <div className={styles.btns}>
              <button
                className={cn(styles.favorite)}
                onClick={pinState ? unpinHandler : pinHandler}
              >
                <img
                  src={
                    pinState
                      ? "/images/Icons/heart/filled-color.svg"
                      : "/images/Icons/heart/stroke.svg"
                  }
                  alt="favourite"
                />
              </button>
              {authCtx.userID !== article.author && (
                <button
                  className={cn(
                    "button" + (isFollowed && "-stroke"),
                    styles.follow
                  )}
                  onClick={() => triggerFollowUser(article.author)}
                >
                  {!isFollowed ? (
                    <span className={styles.inner}>Obserwuj</span>
                  ) : (
                    <span className={styles.inner}>Obserwujesz</span>
                  )}
                </button>
              )}
            </div>
          </div>
          <Overview article={article} fetchArticle={fetchArticle} />
          <Comments article={article} />
        </>
      )}
    </div>
  );
};

export default Details;
