import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import Icon from "../../../../components/Icon";
import ModalPreview from "../../../../components/ModalPreview";
import { convertFromRaw, EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { Link } from "react-router-dom";
import StarRating from "../StarRating";
import Map from "components/Map";
import TagsList from "screens/DetailsLayout/TagsList";

const gallery = [
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
];

const format = ["artykuł", "wideo", "online", "stacjonarny"];
const difficulty = ["początkujący", "średnio zaawansowany", "ekspert"];
const language = ["PL", "ENG"];

const Overview = ({ article, author }) => {
  const [visibleModalPreview, setVisibleModalPreview] = useState(false);
  const [description, setDescription] = useState(article.content);
  const [authorBio, setAuthorBio] = useState(author.user.bio);
  console.log(author);

  useEffect(() => {
    if (article.content) {
      let fixedDescription = EditorState.createWithContent(
        convertFromRaw(JSON.parse(article.content))
      );
      setDescription(convertToHTML(fixedDescription.getCurrentContent()));
    }
  }, [article, description]);

  useEffect(() => {
    if (author.user.bio) {
      let fixedBio = EditorState.createWithContent(
        convertFromRaw(JSON.parse(author.user.bio))
      );
      setAuthorBio(convertToHTML(fixedBio.getCurrentContent()));
    }
  }, [author]);

  return (
    <>
      <div className={styles.overview}>
        <section className={styles.postmoreinfo}>
          <Link className={styles.author} to={`/profile/${article.author}`}>
            <div className={styles.authorimage}>
              <img
                src={
                  author.user.image
                    ? author.user.image
                    : "/images/default-avatar.png"
                }
                alt=""
              />
            </div>
            <p>{article.display_name && article.display_name}</p>
          </Link>
          <StarRating article={article} />
        </section>
        <div className={styles.article_header}>
          <div className={styles.preview}>
            <img
              src={
                article.image ? article.image : "/images/image-placeholder.png"
              }
              alt="Course Image"
            />
          </div>
          <div className={cn("h4", styles.header_content)}>
            <div className={styles.title}>{article.title}</div>
            {article.reflink && (
              <a
                href={article.reflink}
                target="_blank"
                className={cn("button", styles.header_button)}
              >
                Przejdź do kursu
              </a>
            )}
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>Opis</div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-green", styles.subtitle)}>Szczegóły</div>
            <div className={styles.course_details}>
              <ul>
                <li className={styles.price}>
                  <b>Cena:</b>{" "}
                  {article.specialprice ? (
                    <>
                      <s>{article.price}zł</s> {article.specialprice}zł
                    </>
                  ) : article.price ? (
                    `${article.price}zł`
                  ) : (
                    "za darmo"
                  )}
                </li>
                <li>
                  <b>Czas trwania:</b> {article.duration}h
                </li>
                <li>
                  <b>Poziom:</b> {difficulty[article.difficulty - 1]}
                </li>
                <li>
                  <b>Format:</b> {format[article.format - 1]}
                </li>
                <li>
                  <b>Język:</b> {language[article.language - 1]}
                </li>
                <li>
                  <b>Certyfikat ukończenia:</b>{" "}
                  {article.certificate ? "tak" : "nie"}
                </li>
              </ul>
            </div>
          </div>
        </div>

        {article?.localization.length > 0 && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={cn("title-yellow", styles.subtitle)}>
                Lokalizacje kursu
              </div>
              <ul className={styles.locations_list}>
                {article.format == 4 &&
                  article?.localization?.map((item, index) => (
                    <li key={index}>
                      {item.street} {item.adres}, {item.city} {item.code}
                    </li>
                  ))}
              </ul>
              <Map className={styles.locations_map} />
            </div>
          </div>
        )}

        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-blue", styles.subtitle)}>O autorze</div>
            <div className={styles.about_author}>
              <div className={styles.avatar}>
                <Link to={`/profile/${author.user.id}`}>
                  <img src={author.user.image} alt="" />
                </Link>
              </div>
              <div className={styles.info}>
                <Link to={`/profile/${author.user.id}`}>
                  <div className={styles.username}>
                    <img
                      src={author.user.image || "/images/default-avatar.png"}
                      alt=""
                    />
                    {article?.display_name}
                  </div>
                </Link>
                <div
                  className={styles.content}
                  dangerouslySetInnerHTML={{
                    __html: authorBio,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {article?.tags?.length > 0 && (
          <div className={styles.row}>
            <div className={styles.col}>
              <div className={cn("title-purple", styles.subtitle)}>Tagi</div>
              {/* <div className={styles.tags}>
                {article.tags.map((tag, index) => (
                  <span className={styles.tag} key={index}>
                    {tag.name}
                  </span>
                ))}
              </div> */}
              <TagsList tags={article?.tags || []} />
            </div>
          </div>
        )}
      </div>
      <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        gallery={gallery}
        title="Fleet - Travel shopping UI design kit"
        figcaption="Elegant product mockup for your next project"
        download
      />
    </>
  );
};

export default Overview;
