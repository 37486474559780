import React, { useEffect, useState } from "react";
import styles from "./Post.module.sass";
import cn from "classnames";
import Details from "./Details";
//import Panel from "./Panel";
import Icon from "components/Icon";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import devscntrAuth from "api/Instance/devscntrAuth";
import devscntrNoauth from "api/Instance/devscntrNoauth";
import useAuth from "hooks/useAuth";
import RelatedCourses from "components/Related/RelatedCourses";
import RelatedPosts from "components/Related/RelatedPosts";
import RelatedJobOffer from "components/Related/RelatedJobOffer";
import SkeletonLoader from "screens/DetailsLayout/Loader";
import Panel from "screens/DetailsLayout/Panel";

const Post = () => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [article, setArticle] = useState({});
  const [refresh, setRefresh] = useState(false);
  //const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    fetchPost(cancelToken);

    window.scrollTo(0, 0);

    return () => {
      cancelToken.cancel();
    };
  }, [id, refresh]);

  //const currentLocation = history.location;
  //const postID = currentLocation.pathname.split("/").pop();

  const handleClose = () => {
    setActiveIndex(0);
    setVisible(false);
  };

  const fetchPost = (cancelToken) => {
    setIsLoading(true);
    axiosInstance
      .request({
        method: "get",
        url: `/posts/article/show-post/${id}/`,
        cancelToken: cancelToken.token,
      })
      .then(function (response) {
        const data = response.data[0];
        setArticle({ ...data, description: JSON.parse(data.description) });
        setIsLoading(false);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log("post detail canceled");
        } else {
          console.log(error);
        }
      });
  };

  return (
    <>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className={cn(styles.product, { [styles.active]: visible })}>
            <Details
              className={styles.details}
              setValue={setVisible}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              article={article}
              fetchArticle={fetchPost}
            />{" "}
            {/* <RelatedCourses name={article?.tags?.length > 0 && article?.tags[0]?.name} />
            <RelatedPosts name={article?.tags?.length > 0 && article?.tags[0]?.name} />
            <RelatedJobOffer name={article?.tags?.length > 0 && article?.tags[0]?.name} /> */}
            {console.log("article", article?.tags)}
            {/* <Panel
              className={styles.panel}
              article={article}
              fetchArticle={fetchPost}
            /> */}
            <Panel
              className={styles.panel}
              type="post"
              id={article?.id}
              currentReaction={
                article?.emotion_of_user?.length > 0
                  ? article?.emotion_of_user[0]?.emotion
                  : 0
              }
              likes={article?.emotions_likes_count}
              dislikes={article?.emotions_dislikes_count}
              author={article.author}
              author_image={article.author_image}
              refresh={() => setRefresh((prev) => !prev)}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Post;
