import { createSlice } from "@reduxjs/toolkit";
import devscntrAuth from "api/Instance/devscntrAuth";

const initialState = {
  visible: false,
  id: null,
};

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: initialState,
  reducers: {
    showUserInfo: (state, action) => {
      state.visible = true;
      const { id } = action.payload;
      state.id = id;
    },
    clearUserInfo: (state) => {
      state.visible = false;
      //state.id = null;
    },
  },
});
