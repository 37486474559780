import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ActivateAccount.module.sass";
import { Link, useParams } from "react-router-dom";
import devscntrNoauth from "api/Instance/devscntrNoauth";
import { accountsEndpoints } from "api/endpoints";
import Loader from "components/Loader";
import Image from "components/Image";

const AcitvateAccount = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const { uidb64, token } = useParams();
  console.log({ uidb64: uidb64 });
  console.log({ token: token });

  const confirmActivation = async () => {
    try {
      const response = await devscntrNoauth.post(
        accountsEndpoints.auth.registerActivate,
        {
          uidb64: uidb64,
          token: token,
        }
      );
      console.log(response);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSuccess(false);
    }
  };

  useEffect(() => {
    confirmActivation();
  }, [uidb64, token]);

  return (
    <div className={styles.wrapper}>
      <Link
        className={styles.logo}
        style={{ alignSelf: loading ? "center" : "flex-start" }}
        to="/"
      >
        <Image
          className={styles.pic}
          src="/images/logo-dark.svg"
          srcDark="/images/logo-light.svg"
          alt="DevsCNTR"
        />
      </Link>
      {loading ? (
        <>
          <div className={styles.loader_title}>Trwa aktywacja konta</div>
          <Loader className={styles.loader} />
        </>
      ) : (
        <div className={styles.body}>
          {success ? (
            <>
              <div className={cn("h2", styles.title)}>
                Rejestracja zakończona pomyślnie
              </div>
              <div className={styles.info}>Możesz się teraz zalogować</div>
              <Link to="/sign-in" className={cn("button", styles.button)}>
                <span>Przejdź do logowania</span>
              </Link>
              <div className={styles.note}>Twoje konto jest już aktywne</div>
            </>
          ) : (
            <>
              <div className={cn("h2", styles.title)}>
                Aktywacja nie powiodła się
              </div>
              <div className={styles.errorNote}>
                Twój link aktywacyjny wygasł, lub jest niepoprawny
              </div>
              <button className={cn("button", styles.button)}>
                <span>Wyślij link ponownie</span>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AcitvateAccount;
