import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ProfilePage.module.sass";
import Profile from "./Profile";
import Settings from "./Settings";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Filters from "../../components/Filters";
import Product from "../../components/Product";
import Follower from "./Follower";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import MiniPost from "../../components/MiniPost";
import AuthContext from "../../context/AuthContext";
import ProgressBar from "../../components/ProgressBar";
import Courses from "./Courses";
import FollowedUser from "./FollowedUser";
import Company from "./Company";

import useAuth from "../../hooks/useAuth";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import devscntrNoauth from "../../api/Instance/devscntrNoauth";
import Jobs from "./Jobs";
import Loader from "components/MiniPost/Loader";

const intervals = ["Najnowsze", "Najwyżej oceniane", "Popularne"];

//let currentUserId = 5;

const ProfilePage = () => {
  const [navigation, setNavigation] = useState([
    "Wpisy",
    "Kursy",
    "Przypinki",
    "Obserwacje",
    "Poziom",
  ]);
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;
  //const location = useLocation();
  //const locationArr = location.pathname.split("/");
  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState({});
  const [authorData, setAuthorData] = useState({
    id: params.id, //parseInt(locationArr[locationArr.length - 1]),
    name: "",
    image: null,
    bgImage: null,
    level: 0,
    xpPoints: 0,
    posts: [],
    pins: [],
    follows: [],
  });
  const [authorLevelData, setAuthorLevelData] = useState({
    level: 45,
    currentLevelXp: 45,
    nextLvlXp: 60,
    rankName: "Diament",
    rankIcon: "/images/content/ranks/diamond.webp",
  });
  const [isAuthorDataFetched, setIsAuthorDataFetched] = useState(false);
  const [sorting, setSorting] = useState(intervals[0]);
  const [currentFollows, setCurrentFollows] = useState([]);
  const [isFollowed, setIsFollowed] = useState();

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    if (authCtx.isLoggedIn) fetchFollows(cancelToken);
    fetchAuthor(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [params.id]);

  const fetchFollows = (cancelToken) => {
    devscntrAuth
      .get("/accounts/me/follow-user/", { cancelToken: cancelToken.token })
      .then(function (response) {
        let followings = response.data.map((item) => item.id);
        setCurrentFollows(followings);
        if (followings) {
          setIsFollowed(followings.includes(parseInt(params.id)) || false);
        }
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log("follows canceled");
        } else {
          console.log(error);
        }
      });
  };

  const fetchAuthor = (cancelToken) => {
    const headers = {
      "USER-ID": params.id,
    };

    setIsLoading(true);
    axiosInstance
      .get("/posts/article/user-post/?page=1&page_size=5", {
        headers: { ...headers, METHOD: "list" },
        cancelToken: cancelToken.token,
      })
      .then(function (response) {
        setAuthorData((prevState) => ({
          ...prevState,
          posts: response.data.results,
        }));
        setIsLoading(false);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log("user posts canceled");
        } else {
          console.log(error);
        }
      });

    axiosInstance
      .get("/accounts/me/user-info", {
        headers: { ...headers },
        cancelToken: cancelToken.token,
      })
      .then(function (response) {
        console.log(response.data);
        setData(response.data);
        if (response.data.user.company) {
          if (
            !navigation.includes("Firma") &&
            !navigation.includes("Oferty pracy")
          ) {
            setNavigation((prev) => [...prev, "Firma", "Oferty pracy"]);
          }
        }
        // let fixedPins =
        //   response.data.pinned_posts &&
        //   response.data.pinned_posts.map((item) => {
        //     return {
        //       ...item,
        //       description: JSON.parse(item.description),
        //     };
        //   });
        setAuthorData((prevState) => ({
          ...prevState,
          id: response.data.user.id,
          name:
            response.data.user.first_name + " " + response.data.user.last_name,
          username: response.data.user.display_name, //username,
          image: response.data.user.image,
          bgImage: response.data.user.background_image,
          level: response.data.user.user_current_level || 0,
          xpPoints: response.data.user.all_xp_points,
          pins: response.data.pinned_posts,
          follows: response.data.following,
          isAuthor: response.data.user.is_author,
        }));
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          console.log("user data canceled");
        } else {
          console.log(error);
        }
      });

    setIsAuthorDataFetched(true);
  };

  useEffect(() => {
    console.log(currentFollows);
  }, [currentFollows]);

  const triggerFollowUser = (userID, isFollowed) => {
    if (isFollowed)
      setCurrentFollows((prevState) => prevState.filter((x) => x !== userID));
    else setCurrentFollows((prevState) => [...currentFollows, userID]);

    const headers = {
      METHOD: isFollowed ? "unfollow" : "follow",
    };
    devscntrAuth
      .post(
        "/accounts/me/follow-user/",
        { id: userID },
        { headers: { ...headers } }
      )
      .then(function (response) {
        //fetchFollows();
        setIsFollowed((prev) => !prev);
      })
      .catch(function (error) {
        console.error(error);
      });
    //window.location.reload(false);
  };
  return (
    <>
      <div className={styles.shop}>
        <div className={styles.background}>
          <img
            src={authorData.bgImage || "/images/content/bg-shop.jpg"}
            alt="Background"
          />
        </div>
        <Card className={styles.card}>
          <Profile
            name={
              authorData?.name == " "
                ? authorData.name
                : authorData.username || "Brak nazwy"
            }
            image={authorData.image}
            level={authorData.level}
            userId={authorData.id}
            triggerFollow={triggerFollowUser}
            currentUserId={authCtx.userID}
            isFollowed={isFollowed}
            isAuthor={authorData.isAuthor}
          />
          <div className={styles.control}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            {activeIndex < 3 && (
              <div className={styles.dropdownBox}>
                <Dropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  value={sorting}
                  setValue={setSorting}
                  options={intervals}
                  small
                />
              </div>
            )}

            {/*<Filters
                className={styles.filters}
                title="Showing 9 of 32 products"
            >
              <Settings/>
            </Filters>*/}
          </div>
          <div className={styles.wrap}>
            {activeIndex === 0 && (
              <>
                <div className={styles.posts}>
                  {isLoading ? (
                    <Loader cards={1} />
                  ) : authorData?.posts?.length > 0 ? (
                    authorData?.posts.map((post, index) => {
                      return <MiniPost key={index} article={post} />;
                    })
                  ) : (
                    <p className={styles.info}>
                      Użytkownik nie ma jeszcze żadnych wpisów
                    </p>
                  )}
                </div>
                {/* {authorData.posts && (
                  <div className={styles.foot}>
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                    >
                      Pokaż więcej
                    </button>
                  </div>
                )} */}
              </>
            )}
            {activeIndex === 1 && (
              <>
                <Courses author={params?.id} />
              </>
            )}
            {activeIndex === 2 && (
              <>
                <div className={styles.pinned}>
                  {authorData?.pins?.length > 0 ? (
                    authorData?.pins?.map((pin, index) => (
                      <MiniPost key={index} article={pin} />
                    ))
                  ) : (
                    <p className={styles.info}>
                      Użytkownik nie ma jeszcze żadnych przypinek
                    </p>
                  )}
                </div>
                {/* {authorData?.pins && (
                  <div className={styles.foot}>
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                    >
                      Pokaż więcej
                    </button>
                  </div>
                )} */}
              </>
            )}
            {activeIndex === 3 && (
              <>
                <div className={styles.followers}>
                  {/* {authorData?.follows && authorData?.follows?.length > 0 ? (
                    authorData?.follows?.map((followed, index) => (
                      <Follower
                        className={styles.followed}
                        userID={followed.id}
                        key={index}
                        isFollowed={currentFollows.includes(followed.id)}
                        toggleFollow={triggerFollowUser}
                      />
                    ))
                  ) : (
                    <p className={styles.info}>
                      Użytkownik nikogo jeszcze nie obserwuje
                    </p>
                  )} */}
                  {authorData?.follows && authorData?.follows?.length > 0 ? (
                    authorData?.follows?.map((user, index) => (
                      <FollowedUser
                        myFollows={currentFollows}
                        user={user}
                        key={index}
                      />
                    ))
                  ) : (
                    <p className={styles.info}>
                      Użytkownik nikogo jeszcze nie obserwuje
                    </p>
                  )}
                </div>
              </>
            )}

            {activeIndex === 4 && (
              <div className={styles.level_container}>
                {/* <div className={styles.level}>
                  <div className={styles.level_circle}>
                    {authorLevelData?.level}
                  </div>
                  <div className={styles.level_progress}>
                    <div className={styles.header}>
                      <div className={styles.label}>Bierzący poziom:</div>
                      <div className={styles.points}>
                        {authorLevelData?.currentLevelXp}/
                        {authorLevelData?.nextLvlXp}
                      </div>
                    </div>
                    <ProgressBar
                      barColor="#2A85FF"
                      currentProgress={authorLevelData?.currentLevelXp}
                      maxProgress={authorLevelData?.nextLvlXp}
                    />
                  </div>
                </div> */}

                {/* <div className={styles.rank}>
                    <div className={styles.header}>
                      <div className={styles.label}>Ranga:</div>
                      <div className={styles.rank_name}>
                        {authorLevelData?.rankName}
                      </div>
                    </div>
                    <div className={styles.rank_icon}>
                      <img
                        src={authorLevelData?.rankIcon}
                        alt={authorLevelData?.rankName}
                      />
                    </div>
                  </div> */}
              </div>
            )}

            {activeIndex === 5 && (
              <>
                <div className={styles.company}>
                  {data?.user?.company && (
                    <Company id={params.id} data={data.user.company} />
                  )}
                </div>
              </>
            )}

            {activeIndex === 6 && (
              <>
                <div className={styles.company}>
                  {data?.user?.company && (
                    <Jobs id={params.id} data={data.user.company} />
                  )}
                </div>
              </>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default ProfilePage;
