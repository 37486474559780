import { userInfoSlice } from "./slice";

export default userInfoSlice.reducer;
export const userInfoActions = userInfoSlice.actions;
export const showUserInfo = (id) => (dispatch) => {
  return dispatch(userInfoActions.showUserInfo({ id }));
};
export const hideUserInfo = () => (dispatch) => {
  dispatch(userInfoActions.clearUserInfo());
};
