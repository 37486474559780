import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./TagsInput.module.sass";
import Card from "components/Card";
import TextInput from "components/TextInput";
import Icon from "components/Icon";
import useAuth from "hooks/useAuth";
import devscntrAuth from "api/Instance/devscntrAuth";
import devscntrNoauth from "api/Instance/devscntrNoauth";
import axios from "axios";
import { showError } from "utils/showError";
import { validateInput } from "utils/validation";
import OutsideClickHandler from "react-outside-click-handler";

const TagsInput = ({ className, tags, setTags, maxTagNumber }) => {
  const authCtx = useAuth();
  const axiosInstance = authCtx.isLoggedIn ? devscntrAuth : devscntrNoauth;

  const [tagInput, setTagInput] = useState("");
  const [categories, setCategories] = useState([]);
  const [hint, setHint] = useState("");
  const [visibleHint, setVisibleHint] = useState(false);

  const compareNumbers = (a, b) => {
    return b.tags_count - a.tags_count;
  };

  const getCategories = async (cancelToken) => {
    try {
      const response = await axiosInstance.request({
        method: "get",
        url: "/misc/tags/",
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      const cats = response?.data?.sort(compareNumbers);
      setCategories(cats);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getCategories(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, []);

  const getHint = (value) => {
    if (value === "") return "";

    setVisibleHint(true);
    const allHints = categories.filter((item) =>
      item?.name?.trim().toLowerCase().includes(value?.trim().toLowerCase())
    );
    return allHints?.length > 0 ? allHints[0].name : "";
  };

  const useHint = () => {
    handleAddTag(hint);
    setHint("");
  };

  const handleChange = (e) => {
    setTagInput(e.target.value);
    setHint(getHint(e.target.value));
  };

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    handleAddTag(tagInput);
  };

  const handleAddTag = (value) => {
    if (!value?.length > 0) return;
    if (!validateInput(value.trim().toLowerCase(), 20, 3, "tag")) {
      return;
    }

    let alreadyExists = false;
    tags.forEach((item) => {
      if (item === value.trim().toLowerCase()) {
        showError("Podany tag jest już na liście");
        alreadyExists = true;
        return;
      }
    });

    if (value !== "" && tags.length < maxTagNumber && !alreadyExists) {
      setTags([...tags, value.trim().toLowerCase()]);
      setTagInput("");
      console.log(tags);
    }
  };

  const removeTag = (index) => {
    setTags(tags.filter((tag, i) => i !== index));
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="Tagi"
      classTitle="title-purple"
      head={`${tags.length}/${maxTagNumber}`}
    >
      <div className={styles.input_container}>
        <input
          placeholder="Podaj tag i zatwierdź enterem"
          className={styles.input}
          onKeyDown={handleKeyDown}
          value={tagInput}
          onChange={handleChange}
          onFocus={() => setVisibleHint(true)}
        />

        <button className={cn("button")} onClick={() => handleAddTag(tagInput)}>
          Dodaj
        </button>
      </div>

      {hint?.length > 0 && visibleHint && (
        <OutsideClickHandler onOutsideClick={() => setVisibleHint(false)}>
          <button className={styles.hint} onClick={useHint}>
            {hint}
          </button>
        </OutsideClickHandler>
      )}

      <div className={styles.container}>
        {tags.map((tag, index) => (
          <div className={styles.tag} key={index}>
            <span className={styles.text}>{tag}</span>
            <div className={styles.remove} onClick={() => removeTag(index)}>
              <Icon name="close" size={18} />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};
export default TagsInput;
