import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

import Checkbox from "components/Checkbox";
// import ModalProduct from "components/ModalProduct";
import Icon from "components/Icon";
import Actions from "components/Actions";
import Modal from "components/Modal";
import Schedule from "components/Schedule";

import { setStatusName, setTypeName, setShortenAmount } from "./utilities";


export default function Row({ item, isSelected, handleSelect, parentRef }) {
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [visibleModalSchedule, setVisibleModalSchedule] = useState(false);
  // const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  // const observerActions = responsiveStyles(styles);
  // useResizeObserverAction({
  //   containerElementRef: parentRef.current,
  //   action: observerActions
  // });

  // jak przerzucić klasę kontenera do innego komponentu?????????????

  const actions = [
    {
      title: "Schedule product",
      icon: "calendar",
      action: () => setVisibleModalSchedule(true),
    },
    {
      title: "Edit title & description",
      icon: "edit",
      action: () => console.log("Edit title & description"),
    },
    {
      title: "Delete forever",
      icon: "trash",
      action: () => console.log("Delete forever"),
    },
  ];

  const statusName = setStatusName(item.status);
  const typeName = setTypeName(item.type);
  const ratingValue = item.ratingValue ?? '-';
  const shortenViews = setShortenAmount(item.views);
  const shortenLead = setShortenAmount(item.lead);
  const shortenLikes = setShortenAmount(item.likes);
  const shortenUnlikes = setShortenAmount(item.unlikes);


  return (
    <>
      <div className={cn(styles.row, 'row')} data-item-id={item.id}>
        <div className={styles['mobile-wrapper']}>


          {/* <-- Checkbox --> */}
          <div className={cn(styles.col, styles.checkbox)}>
            <Checkbox
              value={isSelected}
              onChange={handleSelect}
            />
          </div>


          {/* <-- Product --> */}
          <div className={cn(styles.col, styles.product)}>
            <div className={styles['product-wrapper']}>

              {/* <-- Thumbnail --> */}
              <div className={styles.thumbnail}>
                <img
                  srcSet={`${item.image2x} 2x`}
                  src={item.image}
                  alt="Miniaturka"
                />
              </div>

              {/* <-- Detale --> */}
              <div className={styles.details}>
                <div className={styles['product-name']}>{item.product}</div>
                {/* <-- Status --> */}
                <div className={cn(styles.status, styles.moved)}>
                  <div className={cn(
                    styles['status-color'],
                    {
                      [styles.published]: item.status === 0,
                      [styles.scheduled]: item.status === 1,
                      [styles.draft]: item.status === 2,
                    }
                  )}>{statusName}</div>
                </div>

                {/* <-- Data --> */}
                <div className={styles.date}>
                  <Icon name="clock" size="20" />
                  <div>{item.date}</div>
                </div>


              </div>
            </div>
          </div>

          {/* <-- Akcje --> */}
          <div className={cn(styles.col, styles.actions, styles['actions--mobile'])}>
            <Actions
              classActionsHead={styles.actionsHead}
              items={actions}
            />
          </div>
        </div>


        {/* <-- Status --> */}
        <div className={cn(styles.col, styles.status)}>
          <div className={styles.label}>Status</div>
          <div className={cn(
            styles['status-color'],
            {
              [styles.published]: item.status === 0,
              [styles.scheduled]: item.status === 1,
              [styles.draft]: item.status === 2,
            }
          )}>{statusName}</div>
        </div>


        <div className={cn(styles['mobile-wrapper'], styles['mobile-details'])}>
          {/* <-- Typ --> */}
          <div className={cn(styles.col, styles.type)}>
            <div className={styles.label}>Typ</div>
            <div>{typeName}</div>
          </div>

          {/* <-- Wyświetlenia --> */}
          <div className={styles.col}>
            <div className={styles.label}>Wyświetlenia</div>
            <div>{shortenViews}</div>
            <div className={styles.bar}>
              <div className={styles.progress}></div>
            </div>
          </div>

          {/* <-- Leady --> */}
          <div className={styles.col}>
            <div className={styles.label}>Lead-y</div>
            <div>{shortenLead}</div>
            <div className={styles.bar}>
              <div className={cn(styles.progress, styles['progress--violet'])}></div>
            </div>
          </div>
          {/* </div> */}


          <div className={cn(
            styles['laptop-wrapper'],
            styles['mobile-wrapper'],
          )}>

            {/* <-- Reakcje --> */}
            <div className={cn(styles.col, styles.reactions)}>
              <div className={styles.label}>Reakcje</div>
              <div className={styles['icon-in-row']}>

                <div className={styles.thumb}>
                  <img src="/images/Icons/thumbs_up/light.svg" alt="Thumbs up" />
                  <div>{shortenLikes}</div>
                </div>
                <div className={styles.thumb}>
                  <img src="/images/Icons/thumbs_down/light.svg" alt="Thumbs down" />
                  <div>{shortenUnlikes}</div>

                </div>
              </div>
            </div>

            {/* <-- Ocena --> */}
            <div className={cn(styles.col, styles.rating)}>
              <div className={styles.label}>Ocena</div>
              <div className={styles['icon-in-row']}>
                <img className={cn(styles.star, {
                  [styles['star--inactive']]: !item.ratingValue
                })}
                  src="/images/Icons/star/light.svg" alt="Gwiazdka"
                />
                <div>
                  {ratingValue}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <-- Akcje --> */}
        <div className={styles.ghost}>
          <div className={cn(styles.col, styles.actions, styles['actions--desktop'])}>
            <Actions
              classActionsHead={styles.actionsHead}
              items={actions}
            />
          </div>
        </div>
      </div>

      {/* <-- Przedziałek --> */}
      <div className={styles.divider}></div>

      {/* <-- Modal po kliknięciu akcji --> */}
      <Modal
        visible={visibleModalSchedule}
        onClose={() => setVisibleModalSchedule(false)}
      >
        <Schedule
          startDate={startDate}
          setStartDate={setStartDate}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      </Modal>
    </>
  );
};
