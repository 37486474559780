import React, { useEffect, useState, useRef } from "react";
import styles from "./NewPost.module.sass";
import TooltipGlodal from "components/TooltipGlodal";
import Modal from "components/Modal";
import Schedule from "components/Schedule";
// import NameAndDescription from "./NameAndDescription";
// import ImagesAndCTA from "./ImagesAndCTA";
// import CategoryAndAttibutes from "./CategoryAndAttibutes";
import Preview from "screens/NewLayouts/Preview";
import Panel from "screens/NewLayouts/Panel";
import { EditorState, convertToRaw } from "draft-js";
import axios from "axios";
import cn from "classnames";
import Card from "components/Card";
import { useHistory } from "react-router-dom";
import PhotoUploader from "components/PhotoUploader";
import { validateImage, validateInput, validateUrl } from "utils/validation";
import { showError } from "utils/showError";
import devscntrAuth from "api/Instance/devscntrAuth";
import TagsInput from "screens/NewLayouts/TagsInput";
import ImageInput from "screens/NewLayouts/ImageInput";
import Content from "screens/NewLayouts/Content";
import Details from "./Details";
import useAuth from "hooks/useAuth";
import ModalSchedule from "components/ModalSchedule";

const setUpFormData = (data) => {
  let newFormData = new FormData();
  for (let key in data) {
    if (key == "image") newFormData.append(key, data[key], data[key].name);
    else newFormData.append(key, data[key]);
  }
  return newFormData;
};

const NewPost = () => {
  const authCtx = useAuth();

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleSchedule, setVisibleSchedule] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const [isPostCreated, setIsPostCreated] = useState(false);

  //const [errorMsg, setErrorMsg] = useState("");

  const [files, setFiles] = useState(null);
  //const [image, setImage] = useState(null);
  //const inputRef = useRef();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [titleState, setTitleState] = useState("");
  const [reflink, setReflink] = useState("");

  const [tags, setTags] = useState([]);

  //const [alertContent, setAlertContent] = useState("");

  const location = useHistory();

  /*
  useEffect(() => {
    if (files) {
      setImage(URL.createObjectURL(files));
    }
  }, [files]);
  */

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleTitleChange = (e) => {
    const val = e.target.value;
    setTitleState(val);
  };

  const fixDataToSend = () => {
    let dataToSend = {
      title: titleState.trim(),
      description: JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      ),
      //tags: tags.map((tag) => tag.text.trim()).join(","),
      tags: tags.map((tag) => tag.trim()).join(","),
    };

    if (files) dataToSend = { ...dataToSend, image: files };
    if (reflink !== "")
      dataToSend = {
        ...dataToSend,
        is_external: true,
        external_url: reflink.trim(),
      };
    return dataToSend;
  };

  const validation = () => {
    // TITLE
    if (!validateInput(titleState.trim(), 100, 10, "Tytuł")) return false;

    // DESCRIPTION
    if (
      reflink === "" &&
      JSON.stringify(convertToRaw(editorState.getCurrentContent())).length < 133
    ) {
      showError("Opis nie może być pusty");
      return false;
    }

    // TAGS
    if (tags.length < 1) {
      showError("Musisz dodać co najmniej jeden tag");
      return false;
    }

    if (reflink !== "") {
      if (!validateUrl(reflink.trim())) return false;
    }

    return true;
  };

  const requestCreatePost = (data) => {
    devscntrAuth
      .post("/posts/article/create-post/", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          method: "create",
        },
      })
      .then(function (response) {
        console.log(response.data);
        setIsPostCreated(true);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    console.log("file change");
    console.log(files);
  }, [files]);

  const handleSubmit = () => {
    if (!validation()) return;

    const dataToSend = fixDataToSend();
    console.log(dataToSend);
    requestCreatePost(dataToSend);
  };

  return (
    <>
      <div className={styles.row}>
        {!isPostCreated ? (
          <>
            <div className={styles.col}>
              {/* <NameAndDescription
                className={styles.card}
                onTitleChange={handleTitleChange}
                onDescriptionChange={handleEditorStateChange}
                titleValue={titleState}
                descriptionValue={editorState}
                reflink={reflink}
                setReflink={setReflink}
              /> */}
              <Content
                className={styles.card}
                onTitleChange={handleTitleChange}
                onDescriptionChange={handleEditorStateChange}
                title={titleState}
                description={editorState}
              />
              <Details
                className={styles.card}
                reflink={reflink}
                setReflink={setReflink}
              />
              {/* <ImagesAndCTA
                className={styles.card}
                inputRef={inputRef}
                //onChange={(e) => setFile(e)}
                onChange={handleFileChange}
              />{" "} */}
              <ImageInput
                className={styles.card}
                title={"Miniaturka postu"}
                setFile={setFiles}
                fileName={`post_img_u${authCtx.userID}`}
              />
              {/*To Do*/}
              {/* <CategoryAndAttibutes
                className={styles.card}
                tagsState={tags}
                setTagsState={setTags}
              /> */}
              <TagsInput tags={tags} setTags={setTags} maxTagNumber={12} />
            </div>
            {/* <div className={styles.col}>
              <Preview
                visible={visiblePreview}
                onClose={() => setVisiblePreview(false)}
                setVisible={setVisiblePreview}
                image={image}
                title={titleState}
                //fetchArticle={fixDataToSend}
                //styles={{ pointerEvents: "none" }}
              />
            </div> */}
          </>
        ) : (
          <Card className={styles.postcreated}>
            <p>Post został utworzony!</p>
            <div className={styles.panel}>
              <button
                className={cn("button", styles.homebtn)}
                onClick={() => location.push("/home")}
              >
                Wróć do strony głównej
              </button>
              <button
                className={cn("button-stroke", styles.newbtn)}
                onClick={window.location.reload}
              >
                Utwórz kolejny post
              </button>
            </div>
          </Card>
        )}
      </div>
      {!isPostCreated && (<>
          <Panel
            setVisiblePreview={setVisiblePreview}
            setVisibleSchedule={setVisibleSchedule}
            onSubmit={handleSubmit}
          />
          <TooltipGlodal />
          <ModalSchedule
            visible={visibleSchedule}
            setVisible={setVisibleSchedule}
            startDate={startDate}
            setStartDate={setStartDate}
            startTime={startTime}
            setStartTime={setStartTime}
          />
      </>)}
    </>
  );
};

export default NewPost;
