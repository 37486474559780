import React, { useEffect, useState } from "react";
import styles from "./TagDetail.module.sass";
import cn from "classnames";
import { useHistory, useParams } from "react-router";
import { adminEndpoints } from "api/endpoints";
import devscntrAuth from "api/Instance/devscntrAuth";
import axios from "axios";
import Card from "components/Card";
import { Link } from "react-router-dom";
import EditTag from "./EditTag";
import ModalConfirm from "components/ModalConfirm";

const TagDetail = () => {
  const { id } = useParams();
  const [tag, setTag] = useState({});
  const [refresh, setRefresh] = useState();
  const [editVisible, setEditVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const history = useHistory();

  const getTag = async (cancelToken) => {
    try {
      const response = await devscntrAuth.request({
        method: "get",
        url: `${adminEndpoints.tags.show}${id}`,
        cancelToken: cancelToken.token,
      });
      console.log({ tag: response.data });
      setTag(response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("admin tags canceled");
      } else {
        console.log(error);
      }
    }
  };

  // get tag on page load
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getTag(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [id, refresh]);

  const deleteTag = async () => {
    try {
      const response = await devscntrAuth.delete(
        `${adminEndpoints.tags.show}${id}/`
      );
      console.log({ delete: response.data });
      history.replace("/admin/tags");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ModalConfirm
        title="Usuń?"
        visible={confirmVisible}
        setVisible={setConfirmVisible}
        onConfirm={deleteTag}
      />

      <Card
        className={styles.card}
        classCardHead={styles.head}
        title="Zarządzaj tagiem"
        classTitle={cn("title-purple", styles.title)}
        head={
          <button
            className={cn("button", styles.delete_btn)}
            onClick={() => setConfirmVisible(true)}
          >
            Usuń
          </button>
        }
      >
        <div className={styles.container}>
          <div className={styles.details}>
            <span className={styles.list_item}>
              <b>Nazwa: </b>
              {tag?.name || "Brak nazwy"}
            </span>
            <span className={styles.list_item}>
              <b>Kolor: </b>
              <div
                className={styles.color_box}
                style={{ backgroundColor: tag?.colour || "#ffffff" }}
              ></div>
              {tag?.colour || "Brak koloru"}
            </span>
            <span className={styles.list_item}>
              <b>Liczba użyć: </b>
              {tag?.tags_count || 0}
            </span>
            <span className={styles.list_item}>
              <b>Data utworzenia: </b>
              {tag?.created_at?.slice(0, 10) || "Brak danych"}
            </span>
            <span className={styles.list_item}>
              <b>Nazwa autora: </b>
              {tag?.author_displayname || "Brak nazwy"}
            </span>
            <span className={styles.list_item}>
              <b>ID autora: </b>
              {tag?.author || "Brak nazwy"}
            </span>
          </div>

          <div className={styles.buttons}>
            <button
              className={cn(
                editVisible ? "button-stroke" : "button",
                styles.edit_button
              )}
              onClick={() => setEditVisible((prev) => !prev)}
            >
              {editVisible ? "Ukryj pola edycji" : "Pokaż pola edycji"}
            </button>
            <Link to={`/admin/users/${tag?.author}`} className={"button"}>
              Przejdź do autora
            </Link>
          </div>

          {editVisible && (
            <EditTag
              tag={tag}
              setTag={setTag}
              refresh={() => setRefresh((prev) => !prev)}
            />
          )}
        </div>
      </Card>
    </>
  );
};
export default TagDetail;
