import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import { Link } from "react-router-dom";

const Item = ({ post }) => {
  return (
    <Link to={`/post/${post.id}`}>
      <div className={styles.item}>
        <div className={styles.preview}>
          <img
            src={post.image || "/images/image-placeholder.png"}
            alt="Obrazek postu"
          />
        </div>
        <div className={styles.title}>{post.title}</div>
        <div className={styles.details}>
          {/* <div className={styles.price}>{post.price}</div> */}
          {post?.tags?.length > 0 && (
            <div className={cn("status-green", styles.status)}>
              {post?.tags[0].name}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default Item;
