import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  visible: false,
};

export const guestModalSlice = createSlice({
  name: "guestModal",
  initialState: initialState,
  reducers: {
    showGuestModal: (state) => {
      state.visible = true;
    },
    hideGuestModal: (state) => {
      state.visible = false;
    },
  },
});
