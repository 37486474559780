import React, { useState, useEffect } from "react";
import styles from "./UserDetail.module.sass";
import cn from "classnames";
import { adminEndpoints } from "api/endpoints";
import { useParams } from "react-router";
import Icon from "components/Icon";
import devscntrAuth from "api/Instance/devscntrAuth";
import axios from "axios";
import Card from "components/Card";
import Publications from "./Publications";
import { Link } from "react-router-dom";
import Company from "./Company";
import { convertFromRaw, EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import EditProfile from "./EditProfile";

const UserDetail = () => {
  const [refresh, setRefresh] = useState(false);

  const { id } = useParams();
  const [user, setUser] = useState({});
  const [bio, setBio] = useState(null);
  const [bioVisible, setBioVisible] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const getUser = async (cancelToken) => {
    try {
      const response = await devscntrAuth.get(
        `${adminEndpoints.users.show}${id}`,
        {
          cancelToken: cancelToken.token,
        }
      );
      console.log({ user: response?.data });
      setUser(response?.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("get user canceled");
      } else {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    getUser(cancelToken);

    return () => {
      cancelToken.cancel();
    };
  }, [id, refresh]);

  useEffect(() => {
    if (user?.bio) {
      let fixedDescription = EditorState.createWithContent(
        convertFromRaw(JSON.parse(user?.bio))
      );
      setBio(convertToHTML(fixedDescription.getCurrentContent()));
    }
  }, [user]);

  const editProfile = () => {
    console.log("edit");
  };

  // MODERATE USER
  const passwordReset = () => {};
  const changeEmail = () => {};
  const showUserLogs = () => {};
  const banUser = () => {};

  return (
    <Card
      className={styles.card}
      classCardHead={styles.head}
      title="Zarządzaj użytkownikiem"
      classTitle={cn("title-yellow", styles.title)}
      head={
        <button
          className={cn(editMode ? "button-stroke" : "button")}
          onClick={() => setEditMode((prev) => !prev)}
        >
          {editMode ? "Widok danych" : "Tryb edycji"}
        </button>
      }
    >
      {editMode ? (
        <EditProfile
          user={user}
          setUser={setUser}
          refresh={() => setRefresh((prev) => !prev)}
        />
      ) : (
        <div className={styles.container}>
          {user?.created_at ? (
            <>
              <div className={styles.details}>
                <div className={styles.image}>
                  <img
                    src={user?.image || "/images/default-avatar.png"}
                    alt=""
                  />
                </div>
                <div className={styles.user_info}>
                  <span className={styles.username}>
                    {user?.display_name || "Brak nazwy"}
                    {user?.is_admin && (
                      <div className={cn("status-red", styles.status)}>
                        Administrator
                      </div>
                    )}
                  </span>
                  <span className={styles.time}>
                    Ostatnie logowanie:{" "}
                    {user?.last_logged?.slice(0, 10) || "Brak danych"}
                  </span>
                </div>
                <Link
                  to={`/profile/${user?.id}`}
                  style={{ marginLeft: "auto" }}
                  className={cn("button")}
                >
                  Przejdź do profilu
                </Link>
              </div>

              <div className={styles.background_image}>
                <img
                  src={user?.background_image || "/images/default-bg-img.jpg"}
                  alt=""
                />
              </div>

              {/* USER DATA */}
              <div className={styles.personal_informations}>
                <div className={cn("title-blue", styles.informations_title)}>
                  Informacje o użytkowniku
                </div>
                <div className={styles.list_item}>
                  {user.first_name || "[Imię]"} {user.last_name || "[Nazwisko]"}
                </div>
                <div className={styles.list_item}>
                  <b>@</b> {user.email || "Brak emailu"}
                </div>
                <div className={styles.list_item}>
                  <Icon name="phone" size={16} />
                  {user.phone || "Brak nr telefonu"}
                </div>
              </div>

              <div className={styles.bio}>
                <div className={cn("title-green", styles.bio_title)}>
                  <div
                    className={cn(
                      styles.title_head,
                      bioVisible ? styles.active : ""
                    )}
                    onClick={() => setBioVisible((prev) => !prev)}
                  >
                    <div>Opis</div>
                    <div>
                      <Icon name="arrow-down" size={20} />
                    </div>
                  </div>
                </div>
                {user?.bio ? (
                  <div
                    className={cn(styles.content, !bioVisible && styles.hide)}
                    dangerouslySetInnerHTML={{
                      __html: bio,
                    }}
                  ></div>
                ) : (
                  bioVisible && (
                    <p className={styles.info}>
                      Użytkownik nie dodał jeszcze opisu
                    </p>
                  )
                )}
              </div>

              {/* ACCOUNT DATA */}
              <div className={styles.account_informations}>
                <div className={cn("title-purple", styles.informations_title)}>
                  Informacje o koncie
                </div>
                <div className={styles.list_item}>
                  <b>Data utworzenia:</b> {user.created_at.slice(0, 10)}
                </div>
                <div className={styles.list_item}>
                  <b>Obserwujący:</b> {user?.follower_count || 0}
                </div>
              </div>

              {/* COMPANY DATA */}
              {user?.company !== null && <Company user={user} />}

              {/* CONTENT DATA */}
              <div className={styles.user_content}>
                <div className={cn("title-green", styles.informations_title)}>
                  <div
                    className={cn(
                      styles.title_head,
                      contentVisible ? styles.active : ""
                    )}
                    onClick={() => setContentVisible((prev) => !prev)}
                  >
                    <div>Twórczość</div>
                    <div>
                      <Icon name="arrow-down" size={20} />
                    </div>
                  </div>
                </div>
                <div className={styles.content_count}>
                  <div className={styles.list_item}>
                    <b>Posty:</b> {user?.posts_count || 0}
                  </div>
                  <div className={styles.list_item}>
                    <b>Kursy:</b> {user?.courses_count || 0}
                  </div>
                  <div className={styles.list_item}>
                    <b>Oferty pracy:</b> {user?.jobs_count || 0}
                  </div>
                </div>
                {contentVisible && (
                  <div className={styles.publications}>
                    <Publications />
                  </div>
                )}
              </div>

              {/* BUTTONS */}
              <div className={styles.user_actions}>
                <div className={cn("title-red", styles.informations_title)}>
                  Podejmij działanie
                </div>
                <div className={styles.actions}>
                  <button className={cn("button")} onClick={editProfile}>
                    Edytuj profil
                  </button>
                  {/* <button className={cn("button")} onClick={passwordReset}>
                  Zresetuj hasło
                </button>
                <button className={cn("button")} onClick={changeEmail}>
                  Zmień email
                </button>
                <button className={cn("button")} onClick={showUserLogs}>
                  Pokaż logi
                </button> */}
                  <button className={cn("button")} onClick={banUser}>
                    Zablokuj
                  </button>
                </div>
              </div>
            </>
          ) : (
            "loading"
          )}
        </div>
      )}
    </Card>
  );
};
export default UserDetail;
