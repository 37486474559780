import React, { useState } from "react";
import cn from "classnames";
import styles from "./FilterSettings.module.sass";
import Dropdown from "../../../../components/Dropdown";
import Checkbox from "../../../../components/Checkbox";
import Icon from "../../../../components/Icon";
import { Range, getTrackBackground } from "react-range";
import useDarkMode from "use-dark-mode";
import TextInput from "../../../../components/TextInput";

const options = [
  "Najnowsze",
  "Popularność",
  "Oceny",
  "Cena (rosnąco)",
  "Cena (malejąco)",
];
const optionsRating = [
  "od 1 gwiazdki",
  "od 2 gwiazdek",
  "od 3 gwiazdek",
  "od 4 gwiazdek",
  "od 5 gwiazdek",
];
const priceFilterOptions = ["Darmowe", "Płatne", "Promocja"];

const currency = "zł";

const FilterSettings = (props) => {
  const STEP = 1;
  const MIN = props.priceFilters.minPrice;
  const MAX = props.priceFilters.maxPrice;

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [values, setValues] = useState(props.priceFilters.priceRange);
  const darkMode = useDarkMode(false);

  const handleSubmit = (e) => {
    alert();
  };

  const handleChange = (filter) => {
    console.log(selectedFilters);
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== filter));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, filter]);
    }
  };

  return (
    <div className={styles.settings}>
      <div className={styles.group}>
        <div className={styles.item}>
          <div className={styles.label}>Sortuj wg.</div>
          <div className={styles.box}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.label}
              value={props.sortBy}
              setValue={props.setSortBy}
              options={props.sortOptions}
            />
            <Icon name="filter" size="24" fill="#6F767E" />
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>Poziom trudności</div>
          <div className={styles.box}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.label}
              value={props.difficulty}
              setValue={props.setDifficulty}
              options={props.difficultyOptions}
            />
            <Icon name="leaderboard" size="24" fill="#6F767E" />
          </div>
        </div>

        {/* <div className={styles.item}>
          <div className={styles.label}>Poziom trudności</div>
          <div className={styles.list}>
            {props.difficultyOptions.map((x, index) => (
              <Checkbox
                className={styles.checkbox}
                content={x}
                value={selectedFilters.includes(x)}
                onChange={() => handleChange(x)}
                key={index}
                reverse
              />
            ))}
          </div>
        </div> */}

        <div className={styles.item}>
          <div className={styles.label}>Forma kursu</div>
          <div className={styles.box}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.label}
              value={props.format}
              setValue={props.setFormat}
              options={props.formatOptions}
            />
            <Icon name="repeat" size="24" fill="#6F767E" />
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>Cena</div>
          <div className={styles.list}>
            {/* {priceFilterOptions.map((x, index) => (
              <Checkbox
                className={styles.checkbox}
                content={x}
                value={selectedFilters.includes(x)}
                onChange={() => handleChange(x)}
                key={index}
                reverse
              />
            ))} */}
            <Checkbox
              className={styles.checkbox}
              content={"Pokazuj tylko darmowe"}
              value={props.priceFilters.showFree}
              onChange={() =>
                props.setPriceFilters((prev) => ({
                  ...prev,
                  showFree: !prev.showFree,
                }))
              }
              reverse
            />
          </div>

          {/* <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={(values) => {
              props.setPriceFilters((prev) => ({
                ...prev,
                priceRange: values,
              }));
              setValues(values);
            }}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  width: "100%",
                  paddingTop: 20,
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "4px",
                    width: "100%",
                    borderRadius: "2px",
                    background: getTrackBackground({
                      values,
                      colors: darkMode.value
                        ? ["#272B30", "#2A85FF", "#272B30"]
                        : ["#EFEFEF", "#2A85FF", "#EFEFEF"],
                      min: MIN,
                      max: MAX,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ index, props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "16px",
                  width: "16px",
                  borderRadius: "50%",
                  backgroundColor: "#FFF",
                  border: "2px solid #2A85FF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "inset 0px 2px 2px #FFFFFF",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: "calc(100% + 5px)",
                    color: "#fff",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontFamily: "Inter",
                    padding: "4px 8px",
                    borderRadius: "8px",
                    backgroundColor: "#272B30",
                  }}
                >
                  {values[index].toFixed(0)}
                  {currency}
                </div>
              </div>
            )}
          /> */}
        </div>

        {/* <div className={styles.item}>
          <div className={styles.label}>Maks. długość kursu (godz.)</div>
          <TextInput icon="clock" />
        </div> */}

        <div className={styles.item}>
          <div className={styles.label}>Język kursu</div>
          <div className={styles.box}>
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              classDropdownLabel={styles.label}
              value={props.languageFilter}
              setValue={props.setLanguageFilter}
              options={props.languageOptions}
            />
            <Icon name="list" size="24" fill="#6F767E" />
          </div>
        </div>

        {/* <div className={styles.item}>
          <div className={styles.label}>Oceny</div>
          <div className={styles.box}>
            <Dropdown
              className={styles.dropdown}
              classDropdownLabel={styles.label}
              classDropdownHead={styles.dropdownHead}
              value={props.rating}
              setValue={props.setRating}
              options={props.ratingOptions}
              upBody
            />
            <Icon name="star-stroke" size="24" fill="#6F767E" />
          </div>
        </div> */}
        <div className={styles.btns}>
          <button
            className={cn("button", styles.button)}
            onClick={props.onFiltersClear}
          >
            Resetuj filtry
          </button>
          {/* <button
            className={cn("button", styles.button)}
            onClick={props.onFiltersSubmit}
          >
            Zastosuj filtry
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default FilterSettings;
