import React, { useState, useEffect, useRef } from "react";
import styles from "./MiniCourse.module.sass";
import relatedStyle from "./RelatedMiniCourse.module.sass";
import { Link, useParams, useLocation } from "react-router-dom";
import Icon from "../Icon";
import AuthContext from "../../context/AuthContext";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import ModalPin from "../ModalPin";
import devscntrAuth from "../../api/Instance/devscntrAuth";
import Actions from "components/Actions";
import { showLoginModal } from "utils/showLoginModal";
import Reactions from "components/MiniItemsLayout/Reactions";
import MoreOptions from "components/MiniItemsLayout/MoreOptions";
import Thumbnail from "components/MiniItemsLayout/Thumbnail";
import Tags from "components/MiniItemsLayout/Tags";
import Author from "components/MiniItemsLayout/Author";
import { currencyList } from "data/dataArrays";
import useAuth from "hooks/useAuth";
import useBreakpoints from "hooks/useBreakpoints";

const currencyCode = currencyList[0].code;
const format = ["artykuł", "wideo", "online", "stacjonarny"];
const difficulty = ["początkujący", "średnio zaawansowany", "ekspert"];
const language = ["PL", "ENG"];

const MiniCourse = React.forwardRef(({ article, related }, ref) => {
  const authCtx = useAuth();
  const [hidden, setHidden] = useState(false);
  const location = useLocation();

  const stylesRef = useRef(null); // element DOM dla resizeObserver-a
  const breakpoints = [
    ["desktop", Infinity],
    ["mobile", 716],
  ];
  useBreakpoints(stylesRef.current, breakpoints);

  // -----------------------------------------------------
  // <----- !!!! PRZYPINKI !!!! ----->
  // -----------------------------------------------------
  const [pinState, setPinState] = useState(article?.pinned);
  const [StarModalVisible, setStarModalVisible] = useState();
  const [redirectedUser, setRedirectedUser] = useState();
  const [unpinPost, setunpinPost] = useState();
  const pinsPath = location.pathname.slice(0, 5);

  const pinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    setStarModalVisible(true);
  };

  const unpinHandler = () => {
    if (!authCtx.isLoggedIn) {
      showLoginModal();
      return;
    }

    if (pinsPath === "/pins") {
      setunpinPost(true);
    } else {
      setRedirectedUser(true);
    }
  };

  return (
    article &&
    !hidden && (
      <article ref={stylesRef}>
        <div className={styles.item} ref={ref}>
          {(StarModalVisible || redirectedUser || unpinPost) && (
            <ModalPin
              visible={StarModalVisible}
              onClose={() => setStarModalVisible(false)}
              article={article}
              setPinState={setPinState}
              redirectedUser={redirectedUser}
              setRedirectedUser={setRedirectedUser}
              unpinPost={unpinPost}
              setunpinPost={setunpinPost}
              pinUrl={"/course/user-course/"}
              idName={"course_id"}
            />
          )}

          <Link to={`/course/${article.id}`}>
            <Thumbnail className={styles.image} image={article?.image} />
          </Link>

          <section className={!related ? styles.details : relatedStyle.details}>
            <div className={styles.head}>
              <Author
                id={article?.id}
                username={article?.display_name}
                image={article?.author_image}
              />
              {article?.tags?.length > 0 && (
                <Tags
                  tags={article?.tags?.sort(
                    (a, b) => b.tags_count - a.tags_count
                  )}
                />
              )}
              <div className={!related ? styles.actions : relatedStyle.actions}>
                <button
                  className={styles.pin_btn}
                  onClick={pinState ? unpinHandler : pinHandler}
                >
                  <img
                    src={
                      pinState
                        ? "/images/Icons/heart/filled-color.svg"
                        : "/images/Icons/heart/stroke.svg"
                    }
                    alt="favourite"
                  />
                </button>

                <MoreOptions
                  item={article}
                  type="course"
                  onDelete={() => setHidden(true)}
                />
              </div>
            </div>

            <Link to={`/course/${article.id}`} style={{ color: "inherit" }}>
              <h2 className={!related ? styles.title : relatedStyle.title}>
                {article?.title || "Brak tytułu"}
              </h2>
            </Link>

            <div className={!related ? styles.content : relatedStyle.content}>
              <div className={!related ? styles.price : relatedStyle.price}>
                {article?.specialprice ? (
                  <>
                    <s>{article.price}</s>{" "}
                    {`${article.specialprice} ${currencyCode}`}
                  </>
                ) : article?.price > 0 ? (
                  `${article.price} ${currencyCode}`
                ) : (
                  "za darmo"
                )}
              </div>
              {`${article?.duration && `${article?.duration} h -`} ${
                article?.format && `${format[article?.format - 1]} -`
              } ${
                article?.difficulty &&
                `${difficulty[article?.difficulty - 1]} -`
              } ${article?.language && `${article?.language}`}`}
            </div>

            <div className={styles.foot}>
              <Reactions
                className={styles.control}
                type="course"
                id={article?.id}
                comments={article?.comments_count}
                rating={article?.rating_avg}
                rated={article?.user_rating > 0}
                currentReaction={
                  article?.emotion_of_user?.length > 0
                    ? article?.emotion_of_user[0]?.emotion
                    : 0
                }
                likes={article?.emotions_likes_count}
                dislikes={article?.emotions_dislikes_count}
              />
              <div className={!related ? styles.time : relatedStyle.time}>
                {article?.created_at?.slice(0, 10)}
              </div>
            </div>
          </section>
        </div>
      </article>
    )
  );
});
export default MiniCourse;
